import React, { useState, useEffect, useContext, version } from "react";
import {
  RowWrapper,
  LineText,
  StyledHeaderText,
  PageWrapper,
  StyledTableHeaderRowDiv,
  StyledTableHeaderDiv,
  LineTextEmphasized,
  ExpandButton,
  CollapsableTableHeaderDiv,
  CollapsableTableItem,
  HorizontalWrapper,
  ButtonDiv,
  StyledGroupHeaderText,
  CollapsableSubFundTableHeaderDiv,
  GroupHeaderLabelDiv,
} from "../reports_styles.jsx";
import html2pdf from "html2pdf.js";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField, Tooltip } from "@mui/material";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import UserContext from "../../../assets/user_context.jsx";
import {
  calcBalanceSheet,
  calcBalanceSheetV2,
} from "../../../utilities/transaction_parser.jsx";
import withSubscriptionProtection from "../../../services/with_subscription.jsx";
import {
  ExpandMore,
  FileDownloadOutlined,
  Inventory2Outlined,
} from "@mui/icons-material";
import { convertToJSON, exportReportXlsx } from "../export_report_xlsx.jsx";
import ReactDOMServer from "react-dom/server";
import AccountRows from "./account_rows.jsx";
import TransactionsDrilling from "../transactions_drilling.jsx";
import {
  extremeShortenText,
  findFundById,
  shortenText,
} from "../../../utilities/general_util.jsx";
import { countFundsDisplayed } from "../report_helper_functions.js";
dayjs.extend(isBetween);

const formatNum = (num) => {
  const float = parseFloat(num);
  const formatted = new Intl.NumberFormat("en-us", {
    style: "currency",
    currency: "USD",
  }).format(float);
  return formatted;
};

const fromCentsToCurrency = (num) => {
  const float = parseFloat(num) / 100;
  const formatted = new Intl.NumberFormat("en-us", {
    style: "currency",
    currency: "USD",
  }).format(float);
  return formatted;
};

const BalanceSheetProtected = () => {
  const {
    accounts,
    accountsHierarchy,
    funds,
    fundsHierarchy,
    transactions,
    contacts,
    orgData,
  } = useContext(UserContext);

  // const result = fundsHierarchy.groups.map((group, index) => {
  //   console.log(group.groupName);
  //   return group.funds.map((fund, innerIndex) => {
  //     console.log(fund);
  //     return fund;
  //   });
  // });

  // console.log(result);

  const [date, setDate] = useState(dayjs());
  const [balanceSheetReportsData, setBalanceSheetReportsData] = useState({});

  const [txModalOpen, setTxModalOpen] = useState(false);
  const [fundTransactions, setFundTransactions] = useState({});
  const [txToDrill, setTxToDrill] = useState({ fund: null, account: null });

  const [showFunds, setShowFunds] = useState(true);
  const [fundGroupsToShow, setFundGroupsToShow] = useState([]);
  const [numberOfFundsShown, setNumberOfFundsShown] = useState(0);

  useEffect(() => {
    if (fundsHierarchy?.groups?.length > 0) {
      const newFundGroupsToShow = fundsHierarchy.groups.map((group) => ({
        show: true, // Assuming you want all groups to be shown initially
        funds: group.funds.map((fund) => ({
          show: true, // Assuming you want all funds to be shown initially
          subFunds:
            fund.subFunds?.length > 0
              ? fund.subFunds.map(() => ({ show: true }))
              : [], // Ensure subFunds array is correctly set up
        })),
      }));
      setFundGroupsToShow(newFundGroupsToShow);
    }
  }, [fundsHierarchy]);

  const toggleFundVisibility = (groupIndex, fundIndex) => {
    setFundGroupsToShow((prevGroups) =>
      prevGroups.map((group, gIndex) => {
        if (gIndex === groupIndex) {
          // Toggle the entire group's visibility if it's the group header
          if (fundIndex === null) {
            return {
              ...group,
              show: !group.show,
              funds: group.funds.map((fund) => ({
                ...fund,
                // Optionally toggle fund visibility along with the group
                show: !group.show,
                subFunds: fund.subFunds.map((subFund) => ({
                  ...subFund,
                  show: !group.show,
                })),
              })),
            };
          } else {
            // Toggle only the subFunds' visibility for a specific fund
            const funds = group.funds.map((fund, fIndex) => {
              if (fIndex === fundIndex) {
                return {
                  ...fund,
                  // Do not toggle fund's own visibility
                  subFunds: fund.subFunds.map((subFund, subFundIndex) => ({
                    ...subFund,
                    show: !fund.subFunds[subFundIndex].show,
                  })),
                };
              }
              return fund;
            });
            return { ...group, funds };
          }
        }
        return group;
      }),
    );
  };

  //Calculate totals from all transactions
  useEffect(() => {
    if (
      transactions?.length > 0 &&
      date &&
      accounts?.length > 0 &&
      funds?.length > 0
    ) {
      const txBeforeDate = transactions.filter((entry) => {
        const dateIsBeforeOrSame =
          dayjs(entry.date).isBefore(date, "day") ||
          dayjs(entry.date).isSame(date, "day");
        return dateIsBeforeOrSame;
      });
      if (txBeforeDate.length > 0) {
        const { fundResultsObj, fundTransactionObj } = calcBalanceSheet(
          txBeforeDate,
          accounts,
        );
        const version2BalanceSheet = calcBalanceSheetV2({
          filteredTransactions: txBeforeDate,
          accounts,
          funds,
          accountsHierarchy,
          fundsHierarchy,
        });
        console.log("Version 2 Balance Sheet: ", version2BalanceSheet);
        // const balanceSheetAccountTypes = {
        //   assets: false,
        //   liabilities: true,
        // };

        // const ok = calcFinancialStatement(
        //   txBeforeDate,
        //   accounts,
        //   balanceSheetAccountTypes,
        // );
        console.log("From Calculations:", fundResultsObj);
        setBalanceSheetReportsData(fundResultsObj);
        setFundTransactions(fundTransactionObj);
      } else {
        console.log("No transactions in date range");
        setBalanceSheetReportsData({});
      }
    }
  }, [transactions, date, accounts, funds]);

  const renderTitle = () => {
    return (
      <React.Fragment>
        <p
          style={{
            fontSize: "1.2rem",
            fontFamily: "MontserratMed",
            textAlign: "center",
            margin: "0.5rem",
            marginBottom: "27px",
          }}>
          {`Balance Sheet as of ${date.format("MM/DD/YYYY")}`}
        </p>
        <div style={{ height: "10px" }} />
      </React.Fragment>
    );
  };

  useEffect(() => {
    console.log("Funds Data: ", funds);
    console.log(balanceSheetReportsData);
  }, [balanceSheetReportsData]);

  //useEffect to log fundGroupsToShow when it changes
  useEffect(() => {
    console.log("SHOW", fundGroupsToShow);

    const numberOfFundsHorizontallyDisplayed =
      countFundsDisplayed(fundGroupsToShow);
    console.log(
      "Number of Funds Displayed: ",
      numberOfFundsHorizontallyDisplayed,
    );
    setNumberOfFundsShown(numberOfFundsHorizontallyDisplayed);
  }, [fundGroupsToShow]);

  const addPdfExportClass = (element) => {
    element.classList.add("pdf-export");
    Array.from(element.children).forEach(addPdfExportClass);
  };

  const reportToPDF = () => {
    const element = document.getElementById("balance-sheet-table");
    if (!element) {
      console.error("Element not found");
      return;
    }

    // Clone the element
    const clone = element.cloneNode(true);
    clone.classList.add("clone-for-pdf");

    // Find all <p> elements within <th> in the clone and add the 'pdf-header-text' class
    const headerParagraphs = clone.querySelectorAll("th > p"); // This selects <p> elements that are direct children of <th>
    headerParagraphs.forEach((p) => {
      p.classList.add("pdf-header-text");
    });

    if (numberOfFundsShown > 4) {
      clone.classList.add("small-text");
    }

    addPdfExportClass(clone);

    // Measure the height of the clone
    const tempContainer = document.createElement("div");
    document.body.appendChild(tempContainer); // Temporarily add to the body
    tempContainer.appendChild(clone); // Append clone for measurement

    const contentHeight = clone.scrollHeight;
    document.body.removeChild(tempContainer); // Remove temp container after measurement

    // Create a container for the clone and title
    const container = document.createElement("div");
    container.style.width = numberOfFundsShown > 4 ? "297mm" : "210mm"; // A4 width: ;
    container.style.height = `${
      contentHeight + (numberOfFundsShown > 4 ? 25 : 60)
    }px`; // A4 height: ;
    container.style.display = "flex";
    container.style.flexDirection = "column";
    container.style.alignItems = "center";
    // container.style.visibility = "hidden"; // Make the container invisible while keeping it in the flow

    // Render the title to a string
    const titleHtml = ReactDOMServer.renderToString(renderTitle());
    // Append title and clone to the container
    container.innerHTML = titleHtml;
    container.appendChild(clone);
    document.body.appendChild(container);

    // Define options for html2pdf
    const options = {
      margin: 10,
      filename: `balance-sheet-${date.format("MM/DD/YYYY")}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 3 },
      jsPDF: {
        unit: "mm",
        format: "a4",
        orientation: numberOfFundsShown > 4 ? "landscape" : "portrait",
      },
      pagebreak: { mode: ["css", "legacy"] },
    };

    // Generate PDF
    setTimeout(() => {
      html2pdf()
        .from(container)
        .set(options)
        .save()
        .then(() => {
          document.body.removeChild(container); // Clean up after saving PDF
        });
    }, 800); // Delay to ensure rendering
  };

  const TotalRowComponent = ({
    balanceSheetReportsData,
    headerText,
    totalKey,
    additionalKey,
  }) => {
    // Calculate the grand total for all funds
    // const grandTotal = Object.keys(balanceSheetReportsData).reduce(
    //   (acc, fundID) => acc + (balanceSheetReportsData[fundID][totalKey] || 0),
    //   0,
    // );

    return (
      <tr>
        <td>
          <LineTextEmphasized>
            {totalKey === "total" && additionalKey === "equityTotal"
              ? "Total Equity"
              : totalKey === "assetsTotal" && additionalKey !== "total"
              ? "Total Assets"
              : totalKey === "liabilitiesTotal"
              ? "Total Liabilities"
              : totalKey === "assetsTotal" && additionalKey === "total"
              ? "Total Liabilities + Equity"
              : "Total"}
          </LineTextEmphasized>
        </td>
        {/* {Object.keys(balanceSheetReportsData).map((fundID, innerIndex) => (
          <CollapsableTableItem shouldShow={showFunds} key={innerIndex}>
            <LineTextEmphasized
              style={{
                borderTop: "1px solid black",
                textAlign: "right",
              }}>
              {fromCentsToCurrency(balanceSheetReportsData[fundID][totalKey])}
            </LineTextEmphasized>
          </CollapsableTableItem>
        ))} */}
        {fundsHierarchy.groups.map((group, groupIndex) =>
          group.funds.map((fund, fundIndex) => (
            <React.Fragment key={`${groupIndex}-${fundIndex}`}>
              {/* Group/Fund Total */}
              {(fundGroupsToShow[groupIndex].show || fundIndex === 0) && (
                <CollapsableTableItem shouldShow={showFunds} key={fundIndex}>
                  <LineTextEmphasized
                    style={{
                      borderTop: "1px solid black",
                      textAlign: "right",
                    }}>
                    {!fundGroupsToShow[groupIndex].show
                      ? fromCentsToCurrency(
                          group.funds.reduce((acc, fund) => {
                            let subFundTotal = 0;
                            if (fund.subFunds?.length > 0) {
                              subFundTotal = fund.subFunds.reduce(
                                (acc, subFund) => {
                                  return (
                                    (balanceSheetReportsData[subFund]?.[
                                      totalKey
                                    ] || 0) + acc
                                  );
                                },
                                0,
                              );
                            }
                            return (
                              (balanceSheetReportsData[fund.id]?.[totalKey] ||
                                0) +
                              acc +
                              subFundTotal
                            );
                          }, 0),
                        )
                      : !fund?.subFunds?.length > 0 ||
                        fundGroupsToShow[groupIndex].funds[
                          fundIndex
                        ].subFunds.some((subFund) => subFund.show)
                      ? fromCentsToCurrency(
                          balanceSheetReportsData[fund.id]?.[totalKey] || 0,
                        )
                      : fromCentsToCurrency(
                          balanceSheetReportsData[fund.id]?.[totalKey] ||
                            0 +
                              fund.subFunds.reduce((acc, subFund) => {
                                return (
                                  balanceSheetReportsData[subFund]?.[
                                    totalKey
                                  ] || 0 + acc
                                );
                              }, 0),
                        )}
                  </LineTextEmphasized>
                </CollapsableTableItem>
              )}
              {/* SubFunds Total (conditionally rendered) */}
              {fund?.subFunds?.length > 0 &&
                fund.subFunds.map((subFund, subFundIndex) => (
                  <React.Fragment>
                    {fundGroupsToShow[groupIndex].funds[fundIndex].subFunds[
                      subFundIndex
                    ].show && (
                      <CollapsableTableItem
                        shouldShow={showFunds && fund.show}
                        key={`${groupIndex}-${fundIndex}-${subFundIndex}`}>
                        <LineTextEmphasized
                          style={{
                            borderTop: "1px solid black",
                            textAlign: "right",
                          }}>
                          {fromCentsToCurrency(
                            balanceSheetReportsData[subFund]?.[totalKey] || 0,
                          )}
                        </LineTextEmphasized>
                      </CollapsableTableItem>
                    )}
                  </React.Fragment>
                ))}
            </React.Fragment>
          )),
        )}
        <td>
          <LineTextEmphasized
            style={{
              borderTop: "1px solid black",
              textAlign: "right",
            }}>
            {fromCentsToCurrency(balanceSheetReportsData.total[totalKey])}
          </LineTextEmphasized>
        </td>
      </tr>
    );
  };

  return (
    <PageWrapper style={{ width: "100%", alignItems: "flex-start" }}>
      <HorizontalWrapper style={{ paddingBottom: "1rem", width: "80vw" }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <RowWrapper>
            <DatePicker
              label="Balance on Date"
              value={date}
              onChange={(newValue) => {
                setDate(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={true}
                  variant="outlined"
                  color="secondary"
                />
              )}
            />
          </RowWrapper>
        </LocalizationProvider>
        <div style={{ height: "60px", width: "100px" }} />
        <ButtonDiv>
          <LineText style={{ whiteSpace: "nowrap" }}>Export to XLSX</LineText>
          {/* <div style={{ width: "10px" }} /> */}
          <Inventory2Outlined
            fontSize="medium"
            onClick={() => {
              if (
                transactions?.length > 0 &&
                date &&
                accounts?.length > 0 &&
                funds?.length > 0
              ) {
                const txBeforeDate = transactions.filter((entry) => {
                  const dateIsBeforeOrSame =
                    dayjs(entry.date).isBefore(date, "day") ||
                    dayjs(entry.date).isSame(date, "day");
                  return dateIsBeforeOrSame;
                });
                if (txBeforeDate.length > 0) {
                  const version2BalanceSheet = calcBalanceSheetV2({
                    filteredTransactions: txBeforeDate,
                    accounts,
                    funds,
                    accountsHierarchy,
                    fundsHierarchy,
                  });
                  console.log(
                    "Version 2 Balance Sheet: ",
                    version2BalanceSheet,
                  );
                  const JSONReport = convertToJSON({
                    reportData: version2BalanceSheet,
                    funds,
                  });
                  console.log(JSONReport);
                  exportReportXlsx({
                    JSONReport,
                    reportName: "Balance Sheet",
                    dateRange: date.format("MM/DD/YYYY"),
                    orgName: orgData?.orgName || "Test Organization",
                  });
                }
              }
            }}
          />
        </ButtonDiv>
        <div style={{ width: "60px" }} />
        <Tooltip
          title={
            numberOfFundsShown > 8
              ? "Too many horizontal funds, collapse some if possible"
              : null
          }>
          <ButtonDiv
            style={
              numberOfFundsShown > 8
                ? { borderColor: "grey", cursor: "not-allowed" }
                : {}
            }
            disabled={numberOfFundsShown > 8}
            onClick={() => {
              if (numberOfFundsShown <= 8) reportToPDF();
            }}>
            <LineText
              style={
                numberOfFundsShown > 8
                  ? {
                      textDecorationLine: "line-through",
                      whiteSpace: "nowrap",
                      color: "gray",
                    }
                  : { whiteSpace: "nowrap" }
              }>
              Export to PDF
            </LineText>
            {/* <div style={{ width: "10px" }} /> */}
            <FileDownloadOutlined
              fontSize="medium"
              style={numberOfFundsShown > 8 ? { color: "grey" } : {}}
            />
          </ButtonDiv>
        </Tooltip>
      </HorizontalWrapper>

      <TransactionsDrilling
        txModalOpen={txModalOpen}
        setTxToDrill={setTxToDrill}
        setTxModalOpen={setTxModalOpen}
        fundTransactions={fundTransactions}
        contacts={contacts}
        txToDrill={txToDrill}
      />

      {Object.keys(balanceSheetReportsData).length > 0 ? (
        <div
          id="balance-sheet-table"
          style={{
            paddingTop: "1.75rem",
            width: "100%",
            overflowX: "auto",
            maxWidth: "92vw",
          }}>
          <table style={{ borderCollapse: "separate" }}>
            <thead>
              <StyledTableHeaderRowDiv>
                <StyledTableHeaderDiv attribute="Account">
                  <StyledHeaderText attribute="bold">Account</StyledHeaderText>
                </StyledTableHeaderDiv>
                {/* {Object.keys(balanceSheetReportsData)
                    .filter((fundId) => fundId !== "total")
                    .map((fundID, index) => (
                      <CollapsableTableHeaderDiv shouldShow={showFunds}>
                        <StyledHeaderText>
                          {funds.find((fund) => fund.id === fundID).fundName}
                        </StyledHeaderText>
                      </CollapsableTableHeaderDiv>
                    ))} */}
                {fundsHierarchy.groups.map((group, groupIndex) =>
                  group.funds.map((fund, fundIndex) => (
                    <React.Fragment key={`${groupIndex}-${fundIndex}`}>
                      {/* Group/Fund Header */}
                      {(fundGroupsToShow[groupIndex].show ||
                        fundIndex === 0) && (
                        <CollapsableTableHeaderDiv
                          shouldShow={showFunds}
                          firstInGroup={fundIndex === 0}
                          // onClick={() =>
                          //   toggleFundVisibility(
                          //     groupIndex,
                          //     fundIndex === 0 ? null : fundIndex,
                          //   )
                          // }>
                        >
                          {fundIndex === 0 && (
                            <GroupHeaderLabelDiv
                              onClick={() =>
                                toggleFundVisibility(
                                  groupIndex,
                                  fundIndex === 0 ? null : fundIndex,
                                )
                              }>
                              <Tooltip
                                title={`Click to expand ${group.groupName}`}>
                                <StyledGroupHeaderText
                                  style={
                                    showFunds ? {} : { visibility: "hidden" }
                                  }>
                                  {shortenText(group.groupName)}
                                </StyledGroupHeaderText>
                              </Tooltip>
                              {showFunds && (
                                <ExpandMore
                                  style={{
                                    transform: fundGroupsToShow[groupIndex].show
                                      ? "rotate(-90deg)"
                                      : "rotate(0deg)",
                                    color: "white",
                                    transition: "transform 0.3s ease", // Smooth transition for rotation
                                  }}
                                />
                              )}
                            </GroupHeaderLabelDiv>
                          )}
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "center",
                            }}>
                            <StyledHeaderText
                              style={
                                showFunds
                                  ? { padding: "0.4rem" }
                                  : { visibility: "hidden" }
                              }>
                              {fundGroupsToShow[groupIndex].show
                                ? findFundById({ fundId: fund.id, funds })
                                    .fundName
                                : group.groupName}
                            </StyledHeaderText>
                            {fundGroupsToShow[groupIndex].show &&
                              fund.subFunds?.length > 0 &&
                              showFunds && (
                                <ExpandMore
                                  style={{
                                    transform: fundGroupsToShow[
                                      groupIndex
                                    ].funds[fundIndex].subFunds.some(
                                      (show) => show.show,
                                    )
                                      ? "rotate(-90deg)"
                                      : "rotate(0deg)",
                                    color: "white",
                                    transition: "transform 0.3s ease", // Smooth transition for rotation
                                  }}
                                  onClick={() =>
                                    toggleFundVisibility(groupIndex, fundIndex)
                                  }
                                />
                              )}
                          </div>
                        </CollapsableTableHeaderDiv>
                      )}
                      {/* SubFunds Header (conditionally rendered) */}

                      {/* SubFunds List */}
                      {fund?.subFunds?.length > 0 &&
                        fund.subFunds.map((subFund, subFundIndex) => (
                          <>
                            {fundGroupsToShow[groupIndex].show &&
                              fundGroupsToShow[groupIndex].funds[fundIndex]
                                .subFunds[subFundIndex].show && (
                                <CollapsableSubFundTableHeaderDiv
                                  key={`${groupIndex}-${fundIndex}-${subFundIndex}`}
                                  shouldShow={showFunds && fund.show}
                                  showFunds={showFunds}
                                  isSubFund={true}>
                                  <Tooltip
                                    title={
                                      numberOfFundsShown < 10
                                        ? null
                                        : findFundById({
                                            fundId: subFund,
                                            funds,
                                          }).fundName
                                    }>
                                    <StyledHeaderText
                                      style={
                                        showFunds
                                          ? { padding: "0.4rem" }
                                          : { visibility: "hidden" }
                                      }>
                                      {numberOfFundsShown < 10
                                        ? findFundById({
                                            fundId: subFund,
                                            funds,
                                          }).fundName
                                        : extremeShortenText(
                                            findFundById({
                                              fundId: subFund,
                                              funds,
                                            }).fundName,
                                          )}
                                    </StyledHeaderText>
                                  </Tooltip>
                                </CollapsableSubFundTableHeaderDiv>
                              )}
                          </>
                        ))}
                    </React.Fragment>
                  )),
                )}

                <StyledTableHeaderDiv
                  style={{ paddingLeft: "40px", paddingRight: "30px" }}>
                  <ExpandButton
                    id="expand-button"
                    showFunds={showFunds}
                    onClick={() => setShowFunds((prevValue) => !prevValue)}
                  />
                  <StyledHeaderText attribute="bold">Total</StyledHeaderText>
                </StyledTableHeaderDiv>
              </StyledTableHeaderRowDiv>
            </thead>
            <tbody>
              {/* Asset Accounts Individually */}
              <AccountRows
                reportsData={balanceSheetReportsData}
                setTxToDrill={setTxToDrill}
                setTxModalOpen={setTxModalOpen}
                showFunds={showFunds}
                accountsHierarchy={accountsHierarchy}
                accounts={
                  // balanceSheetReportsData[
                  //   Object.keys(balanceSheetReportsData)[0]
                  // ].assets
                  accounts
                }
                fundGroupsToShow={fundGroupsToShow}
                headerText="Assets"
              />
              <TotalRowComponent
                balanceSheetReportsData={balanceSheetReportsData}
                formatNum={formatNum}
                totalKey="assetsTotal"
              />
              {/* Liability Accounts Individually */}
              <AccountRows
                reportsData={balanceSheetReportsData}
                setTxToDrill={setTxToDrill}
                setTxModalOpen={setTxModalOpen}
                showFunds={showFunds}
                accountsHierarchy={accountsHierarchy}
                accounts={
                  // balanceSheetReportsData[
                  //   Object.keys(balanceSheetReportsData)[0]
                  // ].liabilities
                  accounts
                }
                fundGroupsToShow={fundGroupsToShow}
                headerText="Liabilities"
              />
              <TotalRowComponent
                balanceSheetReportsData={balanceSheetReportsData}
                formatNum={formatNum}
                totalKey="liabilitiesTotal"
              />
              {accounts
                .filter((acct) => acct.accountType.toLowerCase() === "equity")
                .map((account, outterIndex) => (
                  <tr>
                    <td>
                      <LineText>{`${account.accountNumber} - ${account.accountName}`}</LineText>
                    </td>
                    {Object.keys(balanceSheetReportsData)?.length > 0 &&
                      fundsHierarchy.groups.map((group, groupIndex) =>
                        group.funds.map((fund, fundIndex) => {
                          return (
                            <React.Fragment key={`${groupIndex}-${fundIndex}`}>
                              {(fundGroupsToShow[groupIndex].show ||
                                fundIndex === 0) && (
                                <React.Fragment>
                                  <CollapsableTableItem
                                    shouldShow={showFunds}
                                    key={`${groupIndex}-${fundIndex}`}>
                                    <LineText style={{ textAlign: "right" }}>
                                      {fund.id === account.id
                                        ? formatNum(
                                            balanceSheetReportsData[fund.id]
                                              ?.total / 100 || 0,
                                          )
                                        : fund?.subFunds?.length > 0 &&
                                          fundGroupsToShow[groupIndex].funds[
                                            fundIndex
                                          ].subFunds.every(
                                            (subFund) => !subFund.show,
                                          ) &&
                                          fund?.subFunds?.some((subFund) => {
                                            return subFund === account.id;
                                          })
                                        ? formatNum(
                                            balanceSheetReportsData[
                                              fund.subFunds?.find(
                                                (subFund) =>
                                                  subFund === account.id,
                                              )
                                            ]?.total / 100 || 0,
                                          )
                                        : !fundGroupsToShow[groupIndex].show &&
                                          group?.funds?.some(
                                            (fund) =>
                                              fund?.subFunds?.some(
                                                (subFund) =>
                                                  subFund === account.id,
                                              ) || fund.id === account.id,
                                          )
                                        ? formatNum(
                                            balanceSheetReportsData[
                                              group.funds.find(
                                                (fund) =>
                                                  fund.id === account.id,
                                              )?.id
                                            ]?.total ||
                                              balanceSheetReportsData[
                                                group.funds.find(
                                                  (fund) =>
                                                    fund.id === account.id,
                                                )?.id ||
                                                  group.funds
                                                    .find(
                                                      (fund) =>
                                                        fund.subFunds?.some(
                                                          (subFund) =>
                                                            subFund ===
                                                            account.id,
                                                        ) ||
                                                        fund.id === account.id,
                                                    )
                                                    ?.subFunds?.find(
                                                      (subFund) =>
                                                        subFund === account.id,
                                                    )
                                              ]?.total / 100 ||
                                              0,
                                          )
                                        : formatNum(0)}
                                    </LineText>
                                  </CollapsableTableItem>
                                  {fund?.subFunds?.length > 0 &&
                                    fundGroupsToShow[groupIndex].funds[
                                      fundIndex
                                    ].subFunds.every(
                                      (subFund) => subFund.show,
                                    ) &&
                                    fund.subFunds.map(
                                      (subFund, subFundIndex) => (
                                        <CollapsableTableItem
                                          shouldShow={showFunds && fund.show}
                                          key={`${groupIndex}-${fundIndex}-${subFundIndex}`}>
                                          <LineText
                                            style={{ textAlign: "right" }}>
                                            {subFund === account.id
                                              ? formatNum(
                                                  balanceSheetReportsData[
                                                    subFund
                                                  ]?.total / 100 || 0,
                                                )
                                              : formatNum(0)}
                                          </LineText>
                                        </CollapsableTableItem>
                                      ),
                                    )}
                                </React.Fragment>
                              )}
                            </React.Fragment>
                          );
                        }),
                      )}

                    <td>
                      <LineText style={{ textAlign: "right" }}>
                        {formatNum(
                          balanceSheetReportsData[account.id]?.total
                            ? balanceSheetReportsData[account.id]?.total / 100
                            : 0,
                        )}
                      </LineText>
                    </td>
                  </tr>
                ))}

              <TotalRowComponent
                balanceSheetReportsData={balanceSheetReportsData}
                formatNum={formatNum}
                totalKey="total"
                additionalKey="equityTotal"
              />
              <TotalRowComponent
                balanceSheetReportsData={balanceSheetReportsData}
                formatNum={formatNum}
                totalKey="assetsTotal"
                additionalKey="total"
              />
            </tbody>
          </table>
        </div>
      ) : null}
    </PageWrapper>
  );
};

const BalanceSheet = withSubscriptionProtection(BalanceSheetProtected);

export default BalanceSheet;
