import React, { useState, useContext } from "react";
import UserContext from "../../assets/user_context.jsx";
import {
  ComponentWrapper,
  AddButton,
  StyledSearch,
  ModalInner,
  ModalBox,
  TopRowDiv,
} from "../component_styles.jsx";
import { Box, Modal, Tooltip } from "@mui/material";
import { useAuth } from "../../services/use-auth.js";
import {
  LineText,
  StyledTableRow,
  StyledTableItem,
  StyledTable,
  StyledHeaderText,
  StyledHeader,
} from "./contacts_styles.jsx";
import {
  Check,
  Close,
  PersonAddDisabled,
  Edit,
  NoAccountsOutlined,
} from "@mui/icons-material";
import AddContact from "./add_contact.jsx";
import { primary_dark_color } from "../../constants/color_constants.jsx";
import { ConfirmAction } from "../../assets/controls.jsx";

function formatPhoneNumber(phoneNumber) {
  if (phoneNumber.length !== 10 || isNaN(phoneNumber)) {
    return "Invalid phone number";
  }

  const areaCode = phoneNumber.slice(0, 3);
  const prefix = phoneNumber.slice(3, 6);
  const line = phoneNumber.slice(6);

  return `(${areaCode}) ${prefix}-${line}`;
}

const Contacts = () => {
  const { org, contacts, orgRoles } = useContext(UserContext);

  const [search, setSearch] = React.useState("");
  const [openAddContactModal, setOpenAddContactModal] = useState(false);

  const [confirmRemoveOpen, setConfirmRemoveOpen] = useState(false);
  const [contactIdToDisable, setContactIdToDisable] = useState({
    id: "",
    label: "",
  });
  const [contactToEdit, setContactToEdit] = useState({});

  const authHook = useAuth();

  const handleChange = (event) => {
    setSearch(event.target.value);
  };

  const recordDisable = (data) => {
    console.log("Disable contact feature not complete yet:", data);

    setConfirmRemoveOpen(false);
    //Remove the contact from the database
    //Remove the contact from the contacts array
  };

  return (
    <React.Fragment>
      <ConfirmAction
        dataForAction={contactIdToDisable}
        callback={(data) => recordDisable(data)}
        openState={confirmRemoveOpen}
        handleCloseState={() => setConfirmRemoveOpen(false)}>
        Disable Contact
      </ConfirmAction>
      <Modal
        open={openAddContactModal}
        onClose={() => {
          setOpenAddContactModal(false);
          setContactToEdit({});
        }}>
        <ModalBox>
          <ModalInner>
            <AddContact
              handleClose={() => {
                setOpenAddContactModal(false);
                setContactToEdit({});
              }}
              db={authHook.db}
              org={org}
              contactToEdit={contactToEdit}
            />
          </ModalInner>
        </ModalBox>
      </Modal>

      <TopRowDiv>
        <Box type="form">
          <StyledSearch
            id="search"
            name="search"
            label="Search"
            value={search}
            onChange={handleChange}
            variant="outlined"
            color="secondary"
            size="large"
            style={{ width: "100%" }}
          />
        </Box>
        {orgRoles.some((role) => role === "admin" || role === "bookkeeper") && (
          <AddButton onClick={() => setOpenAddContactModal(true)}>
            Add Contact
          </AddButton>
        )}
      </TopRowDiv>
      <br />
      <ComponentWrapper style={{ alignItems: "flex-start" }}>
        <StyledTable>
          <thead>
            <StyledTableRow header>
              <StyledHeader>
                <StyledHeaderText style={{ marginRight: "40px" }}>
                  Company Name
                </StyledHeaderText>
              </StyledHeader>

              <StyledHeader>
                <StyledHeaderText style={{ marginRight: "40px" }}>
                  Contact Name
                </StyledHeaderText>
              </StyledHeader>

              <StyledHeader>
                <StyledHeaderText style={{ marginRight: "40px" }}>
                  Email
                </StyledHeaderText>
              </StyledHeader>

              <StyledHeader>
                <StyledHeaderText
                  style={{
                    textAlign: "center",
                    marginLeft: "40px",
                    marginRight: "40px",
                  }}>
                  Phone
                </StyledHeaderText>
              </StyledHeader>

              <StyledHeader>
                <StyledHeaderText
                  style={{
                    textAlign: "left",
                    marginLeft: "40px",
                    marginRight: "40px",
                  }}>
                  Address
                </StyledHeaderText>
              </StyledHeader>
              <StyledHeader>
                <StyledHeaderText
                  style={{ marginLeft: "20px", marginRight: "20px" }}>
                  1099?
                </StyledHeaderText>
              </StyledHeader>
              <StyledHeader>
                <StyledHeaderText
                  style={{ marginLeft: "20px", marginRight: "20px" }}>
                  Edit or Delete
                </StyledHeaderText>
              </StyledHeader>
            </StyledTableRow>
          </thead>
          <br />
          <tbody>
            {contacts.length > 0 ? (
              contacts
                .filter((contact) => {
                  const searchLower = search.toLowerCase();
                  const contactLower = contact.shortName.toLowerCase();
                  const companyLower = contact.companyName.toLowerCase();

                  return (
                    search === "" ||
                    companyLower.includes(searchLower) ||
                    contactLower.includes(searchLower)
                  );
                })
                .map((contact, index) => (
                  <StyledTableRow index={index} key={contact.email}>
                    <StyledTableItem>
                      <Tooltip title={contact.companyName}>
                        <LineText>{contact.shortName}</LineText>
                      </Tooltip>
                    </StyledTableItem>
                    <StyledTableItem>
                      {contact?.firstName && contact?.lastName && (
                        <LineText>
                          {contact.firstName + " " + contact.lastName}
                        </LineText>
                      )}
                    </StyledTableItem>
                    <StyledTableItem>
                      {contact?.email && <LineText>{contact.email}</LineText>}
                    </StyledTableItem>
                    <StyledTableItem>
                      {contact?.phone && (
                        <LineText>{formatPhoneNumber(contact.phone)}</LineText>
                      )}
                    </StyledTableItem>
                    <StyledTableItem>
                      {contact?.city &&
                        contact?.streetAddress &&
                        contact?.state &&
                        contact?.zip && (
                          <LineText
                            style={{
                              marginRight: "40px",
                              marginLeft: "40px",
                            }}>
                            {contact.streetAddress +
                              ", " +
                              contact.city +
                              ", " +
                              contact.state +
                              " " +
                              contact.zip}
                          </LineText>
                        )}
                    </StyledTableItem>
                    <StyledTableItem style={{ textAlign: "center" }}>
                      {contact?.["1099"] ? (
                        contact["1099"] ? (
                          <Check />
                        ) : (
                          <Close />
                        )
                      ) : null}
                    </StyledTableItem>
                    <StyledTableItem style={{ textAlign: "center" }}>
                      {orgRoles.some(
                        (role) => role === "admin" || role === "bookkeeper",
                      ) ? (
                        <div>
                          <Edit
                            fontSize="large"
                            sx={{
                              color: primary_dark_color,
                              cursor: "pointer",
                              transition: "transform 500ms", // This line sets up the transition
                              ":hover": {
                                transform: "rotate(360deg)", // This line sets the hover rotation
                              },
                            }}
                            onClick={() => {
                              console.log("Edit", contact);
                              setContactToEdit(contact);
                              setOpenAddContactModal(true);
                            }}
                          />
                          <PersonAddDisabled
                            fontSize="large"
                            sx={{
                              color: primary_dark_color,
                              cursor: "pointer",
                              transition: "transform 500ms", // This line sets up the transition
                              ":hover": {
                                transform: "rotate(360deg)", // This line sets the hover rotation
                              },
                            }}
                            onClick={() => {
                              setContactIdToDisable({
                                id: contact.id,
                                label: `remove ${contact.shortName}`,
                              });
                              setConfirmRemoveOpen(true);
                            }}
                          />
                        </div>
                      ) : (
                        <Tooltip
                          title={`No editing permissions as ${orgRoles.join(
                            " ",
                          )}.`}>
                          <NoAccountsOutlined />
                        </Tooltip>
                      )}
                    </StyledTableItem>
                  </StyledTableRow>
                ))
            ) : (
              <tr>
                <td colSpan="5">
                  <LineText>Please add Contacts</LineText>
                </td>
              </tr>
            )}
          </tbody>
        </StyledTable>
      </ComponentWrapper>
    </React.Fragment>
  );
};

export default Contacts;
