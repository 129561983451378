import React, { useState, useEffect, useContext, useRef } from "react";
import UserContext from "../../../assets/user_context";
import WhiteTextField, { RowWrapper } from "./banking_styles";
import { TextField, InputAdornment, FormControl, Tooltip } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {
  primary_dark_color,
  primary_highlight_color,
} from "../../../constants/color_constants";
import { Autocomplete } from "@mui/material";
import StyledButton from "../../../assets/buttons";
import CallSplitIcon from "@mui/icons-material/CallSplit";
import { Add, Close, UploadFileOutlined } from "@mui/icons-material";
import Dayjs from "dayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useAuth } from "../../../services/use-auth";

import { validateBankingInputs } from "../validation.jsx";
import { useRecordJE } from "../../transactions/enter_transaction/record_journal_entry.jsx";
import {
  findContactNameById,
  formatCurrency,
  removeCommasAndReturnCents,
  sortAccountsByType,
} from "../../../utilities/general_util";
import styled from "@emotion/styled";
import {
  ControlDiv,
  ControlLabelText,
  LineText,
  MasterErrorText,
} from "../../transactions/enter_transaction/enter_transaction_styles";
import CCPaymentTool from "../credit_cards/cc_payment_tool.jsx";
import { useWindowSize } from "../../../utilities/responsive_helpers.jsx";
import { TopRowDiv } from "../../component_styles.jsx";

const ContactTextField = styled(TextField)`
  background-color: white;
  .MuiFormHelperText-root.Mui-error {
    color: #f44336; // or whatever the default Material-UI error color you want
  }

  .MuiFormHelperText-root {
    color: ${primary_highlight_color};
    font-size: 0.8rem;
  }
`;

const EnterBankingTransaction = ({
  selectedAccount,
  txToComplete,
  closeModal,
  location,
  txToEdit,
  setOpenUploadModal,
}) => {
  const dateInputRef = useRef(null);
  const initialEntryDataState = {
    0: {
      comment: "",
      contact: "",
      credit: "",
      debit: "",
      account: "",
      fund: "",
    },
  };

  const initialMetaDataState = {
    date: null,
    checkNumber: "",
    memo: "",
  };

  const { accounts, contacts, funds, org, user, contactMapping } =
    useContext(UserContext);

  const authHook = useAuth();

  const [txMetaData, setTxMetaData] = useState(
    !txToComplete
      ? initialMetaDataState
      : { date: Dayjs(), checkNumber: "", memo: "" },
  );
  const [txEntryData, setTxEntryData] = useState(
    !txToComplete ||
      txToComplete === null ||
      txToComplete === undefined ||
      !txToEdit ||
      txToEdit === null ||
      txToEdit === undefined
      ? initialEntryDataState
      : {
          comment: "",
          contact:
            txToComplete.merchant_name !== null
              ? txToComplete.merchant_name
              : txToComplete.name,
          credit:
            location && location.some((l) => l === "creditCards")
              ? txToComplete.amount < 0
                ? txToComplete.amount
                : ""
              : txToComplete.amount > 0
              ? txToComplete.amount
              : "",
          debit:
            location && location.some((l) => l === "creditCards")
              ? txToComplete.amount > 0
                ? txToComplete.amount
                : ""
              : txToComplete.amount < 0
              ? txToComplete.amount
              : "",
          account: "",
          fund: "",
        },
  );
  const [displayContact, setDisplayContact] = useState(
    txToComplete
      ? [
          txToComplete.merchant_name !== null
            ? txToComplete.merchant_name
            : txToComplete.name,
        ]
      : [null],
  );

  const [remainderToAssign, setRemainderToAssign] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [entryErrors, setEntryErrors] = useState({});

  const [someUniqueKeyThatForcesReRender, setSomeUniqueKeyThatForcesReRender] =
    useState("Autocomplete");

  //CC Payment Tool state vars
  const [openPaymentTool, setOpenPaymentTool] = useState(false);
  const [paymentToProcess, setPaymentToProcess] = useState(null);
  const [creditCardAccount, setCreditCardAccount] = useState(null);
  const [touched, setTouched] = useState({ account: false, fund: false });

  const findAccountById = (id) => {
    return accounts.find((account) => account.id === id);
  };

  useEffect(() => {
    console.log("txEntryData: ", txEntryData);
    console.log(
      "SelectedAccountChange: setting txEntryData to initialEntryDataState",
    );
    setTxEntryData(initialEntryDataState);
    setTxMetaData(initialMetaDataState);
    setTouched({ account: false, fund: false });
    setDisplayContact([null]);
    setSomeUniqueKeyThatForcesReRender((prevState) => prevState.concat("a"));
    return () => {
      console.log("cleaning up", txEntryData);
      if (
        txEntryData[0].contact !== "" ||
        txEntryData[0].account !== "" ||
        txEntryData[0].fund !== ""
      ) {
        console.log("Unmounting: setting txEntryData to initialEntryDataState");
        setTxEntryData(initialEntryDataState);
      }
    };
  }, [selectedAccount]);

  //parse txToComplete which are usually coming from Plaid, and set the txEntryData and txMetaData
  useEffect(() => {
    if (txToComplete) {
      //This is an edit, so we need to load the data
      const newEntryData = {
        0: {
          comment: txToComplete.comment,
          contact:
            txToComplete.merchant_name !== null
              ? txToComplete.merchant_name
              : txToComplete.name,
          //This part is very confusing, but remember that the account that is being selected is the one that BALENCES this register's transaction
          //which is why credit/debit is the opposit as expected
          credit: txToComplete.amount < 0 ? -1 * txToComplete.amount : "",
          debit: txToComplete.amount > 0 ? txToComplete.amount : "",
          account: "",
          fund: "",
        },
      };
      setTxEntryData(newEntryData);
      setTxMetaData({
        date: txToComplete.date,
        checkNumber: txToComplete.checkNumber,
        memo: txToComplete.memo,
      });
      setDisplayContact([
        txToComplete.merchant_name !== null
          ? txToComplete.merchant_name
          : txToComplete.name,
      ]);
    } else {
      setTxEntryData(initialEntryDataState);
      setTxMetaData(initialMetaDataState);
      setDisplayContact([null]);
    }
  }, [txToComplete]);

  useEffect(() => {
    if (dateInputRef.current) {
      // console.log("dateInputRef.current is not null");
      dateInputRef.current.focus();
    }
  }, [dateInputRef.current]);

  // //parse txToEdit which are transactions that are being modified, and set the txEntryData and txMetaData
  useEffect(() => {
    if (txToEdit) {
      console.log("txToEdit: ", txToEdit);
      //This is an edit, so we need to load the data
      const linesArr = txToEdit.lines.filter(
        (line) => line.account !== selectedAccount,
      );
      if (linesArr.length === 1) {
        const linesToMap = linesArr.map((line) => {
          return {
            comment: line.comment,
            contact: line.contact,
            credit: line.sign === "credit" ? formatCurrency(line.amount) : "",
            debit: line.sign === "debit" ? formatCurrency(line.amount) : "",
            account: line.account,
            fund: line.fund,
            cleared: line.cleared || false,
          };
        });
        let newEntryData = {};
        linesToMap.forEach((line, index) => {
          newEntryData[index] = line;
        });
        setTxEntryData(newEntryData);
      } else {
        const totalAmount = linesArr.reduce((acc, curr) => {
          return acc + curr.amount;
        }, 0);
        const creditOrDebit =
          txToEdit.lines.find((line) => line.account === selectedAccount)
            .sign === "credit"
            ? "credit"
            : "debit";
        const topLine = {
          comment: "",
          contact: txToEdit.lines[0].contact,
          credit: creditOrDebit === "debit" ? formatCurrency(totalAmount) : "",
          debit: creditOrDebit === "credit" ? formatCurrency(totalAmount) : "",
          account: "",
          fund: "",
        };
        const linesToMap = linesArr.map((line) => {
          return {
            comment: line.comment,
            contact: line.contact,
            credit: line.sign === "credit" ? formatCurrency(line.amount) : "",
            debit: line.sign === "debit" ? formatCurrency(line.amount) : "",
            account: line.account,
            fund: line.fund,
            cleared: line.cleared || false,
          };
        });
        let newEntryData = {};
        newEntryData[0] = topLine;
        linesToMap.forEach((line, index) => {
          newEntryData[index + 1] = line;
        });
        setTxEntryData(newEntryData);
      }
      setDisplayContact([
        findContactNameById({ contactId: txToEdit.lines[0].contact, contacts }),
      ]);
      setTxMetaData({
        date: txToEdit.date,
        checkNumber: txToEdit.checkNumber || "",
        memo: txToEdit.memo || "",
      });
    }
  }, [txToEdit]);

  // Function to format numbers with commas for display
  function formatNumberWithCommas(value) {
    // Return "-" as is, to handle the initial negative sign input
    if (value === "-") return "-";

    // Ensure that we're formatting only if there's a value
    if (!value) return "";
    let parts = value.split(".");
    let integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // Reassemble the parts to include the decimal, if present
    return parts.length === 2 ? `${integerPart}.${parts[1]}` : integerPart;
  }

  // Function to format the amount to 2 decimal places when the input is blurred
  const formatAmount = (line) => {
    const newEntryData = { ...txEntryData };
    // Determine if we're working with 'credit' or 'debit'
    const type = newEntryData[line].credit !== "" ? "credit" : "debit";
    const amount = newEntryData[line][type];

    if (amount !== "") {
      // Strip commas, format to two decimal places, and reapply comma formatting
      const numericAmount = parseFloat(
        amount.toString().replace(/,/g, ""),
      ).toFixed(2);
      const formattedAmount = formatNumberWithCommas(numericAmount);

      newEntryData[line][type] = formattedAmount;
      setTxEntryData(newEntryData);
    }
  };

  //useEffect to calculate the remainder to assign if there are multiple lines
  useEffect(() => {
    if (
      (Object.keys(txEntryData).length > 1 && txEntryData[0]?.credit) ||
      txEntryData[0]?.debit
    ) {
      //Calculate the total of all the amounts, exluding the first line
      const total = Object.keys(txEntryData).reduce((acc, key) => {
        console.log("key OUTSIDE if: ", key);
        if (
          (txEntryData[key].credit !== "" || txEntryData[key].debit !== "") &&
          key !== 0 &&
          key !== "0"
        ) {
          console.log("key INSIDE if: ", key);
          return (
            acc +
            removeCommasAndReturnCents(
              txEntryData[key].credit || txEntryData[key].debit,
            )
          );
        } else {
          return acc;
        }
      }, 0);
      console.log("total: ", total);
      const remainder =
        removeCommasAndReturnCents(
          txEntryData[0].credit || txEntryData[0].debit,
        ) - total;
      const remainderParsed = remainder;
      setRemainderToAssign(remainderParsed);
      console.log("remainder: ", remainderParsed);
    }
  }, [txEntryData]);

  const handleChangeAmount = (event) => {
    const name = event.target.name;
    const line = name.split("-")[1];
    const sign = name.split("-")[0];
    console.log("line: ", line === "0");
    let value =
      line !== "0"
        ? event.target.value.replace(/,/g, "")
        : event.target.value.replace(/[,\\-]/g, "");
    // Count "-" occurrences
    const dashCount = value.split("-").length - 1;

    //For toggling the negative sign
    if (dashCount === 1) {
      // Ensure "-" is at the beginning
      value = "-" + value.replace(/-/g, "");
    } else if (dashCount > 1) {
      // Remove all "-" if more than one is present
      value = value.replace(/-/g, "");
    }

    // Validate the resulting value
    const isNumberOrDecimal = /^-?\d*\.?\d*$/; // Allow numbers with or without leading "-", and with or without decimals
    if (value === "" || isNumberOrDecimal.test(value)) {
      const newEntryData = { ...txEntryData };

      // Format the display value, including any decimals accurately
      const formattedValue = formatNumberWithCommas(value);
      // console.log("Formatted:", formattedValue);

      // Update the data structure with the formatted display value for user-friendly display
      newEntryData[line][sign] = formattedValue; // For display
      newEntryData[line][sign === "credit" ? "debit" : "credit"] = "";
      newEntryData[line].entered = true;
      setTxEntryData(newEntryData);
    } else {
      // Optionally handle invalid input, such as by resetting it to the last valid state or providing feedback
    }
  };

  const recordJE = useRecordJE();
  const handleSubmit = () => {
    //Create a deep copy of the txEntryData to avoid modifying the state directly
    const currentEntryData = JSON.parse(JSON.stringify(txEntryData));

    Object.keys(currentEntryData).forEach((key) => {
      // Remove any commas from the credit and debit fields and convert to an integer for cents
      currentEntryData[key].credit = currentEntryData[key]?.credit
        ? parseFloat(currentEntryData[key].credit.toString().replace(/,/g, ""))
        : "";
      currentEntryData[key].debit = currentEntryData[key]?.debit
        ? parseFloat(currentEntryData[key].debit.toString().replace(/,/g, ""))
        : "";
    });
    console.log("currentEntryData: ", currentEntryData);
    //Validate the inputs
    const errors = validateBankingInputs({
      txEntryData: currentEntryData,
      txMetaData,
    });
    // console.log("errors: ", errors);
    // console.log("txEntryData: ", txEntryData);
    setEntryErrors(errors);
    if (Object.keys(errors).length === 0) {
      setSubmitting(true);
      //CHECK FOR DEBT PAYMENT
      //Check there is a line that has a credit to an asset and another line that has a debit to a liability, if so it's a debt payment and should go to payment feature to assign by fund.
      const isDebtPayment = Object.values(currentEntryData).some((line) => {
        // console.log("line and account: ", line, findAccountById(line.account));
        return (
          line.debit !== "" &&
          line.account !== "" &&
          findAccountById(line.account)?.accountType.toLowerCase() ===
            "liabilities" &&
          findAccountById(line.account)?.isRegister === true
        );
      });
      // if (isDebtPayment) console.log("This is a debt payment");
      if (isDebtPayment) {
        const creditAccountId = Object.values(currentEntryData).find(
          (line) =>
            line.debit !== "" &&
            line.account !== "" &&
            findAccountById(line.account).accountType.toLowerCase() ===
              "liabilities" &&
            findAccountById(line.account).isRegister === true,
        ).account;
        setOpenPaymentTool(true);
        setCreditCardAccount(creditAccountId);
        setPaymentToProcess({
          amount:
            //Sum all the credits from this account
            Object.values(currentEntryData).reduce((acc, curr) => {
              if (
                curr.account === creditAccountId &&
                curr.debit !== "" &&
                curr.debit !== null
              ) {
                return acc + parseInt(curr.debit);
              }
              return acc;
            }, 0) * -1,
          date: txMetaData.date,
          checkNumber: txMetaData.checkNumber,
        });
        setTxEntryData({
          0: {
            comment: "",
            contact: "",
            credit: "",
            debit: "",
            account: "",
            fund: "",
          },
        });
        setSubmitting(false);
        return;
      }
      //No errors and not a payment, so we can submit the transaction
      const transactionLinesCount = Object.keys(currentEntryData).length;
      const splitTransaction = transactionLinesCount > 1;
      // console.log("splitTransaction: ", splitTransaction);
      //Even in a split transaction, this will be the total amount since it's top line
      let amount = currentEntryData[0].credit || currentEntryData[0].debit;

      //Build the transaction object to submit in journalEntries form
      let entryData = Object.values(currentEntryData).map((line) => {
        console.log(
          "BUILDING ENTRY DATA:",
          line,
          line.credit < 0,
          line.debit < 0,
          isNaN(line.credit),
          isNaN(line.debit),
        );
        return {
          account: line.account,
          credit:
            line.debit !== "" && line.debit < 0
              ? -1 * line.debit
              : isNaN(line.credit) && line.credit > 0
              ? ""
              : line.credit,
          debit:
            line.credit !== "" && line.credit < 0
              ? -1 * line.credit
              : isNaN(line.debit) && line.debit > 0
              ? ""
              : line.debit,
          fund: line.fund,
          comment: line.comment,
          contact: currentEntryData[0].contact,
          cleared: line.cleared || false,
        };
      });
      console.log("entryData2: ", entryData);
      if (!splitTransaction) {
        //Since this is a register we will build a new line for the double entry
        const newLine = {
          comment: "auto-entry for register",
          contact: currentEntryData[0].contact,
          credit: currentEntryData[0].credit ? "" : amount,
          debit: currentEntryData[0].debit ? "" : amount,
          fund: currentEntryData[0].fund,
          account: selectedAccount,
        };

        entryData.push(newLine);
      } else {
        //Eliminate the top line for a split transaction
        entryData.shift();

        //This is a split transaction, we need to find the total amounts for each fund
        const totalByFund = Object.values(currentEntryData)
          .slice(1)
          .reduce((acc, curr) => {
            if (curr.credit !== "" || curr.debit !== "") {
              const fund = curr.fund;
              const amount = parseFloat(curr.credit || curr.debit);
              if (acc[fund]) {
                acc[fund] += amount;
              } else {
                acc[fund] = amount;
              }
            }
            return acc;
          }, {});
        // console.log("totalByFund: ", totalByFund);
        Object.entries(totalByFund).forEach(([fund, amount]) => {
          entryData.push({
            comment: "split auto-entry for register",
            contact: currentEntryData[0].contact,
            credit: currentEntryData[0].credit ? "" : amount,
            debit: currentEntryData[0].debit ? "" : amount,
            fund: fund,
            account: selectedAccount,
          });
        });
      }

      const metaData = {
        date: txMetaData.date.toDate()
          ? txMetaData.date.toDate()
          : Dayjs(txMetaData.date).toDate(),
        memo: txMetaData.memo,
        checkNumber: txMetaData.checkNumber,
        plaidTx: txToComplete ? txToComplete : null,
        journalEntry: false,
      };

      const amountInAccountFromTx = entryData
        .filter((line) => line.account === selectedAccount)
        .reduce((acc, curr) => {
          //Sum the debits and subtract the credits
          if (curr.debit !== "") {
            return acc + curr.debit;
          } else {
            return acc - curr.credit;
          }
        }, 0);

      const amountInAccountFromTxToEdit =
        txToEdit?.lines?.length > 0
          ? txToEdit.lines
              .filter((line) => line.account === selectedAccount)
              .reduce((acc, curr) => {
                if (curr.sign === "debit") {
                  return acc + curr.amount;
                } else {
                  return acc - curr.amount;
                }
              }, 0)
          : 0;

      console.log(
        "amountInAccountFromTx: ",
        amountInAccountFromTx,
        "amountInAccountFromTxToEdit",
        amountInAccountFromTxToEdit,
      );

      if (
        txToEdit?.lines
          .filter((line) => line.account === selectedAccount)
          .every((line) => line.cleared) &&
        amountInAccountFromTx === amountInAccountFromTxToEdit
      ) {
        console.log(
          "This is a cleared transaction that is being edited but it's amount isn't changing, maintain cleared status",
        );
        //Change all the lines that have account === selectedAccount to cleared
        entryData.forEach((line) => {
          if (line.account === selectedAccount) {
            line.cleared = true;
          }
        });
      }

      console.log("entryData3: ", entryData);
      // console.log("entryMetaData: ", metaData);

      recordJE({
        entryData,
        metaData,
        contacts,
        db: authHook.db,
        org,
        userId: user.uid,
        entryToEdit: txToEdit,
      });
      setTimeout(() => {
        setTxEntryData(initialEntryDataState);
      }, 50);

      setTxMetaData({
        date: null,
        checkNumber: "",
        memo: "",
      });
      setDisplayContact([null]);
      setTouched({ account: false, fund: false });
      const generateRandomString = () =>
        Math.random().toString(36).substring(2, 7);
      setTimeout(() => {
        setSomeUniqueKeyThatForcesReRender(generateRandomString());
      }, 100);

      setSubmitting(false);
      console.log("After submission dateInputRef", dateInputRef.current);
      // Delay focusing to ensure the DOM has updated
      setTimeout(() => {
        if (dateInputRef.current) {
          dateInputRef.current.focus();
        }
      }, 300);

      if (closeModal) {
        closeModal();
      }
    }
  };

  const addSplitTxLine = () => {
    const addOne = (entryDataIn) => {
      //find the largest number of the keys in txEntryData
      const largestKey = Math.max(
        ...Object.keys(entryDataIn).map((key) => parseInt(key)),
      );
      if (Object.keys(entryDataIn).length === 1) {
        const firstLine = {
          credit: Object.values(entryDataIn)[0].credit
            ? Object.values(entryDataIn)[0].credit
            : "",
          debit: Object.values(entryDataIn)[0].debit
            ? Object.values(entryDataIn)[0].debit
            : "",
          contact: Object.values(entryDataIn)[0].contact,
          account: null,
          fund: "",
          comment: "",
        };
        console.log("firstLine: ", firstLine);
        return {
          0: firstLine,
          1: {
            comment: Object.values(entryDataIn)[0].comment,
            contact: null,
            credit: "",
            debit: "",
            account: Object.values(entryDataIn)[0].account,
            fund: Object.values(entryDataIn)[0].fund,
          },
        };
      } else {
        return {
          ...entryDataIn,
          [largestKey + 1]: {
            comment: "",
            contact: null,
            credit: "",
            debit: "",
            account: null,
            fund: "",
          },
        };
      }
    };
    let newDisplayContact = [...displayContact, null];
    let added = addOne(txEntryData);
    if (Object.keys(added).length === 2) {
      added = addOne(added);
      newDisplayContact.push(null);
    }

    setTxEntryData(added);
    setDisplayContact([...displayContact, null]);
  };

  const submitCallbackForCCPaymentTool = () => {
    setTxEntryData({
      0: {
        comment: "",
        contact: "",
        credit: "",
        debit: "",
        account: "",
        fund: "",
      },
    });
    setTxMetaData({
      date: null,
      checkNumber: "",
      memo: "",
    });
    setDisplayContact([null]);
    setTimeout(() => {
      setSomeUniqueKeyThatForcesReRender((prevState) => prevState.concat("a"));
    }, 200);

    setSubmitting(false);
  };

  const handleEnterPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSubmit();
    }
  };

  //Error style for date picker since prop is not working
  const errorStyleForDate = entryErrors?.date
    ? {
        color: "red",
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: "red",
          },
        },
        "& .MuiInputLabel-root": {
          // Custom style for the label
          color: "red",
        },
      }
    : {};

  const { height } = useWindowSize();
  const size = height > 800 ? "medium" : "small";

  return (
    <div onKeyDown={handleEnterPress}>
      <CCPaymentTool
        //Note the crossover in selectedAccount below, since this tool is intended from the perspective of the CC account, only here if user triggers it
        openPaymentTool={openPaymentTool}
        setOpenPaymentTool={setOpenPaymentTool}
        paymentToProcess={paymentToProcess}
        selectedAccount={creditCardAccount}
        accountToPayFromInput={selectedAccount}
        submitCallback={submitCallbackForCCPaymentTool}
      />
      <Grid container rowSpacing={1} columnSpacing={1}>
        <Grid item xs={2.5} sm={2.5} md={2.5} lg={2} xl={1.5}>
          <FormControl style={{ backgroundColor: "white" }} variant="filled">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label={<span>Transaction Date *</span>}
                value={txMetaData.date}
                onChange={(newDate) =>
                  setTxMetaData({ ...txMetaData, date: newDate })
                }
                sx={errorStyleForDate}
                disabled={
                  (location && location.some((l) => l === "plaidImport")) ||
                  Object.values(txEntryData).some((line) => line.cleared)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    color="secondary"
                    disabled={
                      (location && location.some((l) => l === "plaidImport")) ||
                      Object.values(txEntryData).some((line) => line.cleared)
                    }
                  />
                )}
                slotProps={{
                  textField: {
                    inputRef: dateInputRef,
                    size: size,
                    fullWidth: true,
                    disabled:
                      location?.some((l) => l === "plaidImport") ||
                      txToEdit?.lines.some((line) => line.cleared),
                  },
                }}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>
        <Grid item xs={4.5} sm={4} md={3.5} lg={3.5} xl={3.5}>
          <FormControl fullWidth variant="filled">
            <Autocomplete
              freeSolo
              autoSelect
              key={`${someUniqueKeyThatForcesReRender}-${0}`}
              label="Contact"
              name={`contact-${0}`}
              options={
                contacts
                  ? contacts
                      .filter(
                        (contact) =>
                          contact.shortName !== null &&
                          contact.shortName !== "" &&
                          contact.shortName !== undefined,
                      )
                      .map((contact) => contact.shortName)
                  : []
              }
              value={displayContact[0] || null}
              onInputChange={(event, newValue) => {
                const newEntryData = { ...txEntryData };
                console.log(newEntryData);

                const matchingContact = contacts.find(
                  (contact) => contact.shortName === newValue,
                );
                //This line doesn't exist yet, so we need to create it
                if (!newEntryData[0]) {
                  newEntryData[0] = {};
                }

                newEntryData[0].contact = matchingContact
                  ? matchingContact.id
                  : newValue;

                //Find the contact in the contactMapping, and if it exists and account || fund is blank replace account for this line with its mapped account and fund with its mapped fund
                if (matchingContact) {
                  const mappedAccountAndFund =
                    contactMapping[matchingContact?.id];
                  if (
                    mappedAccountAndFund &&
                    touched.account === false &&
                    Object.keys(newEntryData).length === 1 &&
                    (txToEdit === null || txToEdit === undefined) &&
                    mappedAccountAndFund.account !== selectedAccount
                  ) {
                    newEntryData[0].account = mappedAccountAndFund.account;
                  }
                  if (
                    mappedAccountAndFund &&
                    touched.fund === false &&
                    Object.keys(newEntryData).length === 1 &&
                    (txToEdit === null || txToEdit === undefined)
                  ) {
                    newEntryData[0].fund = mappedAccountAndFund.fund;
                  }
                }
                setTxEntryData(newEntryData);

                // Also update the display value
                setDisplayContact((prevDisplayContact) => {
                  const newDisplayContact = [...prevDisplayContact];
                  newDisplayContact[0] = matchingContact
                    ? matchingContact.shortName
                    : newValue;
                  return newDisplayContact;
                });
              }}
              renderInput={(params) => {
                // Determine if the entered value is in the list
                const isValueInList =
                  contacts &&
                  txEntryData["0"] &&
                  contacts.some(
                    (contact) => contact.id === txEntryData["0"].contact,
                  );

                let helperTextValue = "";

                // Check for entry errors
                if (
                  Object.keys(entryErrors).length > 0 &&
                  entryErrors[`contact-${0}`]
                ) {
                  helperTextValue = entryErrors[`contact-${0}`];
                } else {
                  // Handle the case when txToComplete exists
                  if (txToComplete) {
                    const merchantName =
                      txToComplete.merchant_name !== null
                        ? txToComplete.merchant_name
                        : txToComplete.name;
                    if (
                      displayContact[0] !== "" &&
                      !isValueInList &&
                      displayContact[0] !== "Journal Entry"
                    ) {
                      helperTextValue = `Will add as New Contact - Doesn't match any you have. Plaid Imported as: ${merchantName}`;
                    } else {
                      helperTextValue = `Plaid Imported as: ${merchantName}`;
                    }
                  }
                  // Handle the case when txToComplete doesn't exist
                  else {
                    if (
                      displayContact[0] !== null &&
                      !isValueInList &&
                      displayContact[0] !== "Journal Entry"
                    ) {
                      helperTextValue =
                        "Will add as New Contact - Doesn't match any you have";
                    }
                  }
                }

                return (
                  <ContactTextField
                    {...params}
                    name={`contact-${0}`}
                    label="Contact"
                    variant="outlined"
                    color="secondary"
                    // Change the helper text based on the entered value
                    helperText={helperTextValue}
                    error={entryErrors.contact}
                    required
                    size={size}
                  />
                );
              }}
            />
          </FormControl>
        </Grid>
        <Grid
          item
          xs={location && location.some((l) => l === "creditCards") ? 5 : 3}
          sm={location && location.some((l) => l === "creditCards") ? 5.5 : 4}
          md={location && location.some((l) => l === "creditCards") ? 6 : 4.5}
          lg={location && location.some((l) => l === "creditCards") ? 6.5 : 5}
          xl={location && location.some((l) => l === "creditCards") ? 7 : 5.5}>
          <FormControl fullWidth variant="filled">
            <WhiteTextField
              label="Memo"
              name="memo"
              value={txMetaData.memo}
              onChange={(e) =>
                setTxMetaData({ ...txMetaData, memo: e.target.value })
              }
              variant="outlined"
              color="secondary"
              size={size}
            />
          </FormControl>
        </Grid>
        {(!location ||
          !location.some((l) => l === "creditCards") ||
          txToComplete?.amount < 0) && (
          <Grid item xs={2} sm={1.5} lg={1.5} xl={1.5}>
            <FormControl variant="filled">
              <WhiteTextField
                label="Check Number"
                name="checkNumber"
                value={txMetaData.checkNumber}
                onChange={(e) =>
                  setTxMetaData({ ...txMetaData, checkNumber: e.target.value })
                }
                variant="outlined"
                color="secondary"
                size={size}
              />
            </FormControl>
          </Grid>
        )}
        {Object.keys(txEntryData).map((id, index) => (
          <React.Fragment key={`transLine${id}`}>
            {/* {Object.keys(txEntryData).length > 1 && index === 0 && (
              <div style={{ width: "50vw" }} />
            )} */}
            {(Object.keys(txEntryData).length === 1 || index >= 1) && (
              <React.Fragment>
                <Grid
                  item
                  //If the location is credit cards, we only have a charge so resize accordingly
                  xs={
                    index === 0 ||
                    (Object.keys(txEntryData).length > 1 &&
                      txEntryData[0]?.credit !== "") ||
                    txEntryData[0]?.debit !== ""
                      ? location && location.some((l) => l === "creditCards")
                        ? id === "0"
                          ? 3.5
                          : 3
                        : 2.5
                      : location && location.some((l) => l === "creditCards")
                      ? 4
                      : 3.5
                  }>
                  <FormControl
                    fullWidth
                    key={`formAccount-${id}`}
                    variant="outlined">
                    <Autocomplete
                      autoSelect
                      autoHighlight
                      id={`account-${id}`}
                      key={`account-${id}-${someUniqueKeyThatForcesReRender}`}
                      name={`account-${id}`}
                      options={
                        accounts?.length > 0
                          ? sortAccountsByType(
                              accounts,
                              ["expenses", "income", "assets", "liabilities"],
                              selectedAccount,
                              "Equity",
                            )
                          : []
                      }
                      groupBy={(option) => option.accountType}
                      getOptionLabel={(option) =>
                        ` ${option.accountNumber} - ${option.accountName}`
                      }
                      value={findAccountById(txEntryData[id].account) || null}
                      onChange={(event, newValue) => {
                        console.log(newValue); // This should give you the selected object
                        const newEntryData = { ...txEntryData };
                        newEntryData[id].account = newValue
                          ? newValue.id
                          : null; // Set to the account's id or null
                        setTxEntryData(newEntryData);
                      }}
                      renderInput={(params) => (
                        <WhiteTextField
                          {...params}
                          name={`account-${id}`}
                          label="Account"
                          variant="outlined"
                          color="secondary"
                          required
                          error={
                            Object.keys(entryErrors).length > 0 &&
                            (entryErrors[`account-${id}`] ||
                              (entryErrors.account && id === "0"))
                          }
                          // Change the helper text based on the entered value
                          helperText={
                            Object.keys(entryErrors).length > 0 &&
                            entryErrors[`account-${id}`]
                              ? entryErrors[`account-${id}`]
                              : ""
                          }
                          onFocus={() =>
                            setTouched({ ...touched, account: true })
                          }
                          size={size}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={
                    index === 0 ||
                    (Object.keys(txEntryData).length > 1 &&
                      txEntryData[0]?.credit !== "") ||
                    txEntryData[0]?.debit !== ""
                      ? location && location.some((l) => l === "creditCards")
                        ? id === "0"
                          ? 3.5
                          : 3
                        : 2.5
                      : location && location.some((l) => l === "creditCards")
                      ? 4
                      : 3.5
                  }>
                  <FormControl
                    fullWidth
                    key={`formFund-${id}`}
                    variant="outlined">
                    <Autocomplete
                      key={`fund-${id}-${someUniqueKeyThatForcesReRender}`}
                      id={`fund-${id}`}
                      autoSelect
                      autoHighlight
                      options={funds
                        .filter((fund) => !fund.disabled)
                        .sort((a, b) => {
                          // Sort by fundNumber, then fundName
                          if (a.fundNumber < b.fundNumber) {
                            return -1;
                          } else if (a.fundNumber > b.fundNumber) {
                            return 1;
                          } else {
                            return a.fundName.localeCompare(b.fundName);
                          }
                        })}
                      getOptionLabel={(option) =>
                        `${option.fundNumber} - ${option.fundName}`
                      }
                      value={
                        funds.find(
                          (fund) => fund.id === txEntryData[id].fund,
                        ) || null
                      }
                      onChange={(event, newValue) => {
                        console.log(newValue); // This should give you the selected object
                        const newEntryData = { ...txEntryData };
                        newEntryData[id].fund = newValue ? newValue.id : null; // Set to the account's id or null
                        setTxEntryData(newEntryData);
                      }}
                      renderInput={(params) => (
                        <WhiteTextField
                          {...params}
                          label="Fund"
                          required
                          variant="outlined"
                          color="secondary"
                          error={
                            Object.keys(entryErrors).length > 0 &&
                            (entryErrors[`fund-${id}`] || entryErrors.fund)
                          }
                          helperText={
                            Object.keys(entryErrors).length > 0 &&
                            entryErrors.fund
                              ? entryErrors.fund
                              : Object.keys(entryErrors).length > 0 &&
                                entryErrors[`fund-${id}`]
                              ? entryErrors[`fund-${id}`]
                              : ""
                          }
                          onFocus={() => setTouched({ ...touched, fund: true })}
                          size={size}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
              </React.Fragment>
            )}
            {Object.keys(txEntryData).length > 1 && id !== "0" ? (
              <Grid
                item
                xs={
                  location && location.some((l) => l === "creditCards")
                    ? 1.5
                    : 2
                }>
                <FormControl fullWidth variant="filled">
                  <WhiteTextField
                    label="Comment"
                    name={`comment-${id}`}
                    value={txEntryData[id].comment}
                    onChange={(e) =>
                      setTxEntryData({
                        ...txEntryData,
                        [id]: { ...txEntryData[id], comment: e.target.value },
                      })
                    }
                    variant="outlined"
                    color="secondary"
                    size={size}
                  />
                </FormControl>
              </Grid>
            ) : null}

            {(index === 0 ||
              (Object.keys(txEntryData).length > 1 &&
                txEntryData[0]?.credit !== "")) &&
              (!location ||
                !location.some((l) => l === "creditCards") ||
                txToComplete?.amount < 0) && (
                <Grid
                  item
                  xs={2.5}
                  xsOffset={
                    Object.keys(txEntryData).length > 1 && id === "0" ? 5 : 0
                  }>
                  <FormControl
                    key={`formAmount-${id}-credit`}
                    fullWidth
                    variant="filled">
                    <WhiteTextField
                      key={`input-${id}-credit`}
                      id="filled-adornment-amount"
                      name={`credit-${id}`}
                      label="Inflow"
                      color="secondary"
                      value={txEntryData[id].credit}
                      onChange={handleChangeAmount}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      variant="outlined"
                      onBlur={() => {
                        formatAmount(id);
                      }}
                      required={txEntryData[id].debit === ""}
                      helperText={
                        entryErrors?.splitTotal && id !== "0"
                          ? entryErrors.splitTotal
                          : Object.keys(txEntryData).length > 1 &&
                            txEntryData[0]?.credit !== "" &&
                            id === "0"
                          ? `$${formatCurrency(
                              remainderToAssign,
                            )} left to assign`
                          : ""
                      }
                      error={
                        (txEntryData[0]?.credit !== "" &&
                          entryErrors?.splitTotal) ||
                        entryErrors.amount
                      }
                      disabled={
                        (id === "0" || id === 0) &&
                        (txToEdit?.lines?.some(
                          (line) => line.cleared === true,
                        ) ||
                          location?.some((l) => l === "plaidImport"))
                      }
                      size={size}
                    />
                  </FormControl>
                </Grid>
              )}
            {(index === 0 ||
              (Object.keys(txEntryData).length > 1 &&
                txEntryData[0]?.debit !== "")) &&
              (!location ||
                location.some((l) => l === "creditCards") ||
                txToComplete?.amount > 0) && (
                <Grid
                  item
                  xs={
                    location && location.some((l) => l === "creditCards")
                      ? id === "0"
                        ? 3
                        : 2.5
                      : 2.5
                  }
                  xsOffset={
                    Object.keys(txEntryData).length > 1 &&
                    id === "0" &&
                    location &&
                    location.some((l) => l === "creditCards")
                      ? 7
                      : 0
                  }>
                  <FormControl
                    key={`formAmount-${id}-debit`}
                    fullWidth
                    variant="filled">
                    <WhiteTextField
                      key={`input-${id}-debit`}
                      id="filled-adornment-amount"
                      name={`debit-${id}`}
                      label={
                        location && location.some((l) => l === "creditCards")
                          ? "Charge"
                          : "Outflow"
                      }
                      color="secondary"
                      value={txEntryData[id].debit}
                      onChange={handleChangeAmount}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      variant="outlined"
                      onBlur={() => {
                        formatAmount(id);
                      }}
                      required={txEntryData[id].credit === ""}
                      helperText={
                        entryErrors?.splitTotal && id !== "0"
                          ? entryErrors.splitTotal
                          : Object.keys(txEntryData).length > 1 &&
                            txEntryData[0]?.debit !== "" &&
                            id === "0"
                          ? `$${formatCurrency(
                              remainderToAssign,
                            )} left to assign`
                          : ""
                      }
                      error={
                        (txEntryData[0]?.debit !== "" &&
                          entryErrors?.splitTotal) ||
                        entryErrors.amount
                      }
                      disabled={
                        (id === "0" || id === 0) &&
                        (txToEdit?.lines?.some(
                          (line) => line.cleared === true,
                        ) ||
                          location?.some((l) => l === "plaidImport"))
                      }
                      size={size}
                    />
                  </FormControl>
                </Grid>
              )}
            {index === 0 && (
              <Grid item xs={2}>
                {Object.keys(txEntryData).length === 1 ? (
                  <Tooltip title="Split Transaction">
                    <ControlDiv
                      onClick={() => {
                        addSplitTxLine();
                      }}>
                      <LineText style={{ fontSize: "1rem" }}>Split</LineText>
                      <CallSplitIcon
                        sx={{
                          height: "2rem",
                          width: "2rem",
                          transform: "rotate(90deg)",
                          color: primary_dark_color,
                        }}
                      />
                    </ControlDiv>
                  </Tooltip>
                ) : (
                  <Tooltip title="Add a split line">
                    <ControlDiv
                      onClick={() => {
                        addSplitTxLine();
                      }}>
                      <ControlLabelText style={{ fontSize: "1rem" }}>
                        Add a Line
                      </ControlLabelText>
                      <Add
                        sx={{
                          height: "30px",
                          width: "30px",
                          cursor: "pointer",
                          color: primary_dark_color,
                        }}
                      />
                    </ControlDiv>
                  </Tooltip>
                )}
              </Grid>
            )}
            {index !== 0 && (
              <Grid item xs={2}>
                <ControlDiv
                  onClick={() => {
                    //Check if there are only three lines, and if so, remove the split transaction
                    if (Object.keys(txEntryData).length === 3) {
                      const newEntryData = { ...txEntryData };
                      newEntryData[0].account = newEntryData[1]?.account
                        ? newEntryData[1].account
                        : "";
                      newEntryData[0].fund = newEntryData[1]?.fund
                        ? newEntryData[1].fund
                        : "";
                      Object.keys(newEntryData)
                        .filter((key) => key !== "0")
                        .forEach((key) => {
                          delete newEntryData[key];
                        });
                      setTxEntryData(newEntryData);
                      setDisplayContact([null]);
                      return;
                    }

                    //Remove the line from txEntryData that's key matches the id
                    const newEntryData = { ...txEntryData };
                    delete newEntryData[id];
                    setTxEntryData(newEntryData);

                    //Remove the line from displayContact that's key matches the id
                    const newDisplayContact = [...displayContact];
                    newDisplayContact.splice(id, 1);
                    setDisplayContact(newDisplayContact);
                  }}>
                  <ControlLabelText style={{ fontSize: "1rem" }}>
                    Remove
                  </ControlLabelText>
                  <Close
                    sx={{
                      height: "2rem",
                      width: "2rem",
                      transform: "rotate(90deg)",
                      cursor: "pointer",
                      color: primary_dark_color,
                    }}
                  />
                </ControlDiv>
              </Grid>
            )}
          </React.Fragment>
        ))}
      </Grid>
      {Object.keys(entryErrors).length !== 0 && (
        <MasterErrorText>
          {entryErrors.date
            ? entryErrors.date
            : entryErrors.account
            ? entryErrors.account
            : entryErrors.fund
            ? entryErrors.fund
            : entryErrors.amount
            ? entryErrors.amount
            : entryErrors.splitTotal}
        </MasterErrorText>
      )}
      <RowWrapper
        style={height > 800 ? { marginTop: "8px" } : { marginTop: 0 }}>
        <StyledButton
          disabled={submitting}
          // width="410px"
          fontSize={height > 800 ? "1.3rem" : "1rem"}
          secondary
          onClick={() => handleSubmit()}>
          {txToEdit ? "Save Changes" : "Record Transaction"}
        </StyledButton>
      </RowWrapper>
    </div>
  );
};

export default EnterBankingTransaction;
