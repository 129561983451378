import React, { useState, useEffect } from "react";
import dayjs from "dayjs";

import { doc, onSnapshot, collection } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import UserContext from "../assets/user_context";
import { useAuth } from "./use-auth";
import useFetchAndUpdateTransactions from "./maintain_sync_tx/maintain_sync_tx";
import {
  experimentalAccounts,
  experimentalAccountsHierarchy,
  experimentalContacts,
  experimentalFunds,
  experimentalFundsHierarchy,
  experimentalOrgData,
  experimentalOrgId,
  experimentalTransactions,
} from "../constants/experimental_org";

//Logic flow is import here to manage whether all the user and org's data is loaded or not.
// A user that is loaded but not onboarded will have no orgs, and no org data
// A user that is loaded and onboarded, but in an org that isn't onboarded, will not need a subscription
// A user that is loaded and onboarded, and in an org that is onboarded, will need a subscription

//Loading order to determine loading state: user -> org -> accounts, funds, contacts, org data -> transactions

//Modals that will manage the loading process:
// 1. Select org modal
// 2. Subscribe modal
// 3. Create org modal
// 4. Org onboarding modal

const UserProvider = ({ children }) => {
  const firstDayOfLastMonth = dayjs().subtract(6, "month").startOf("month");
  const today = dayjs();
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState({});
  const [org, setOrg] = useState(null); //This is the org the user is currently viewing
  const [orgData, setOrgData] = useState(null); //This is the org subscription status and more
  const [accounts, setAccounts] = useState([]);
  const [accountsHierarchy, setAccountsHierarchy] = useState({});
  const [funds, setFunds] = useState([]);
  const [fundsHierarchy, setFundsHierarchy] = useState({});
  const [contacts, setContacts] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [dateRangeForTx, setDateRangeForTx] = useState({
    firstDate: firstDayOfLastMonth,
    lastDate: today,
  });
  const [loaded, setLoaded] = useState(false);
  const [isParentDataLoaded, setIsParentDataLoaded] = useState(false);
  const [isChildDataLoaded, setIsChildDataLoaded] = useState(false);
  const [userStillLoading, setUserStillLoading] = useState(true);
  const [openSubscribe, setOpenSubscribe] = useState(false);
  const [selectOrgOpen, setSelectOrgOpen] = useState(false);
  const [experimental, setExperimental] = useState(false);
  const [monthlyAggregates, setMonthlyAggregates] = useState(null);
  const [experimentalReconcilliations, setExperimentalReconcilliations] =
    useState(null);
  const [orgRoles, setOrgRoles] = useState([]);
  //Delte queue is an array of objects that have the id of the transaction to delete, so the user can see their request to delete imedaiately as a strikethrough
  const [deleteQueue, setDeleteQueue] = useState([]);
  const [contactMapping, setContactMapping] = useState({});
  const [features, setFeatures] = useState({});
  const [donors, setDonors] = useState([]);
  //Track whether we've notified free trialers so we don't notify them again
  const [freeTrialNotified, setFreeTrialNotified] = useState(false);
  const [openTrialNotification, setOpenTrialNotification] = useState(false);
  const authHook = useAuth();

  //Load the user's data
  useEffect(() => {
    if (authHook.auth && !authHook.isLoadingFirebase) {
      const unsubscribe = onAuthStateChanged(authHook.auth, (user) => {
        setUserStillLoading(true);
        setIsChildDataLoaded(false);
        if (user) {
          // console.log('There appears to be a user with UID: ', user.uid, user);
          setUser(user);
          setUserStillLoading(false);
        } else {
          // console.log("Loaded, but no logged in user");
          setUserStillLoading(false);
          setUser(null);
          setUserData({});
          setIsParentDataLoaded(true);
          setIsChildDataLoaded(true);
        }
      });
      return () => {
        unsubscribe();
      };
    }
  }, [authHook, authHook?.auth, authHook?.isLoadingFirebase]);

  //Load the user's orgs, and check for more than one org
  useEffect(() => {
    if (authHook && !authHook.isLoadingFirebase && user && !experimental) {
      // console.log("Setting user data listener - Will SET ORGS", experimental);
      const unsub = onSnapshot(doc(authHook.db, "users", user.uid), (doc) => {
        if (doc.data() && doc.data().orgs && doc.data().orgs.length > 0) {
          //Check if the user is in more than one org
          if (doc.data().orgs.length > 1) {
            //Check if the user has a lastOrgUsed, if so set the org to that org
            if (doc.data().lastOrgUsed) {
              setOrg(doc.data().lastOrgUsed);
            } else {
              //The user is in more than one org and we have no record of their last org so open the select org dialog
              setSelectOrgOpen(true);
            }
          } else if (doc.data().orgs.length === 1) {
            //Set the user's org to the only org they are in
            setOrg(doc.data().orgs[0].orgID);
            // console.log("Got org: ", doc.data().orgs[0].orgID);
          }
        } else {
          setIsChildDataLoaded(true);
        }

        setUserData(doc.data());
        setIsParentDataLoaded(true);
      });
      return () => {
        unsub();
      };
    } else if (
      authHook &&
      !authHook.isLoadingFirebase &&
      !user &&
      experimental
    ) {
      setExperimental(false);
    }
  }, [authHook, authHook.db, setOrg, user, experimental]);

  //Load the org's accounts, funds, contacts, and org data
  useEffect(() => {
    if (
      authHook &&
      !authHook.isLoadingFirebase &&
      user &&
      org &&
      !experimental
    ) {
      //Get the org data
      const unsubOrgdata = onSnapshot(
        doc(authHook.db, "orgs", org),
        (orgDoc) => {
          // console.log("LISTENER got org data: ", doc.data());
          setOrgData(orgDoc.data());
          setOrgRoles(orgDoc.data().users[user.uid].roles);
          if (orgDoc.data().features) {
            console.log("FEATURES: ", orgDoc.data().features);
            setFeatures(orgDoc.data().features);
            //if features.donors is true, fetch the donors
            if (orgDoc.data().features.donorManagement) {
              const unsubDonors = onSnapshot(
                doc(authHook.db, "orgs", org, "donorManagement", "donors"),
                (donorsDoc) => {
                  console.log("DONORS:", donorsDoc.data()?.donors);
                  setDonors(donorsDoc.data()?.donors);
                },
              );
              return () => {
                unsubDonors();
              };
            }
          } else {
            setFeatures({});
          }
          if (!isChildDataLoaded) {
            // console.log("Setting CHILDLOADED from org data");
            setIsChildDataLoaded(true);
          }
        },
      );

      //Fetch Accounts
      const unsubAccounts = onSnapshot(
        collection(authHook.db, "orgs", org, "accounts"),
        (snapshot) => {
          setAccounts(
            snapshot.docs.map((doc) => {
              const account = { ...doc.data(), id: doc.id };
              return account;
            }),
          );
          // console.log(
          //   "Accounts found: ",
          //   snapshot.docs.map((doc) => {
          //     const account = { ...doc.data(), id: doc.id };
          //     return account;
          //   }),
          // );
          if (!isChildDataLoaded) {
            console.log("Setting CHILDLOADED from accounts loaded");
            setIsChildDataLoaded(true);
          }
        },
      );

      //Fetch Account Hierarchy
      const unsubAccountsHierarchy = onSnapshot(
        doc(
          authHook.db,
          "orgs",
          org,
          "organizationAndSettings",
          "accountsHierarchy",
        ),
        (doc) => {
          if (doc.data() !== undefined) {
            setAccountsHierarchy(doc.data());
            // console.log("Accounts hierarchy found: ", doc.data());
          } else {
            // console.log("No accounts hierarchy found");
            setAccountsHierarchy({});
            if (!isChildDataLoaded) {
              // console.log("Setting CHILDLOADED from NO HIERARCHY - accounts");
              setIsChildDataLoaded(true);
            }
          }
        },
      );

      //Fetch the funds
      const unsubFunds = onSnapshot(
        collection(authHook.db, "orgs", org, "funds"),
        (snapshot) => {
          setFunds(
            snapshot.docs.map((doc) => {
              const fund = { ...doc.data(), id: doc.id };
              return fund;
            }),
          );
          if (!isChildDataLoaded) {
            // console.log("Setting CHILDLOADED from funds loaded");
            setIsChildDataLoaded(true);
          }
          // console.log(
          //   "funds found: ",
          //   snapshot.docs.map((doc) => {
          //     const fund = { ...doc.data(), id: doc.id };
          //     return fund;
          //   }),
          // );
        },
      );

      //Fetch Fund Hierarchy
      const unsubFundHierarchy = onSnapshot(
        doc(
          authHook.db,
          "orgs",
          org,
          "organizationAndSettings",
          "fundsHierarchy",
        ),
        (doc) => {
          if (doc.data() !== undefined) {
            setFundsHierarchy(doc.data());
            // console.log("Funds hierarchy found: ", doc.data());
          } else {
            // console.log("No funds hierarchy found");
            setFundsHierarchy({});
            if (!isChildDataLoaded) {
              // console.log("Setting Child Loaded from NO HIERARCHY - funds");
              setIsChildDataLoaded(true);
            }
          }
        },
      );

      //Fetch the contacts
      const unsubContacts = onSnapshot(
        collection(authHook.db, "orgs", org, "contacts"),
        (snapshot) => {
          setContacts(
            snapshot.docs.map((doc) => {
              const contact = { ...doc.data(), id: doc.id };
              return contact;
            }),
          );
          // console.log(
          //   "Contacts found: ",
          //   snapshot.docs.map((doc) => {
          //     const contact = { ...doc.data(), id: doc.id };
          //     return contact;
          //   }),
          // );
          if (!isChildDataLoaded) {
            // console.log("Setting Child Loaded from contacts loaded");
            setIsChildDataLoaded(true);
          }
        },
      );

      //Fetch all the monthly aggregate docs from the monthlyAggregates collection
      const unsubMonthlyAggregates = onSnapshot(
        collection(authHook.db, "orgs", org, "monthlyAggregates"),
        (snapshot) => {
          const monthlyAggregates = {};
          snapshot.docs.forEach((doc) => {
            monthlyAggregates[doc.id] = doc.data();
          });
          setMonthlyAggregates(monthlyAggregates);
          if (!isChildDataLoaded) {
            // console.log("Setting Child Loaded from monthly aggregates loaded");
            setIsChildDataLoaded(true);
          }
        },
      );

      return () => {
        unsubOrgdata();
        unsubAccounts();
        unsubAccountsHierarchy();
        unsubFunds();
        unsubContacts();
        unsubFundHierarchy();
        unsubMonthlyAggregates();
      };
    }
    //Don't want to add setIsChildDataLoaded to the dependency array because it will cause an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authHook.db, user, org, authHook, experimental]);

  //All logic to fetch the transactions and update them
  useFetchAndUpdateTransactions({
    user,
    setIsChildDataLoaded,
    monthlyAggregates,
    authHook,
    org,
    orgData,
    setTransactions,
    experimental,
    setMonthlyAggregates,
    accounts,
    transactions,
    loaded,
    deleteQueue,
    setContactMapping,
  });

  useEffect(() => {
    if (
      authHook &&
      !authHook.isLoadingFirebase &&
      user &&
      org &&
      experimental
    ) {
      //Set accounts, funds, contacts, org data and transactions to experimental
      // console.log("Setting experimental data");
      setAccounts(experimentalAccounts);
      setFunds(experimentalFunds);
      setFundsHierarchy(experimentalFundsHierarchy);
      setAccountsHierarchy(experimentalAccountsHierarchy);
      setContacts(experimentalContacts);
      setOrg(experimentalOrgId);
      setOrgData(experimentalOrgData);
      setOrgRoles(["admin"]);
      setTransactions(
        experimentalTransactions.map((tx) => ({ ...tx, date: dayjs(tx.date) })),
      );
      if (!isChildDataLoaded) {
        // console.log("Setting Child Loaded from experimental being turned on");
        // setIsChildDataLoaded(true);
      }
    }
  }, [
    authHook.db,
    user,
    org,
    dateRangeForTx.firstDate,
    dateRangeForTx.lastDate,
    experimental,
    authHook,
    setOrg,
    isChildDataLoaded,
  ]);

  // //change experimental mode switched off
  useEffect(() => {
    if (
      authHook &&
      !authHook.isLoadingFirebase &&
      user &&
      userData?.orgs?.length > 0 &&
      !experimental
    ) {
      //Needs to setOrg to the default org of the user as above
      // console.log("Setting experimental data");

      if (userData && userData.orgs && userData.orgs.length > 0) {
        //Check if the user is in more than one org
        if (userData.orgs.length > 1) {
          //The user is in more than one org, so open the select org dialog
          setSelectOrgOpen(true);
        } else if (userData.orgs.length === 1) {
          //Set the user's org to the only org they are in
          // console.log(
          //   "One org found when EXITING experimental: ",
          //   userData.orgs[0].orgID,
          // );
          setOrg(userData.orgs[0].orgID);
        }
      } else {
        console.error(
          "No orgs found for user, should never happen when experimental is turned off since this should only happen in an org",
        );
        setOrg(null);
      }
      setAccounts([]);
      setFunds([]);
      setAccountsHierarchy({});
      setFundsHierarchy({});
      setContacts([]);
      setTransactions([]);
      setOrgRoles([]);
      setIsChildDataLoaded(true);
    }
  }, [experimental, setOrg]);

  // useEffect(() => {
  //   console.log("CHILDLOADED (After Only): ", isChildDataLoaded);
  // }, [isChildDataLoaded]);

  //Manage loading state
  useEffect(() => {
    //Manage loading state
    if (
      (isParentDataLoaded && isChildDataLoaded && !userStillLoading) ||
      (experimental && !userStillLoading)
    ) {
      console.log("------------TURNING OFF LOADING---------------");
      setLoaded(true);
    } else {
      console.log("------------TURNING ON LOADING---------------");
      setLoaded(false);
    }
  }, [isParentDataLoaded, isChildDataLoaded, userStillLoading]);

  // // Log the orgData when it changes
  // useEffect(() => {
  //   console.log("OrgData changed: ", orgData);
  // }, [orgData]);

  // //Log the org when it changes
  // useEffect(() => {
  //   console.log("Org changed: ", org);
  // }, [org]);

  useEffect(() => {
    if (experimental && org !== experimentalOrgId) {
      setExperimental(false);
    }
    if (accounts?.length > 0 || funds?.length > 0 || contacts?.length > 0) {
      //When the org changes, reset all the data
      console.log("Org changed, resetting all data");
      setAccounts([]);
      setFunds([]);
      setAccountsHierarchy({});
      setFundsHierarchy({});
      setContacts([]);
      setTransactions([]);
      setIsChildDataLoaded(false);
      setLoaded(false);
      setOrgData(null);
      setMonthlyAggregates(null);
    }
    if (
      orgData?.users &&
      Object.keys(orgData.users).find((key) => key === user.uid)
    ) {
      setOrgRoles([orgData.users[user.uid].roles]);
    }
  }, [org]);

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        userData,
        setUserData,
        org,
        setOrg,
        orgData,
        orgRoles,
        accounts,
        funds,
        setFunds,
        contacts,
        accountsHierarchy,
        fundsHierarchy,
        setFundsHierarchy,
        transactions,
        dateRangeForTx,
        setDateRangeForTx,
        loaded,
        setIsChildDataLoaded,
        userStillLoading,
        setOpenSubscribe,
        openSubscribe,
        selectOrgOpen,
        setSelectOrgOpen,
        isParentDataLoaded,
        experimental,
        setExperimental,
        setAccounts,
        setAccountsHierarchy,
        setTransactions,
        setContacts,
        monthlyAggregates,
        setUserStillLoading,
        experimentalReconcilliations,
        setExperimentalReconcilliations,
        deleteQueue,
        setDeleteQueue,
        contactMapping,
        features,
        donors,
        freeTrialNotified,
        setFreeTrialNotified,
        openTrialNotification,
        setOpenTrialNotification,
      }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
