import React, { useContext, useState } from "react";
import {
  ClickableLineText,
  CollapsableTableItem,
  LargeLineText,
  LineText,
} from "../reports_styles";
import { findAccountById } from "../../../utilities/general_util";
import styled from "@emotion/styled";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import UserContext from "../../../assets/user_context";
import { isPlainObject } from "jquery";

const GroupDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 0.25rem;

  border-radius: 5px;
  background-color: #f0f0f0;
  width: fit-content;
  cursor: pointer;
  width: 100%;
`;

const ParentAccountDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  cursor: pointer;
  width: 100%;
`;

const formatNum = (num) => {
  const float = parseFloat(num / 100);
  const formatted = new Intl.NumberFormat("en-us", {
    style: "currency",
    currency: "USD",
  }).format(float);
  return formatted;
};

const AccountLine = ({
  account,
  reportsData,
  headerText,
  accounts,
  showFunds,
  setTxModalOpen,
  setTxToDrill,
  collapsedGroup,
  isSubAccount,
  hasSubAccounts,
  toggleSubAccounts,
  subAccountTotals,
  rollUpSubAccounts,
  fundGroupsToShow,
}) => {
  const { fundsHierarchy } = useContext(UserContext);
  const [hoveredAccount, setHoveredAccount] = useState(null);
  let hasABalance = false;
  if (reportsData["total"] > 0) {
    hasABalance = true;
  }
  //Account line which could be an account or a subAccount
  const sum = Object.keys(reportsData)
    .filter((fundId) => fundId !== "total")
    .reduce((acc, fundID) => {
      const foundAccount = reportsData[fundID]?.[headerText.toLowerCase()].find(
        (a) => a.id === account.id,
      );
      if (foundAccount && foundAccount.intTotal > 0) {
        hasABalance = true;
      }
      return acc + (foundAccount ? parseInt(foundAccount.intTotal) : 0);
    }, 0);
  const accountMetadata = findAccountById({ accountId: account.id, accounts });

  return (
    <React.Fragment>
      {!collapsedGroup && (hasABalance || !accountMetadata.disabled) ? (
        <tr
          onMouseEnter={() => setHoveredAccount(account.id)}
          onMouseLeave={() => setHoveredAccount(null)}
          style={
            hoveredAccount === account.id
              ? { backgroundColor: "#f6f5f5", borderRadius: "5rem" }
              : {}
          }>
          <td
            style={
              hoveredAccount === account.id
                ? { borderRadius: "1rem 0 0 1rem" }
                : {}
            }>
            {hasSubAccounts ? (
              rollUpSubAccounts ? (
                <ParentAccountDiv onClick={() => toggleSubAccounts()}>
                  <LineText>{`${
                    findAccountById({ accountId: account.id, accounts })
                      .accountNumber
                  } - ${
                    findAccountById({ accountId: account.id, accounts })
                      .accountName
                  }`}</LineText>
                  <ExpandLess id="expand-button" style={{ color: "#5aaad8" }} />
                </ParentAccountDiv>
              ) : (
                <ParentAccountDiv onClick={() => toggleSubAccounts()}>
                  <LineText>{`${
                    findAccountById({ accountId: account.id, accounts })
                      .accountNumber
                  } - ${
                    findAccountById({ accountId: account.id, accounts })
                      .accountName
                  }`}</LineText>
                  <ExpandMore id="expand-button" style={{ color: "#5aaad8" }} />
                </ParentAccountDiv>
              )
            ) : (
              <LineText style={isSubAccount ? { paddingLeft: "8%" } : null}>{`${
                findAccountById({ accountId: account.id, accounts })
                  .accountNumber
              } - ${
                findAccountById({ accountId: account.id, accounts }).accountName
              }`}</LineText>
            )}
          </td>

          {fundsHierarchy.groups.map((group, groupIndex) => {
            //Sum the funds for this account in this group
            const accountTotalForFundsInGroup = group.funds.reduce(
              (acc, fund, fundIndex) => {
                let subFundsTotal = 0;
                if (fund.subFunds?.length > 0) {
                  subFundsTotal += fund.subFunds.reduce((acc, subFund) => {
                    const subFundTotal = reportsData[subFund]?.[
                      headerText.toLowerCase()
                    ].find((a) => a.id === account.id);
                    return acc + (subFundTotal?.intTotal || 0);
                  }, 0);
                }

                const foundAccount = reportsData[fund.id]?.[
                  headerText.toLowerCase()
                ].find((a) => a.id === account.id);
                if (foundAccount && foundAccount.intTotal > 0) {
                  return foundAccount.intTotal
                    ? acc + parseInt(foundAccount.intTotal) + subFundsTotal
                    : acc + subFundsTotal;
                } else {
                  return acc + subFundsTotal;
                }
              },
              0,
            );

            //Map over the funds to display horizontally
            return group.funds.map((fund, fundIndex) => {
              if (fund.subFunds?.length > 0) {
                //Itterate through the subFunds and the parent fund, creating totals like above, then create a collapsedParentTotal to display if collapsed
                const parentAccountTotalInFund =
                  reportsData[fund.id]?.[headerText.toLowerCase()].find(
                    (a) => a.id === account.id,
                  )?.intTotal || 0;
                let parentAccountTotalInFundWithSubFunds =
                  parentAccountTotalInFund;
                const subFundsWithTotals = fund.subFunds.map((subFund) => {
                  const subFundTotal = reportsData[subFund]?.[
                    headerText.toLowerCase()
                  ].find((a) => a.id === account.id);
                  // console.log("subFundTotal", subFundTotal);
                  parentAccountTotalInFundWithSubFunds +=
                    subFundTotal?.intTotal || 0;
                  return { thisAccountTotal: subFundTotal?.intTotal || 0 };
                });

                return (
                  <React.Fragment key={`${groupIndex}-${fundIndex}`}>
                    {(fundGroupsToShow?.[groupIndex]?.show ||
                      fundIndex === 0) && (
                      <CollapsableTableItem
                        shouldShow={showFunds}
                        key={`tableItem-${fundIndex}`}>
                        <ClickableLineText
                          style={{ textAlign: "right" }}
                          onClick={() => {
                            setTxModalOpen(true);
                            setTxToDrill({
                              fund: fund.id,
                              account: account.id,
                            });
                          }}>
                          {rollUpSubAccounts &&
                          subAccountTotals?.length > 0 &&
                          !fundGroupsToShow?.[groupIndex]?.show
                            ? //Roll up the subAccounts AND the funds into the group
                              formatNum(
                                subAccountTotals[groupIndex]?.fundsGroupTotal[
                                  fundIndex
                                ].parentFundTotal,
                              )
                            : rollUpSubAccounts &&
                              subAccountTotals?.length > 0 &&
                              fundGroupsToShow?.[groupIndex]?.show &&
                              fundGroupsToShow?.[groupIndex]?.funds[
                                fundIndex
                              ].subFunds.every((subFund) => subFund.show)
                            ? //Parent Fund Total without sub funds, parent account with subaccounts rolled up
                              formatNum(
                                parentAccountTotalInFund +
                                  subAccountTotals[groupIndex]?.fundsGroupTotal[
                                    fundIndex
                                  ].total,
                              )
                            : !fundGroupsToShow?.[groupIndex]?.show &&
                              fundIndex === 0
                            ? //Roll up the funds into the group
                              formatNum(parentAccountTotalInFundWithSubFunds)
                            : fundGroupsToShow?.[groupIndex]?.funds[
                                fundIndex
                              ].subFunds.some((subFund) => !subFund.show) &&
                              !rollUpSubAccounts
                            ? //Parent Fund rolls up subFunds
                              formatNum(parentAccountTotalInFundWithSubFunds)
                            : fundGroupsToShow?.[groupIndex]?.funds[
                                fundIndex
                              ].subFunds.some((subFund) => !subFund.show) &&
                              rollUpSubAccounts
                            ? //Parent Fund Total with rolled up subFunds and rolled up subAccounts
                              formatNum(
                                subAccountTotals[groupIndex]?.fundsGroupTotal[
                                  fundIndex
                                ].parentFundTotal,
                              )
                            : //Parent Fund Total (displays in accounts and subAccounts)
                              formatNum(parentAccountTotalInFund)}
                        </ClickableLineText>
                      </CollapsableTableItem>
                    )}
                    {subFundsWithTotals.map((subFund, subFundIndex) => (
                      <React.Fragment key={`${groupIndex}-${subFundIndex}`}>
                        {fundGroupsToShow?.[groupIndex]?.show &&
                          fundGroupsToShow?.[groupIndex]?.funds[fundIndex]
                            .subFunds[subFundIndex].show && (
                            <CollapsableTableItem
                              shouldShow={showFunds}
                              key={`tableItem-${subFundIndex}`}>
                              <ClickableLineText
                                style={{ textAlign: "right" }}
                                onClick={() => {
                                  setTxModalOpen(true);
                                  setTxToDrill({
                                    fund: fund.subFunds[subFundIndex],
                                    account: account.id,
                                  });
                                }}>
                                {/* subFund, displays acconts and subAccounts */}
                                {rollUpSubAccounts
                                  ? //parent account, within subFund, subAccounts rolled up
                                    formatNum(
                                      subAccountTotals[groupIndex]
                                        .fundsGroupTotal[fundIndex].subFunds[
                                        subFundIndex
                                      ].total,
                                    )
                                  : formatNum(subFund.thisAccountTotal)}
                              </ClickableLineText>
                            </CollapsableTableItem>
                          )}
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                );
              } else {
                //subFunds.length === 0
                const foundAccount = reportsData[fund.id]?.[
                  headerText.toLowerCase()
                ].find((a) => a.id === account.id);

                return (
                  // The fund within the account row
                  <React.Fragment key={`${groupIndex}-${fundIndex}`}>
                    {(fundGroupsToShow?.[groupIndex]?.show ||
                      fundIndex === 0) && (
                      <CollapsableTableItem
                        shouldShow={showFunds}
                        key={`tableItem-${fundIndex}`}>
                        <ClickableLineText
                          style={{ textAlign: "right" }}
                          onClick={() => {
                            setTxModalOpen(true);
                            setTxToDrill({
                              fund: fund.id,
                              account: foundAccount.id,
                            });
                          }}>
                          {foundAccount
                            ? rollUpSubAccounts &&
                              subAccountTotals?.length > 0 &&
                              !fundGroupsToShow?.[groupIndex]?.show
                              ? // formatNum(
                                //     // subAccountTotals[
                                //     //   groupIndex
                                //     // ].fundsGroupTotal.reduce((acc, fund) => {
                                //     //   console.log("fund", fund);
                                //     //   if (fund?.total && !isNaN(fund.total)) {
                                //     //     console.log("fund.total", fund.total);
                                //     //     return fund.total + acc;
                                //     //   } else {
                                //     //     console.log("acc", acc);
                                //     //     return acc;
                                //     //   }
                                //     // }, 0),
                                //     subAccountTotals[groupIndex].groupGroupTotal,
                                //   )
                                formatNum(
                                  subAccountTotals[groupIndex]?.groupGroupTotal,
                                )
                              : rollUpSubAccounts &&
                                subAccountTotals?.length > 0
                              ? //Roll up the subAccounts AND the funds into the group
                                formatNum(
                                  subAccountTotals[groupIndex]?.fundsGroupTotal[
                                    fundIndex
                                  ].total,
                                )
                              : !fundGroupsToShow?.[groupIndex]?.show &&
                                fundIndex === 0
                              ? //Collapsed display to fund group
                                formatNum(accountTotalForFundsInGroup)
                              : //Normal display
                                formatNum(foundAccount.intTotal)
                            : // formatNum(0)
                            rollUpSubAccounts
                            ? !fundGroupsToShow?.[groupIndex]?.show &&
                              fundIndex === 0
                              ? formatNum(
                                  subAccountTotals[groupIndex]?.groupGroupTotal,
                                )
                              : formatNum(
                                  subAccountTotals[groupIndex]?.fundsGroupTotal[
                                    fundIndex
                                  ]?.total,
                                )
                            : formatNum(0)}
                        </ClickableLineText>
                      </CollapsableTableItem>
                    )}
                  </React.Fragment>
                );
              }
            });
          })}

          <td
            style={
              hoveredAccount === account.id
                ? { borderRadius: "0 1rem 1rem 0" }
                : {}
            }>
            <LineText style={{ textAlign: "right" }}>
              {rollUpSubAccounts && subAccountTotals?.length > 0
                ? formatNum(subAccountTotals[subAccountTotals.length - 1])
                : formatNum(sum)}
            </LineText>
          </td>
        </tr>
      ) : null}
    </React.Fragment>
  );
};

const GroupAccountLines = ({
  group,
  collapsedGroup,
  accounts,
  reportsData,
  headerText,
  showFunds,
  setTxModalOpen,
  setTxToDrill,
  fundGroupsToShow,
}) => {
  const { fundsHierarchy } = useContext(UserContext);
  const [showSubAccounts, setShowSubAccounts] = useState(
    group.accounts.map(() => true),
  );
  return group.accounts.map((account, index) => {
    if (account?.subAccounts?.length > 0) {
      //There are subAccounts
      if (showSubAccounts[index]) {
        return (
          <React.Fragment key={account.id}>
            <AccountLine
              account={account}
              reportsData={reportsData}
              headerText={headerText}
              accounts={accounts}
              showFunds={showFunds}
              setTxModalOpen={setTxModalOpen}
              setTxToDrill={setTxToDrill}
              collapsedGroup={collapsedGroup}
              isSubAccount={false}
              hasSubAccounts={true}
              toggleSubAccounts={() =>
                setShowSubAccounts((prev) => {
                  const current = [...prev];
                  current[index] = !current[index];
                  return current;
                })
              }
              rollUpSubAccounts={false}
              fundGroupsToShow={fundGroupsToShow}
            />
            {account.subAccounts.map((subAccount) => (
              <AccountLine
                account={{ id: subAccount }}
                reportsData={reportsData}
                headerText={headerText}
                accounts={accounts}
                showFunds={showFunds}
                setTxModalOpen={setTxModalOpen}
                setTxToDrill={setTxToDrill}
                collapsedGroup={collapsedGroup}
                isSubAccount={true}
                hasSubAccounts={false}
                fundGroupsToShow={fundGroupsToShow}
              />
            ))}
          </React.Fragment>
        );
      } else {
        //ParentAccountTotalHelper
        const parentAccountTotalHelper = ({ fund }) => {
          const fundID = fund.id;
          console.log("fundID", fundID, headerText.toLowerCase());
          // For each fund, sum up the balances of all subAccounts of the parent account
          const allSubAccounts = account.subAccounts;
          const allSubAccountsTotal = allSubAccounts.reduce(
            (acc, accountId) => {
              // Use accountId to find the corresponding account in reportsData
              console.log(
                "reportsData",
                reportsData[fundID]?.[headerText.toLowerCase()],
                "subAccountId",
                accountId,
              );
              const foundAccount = reportsData[fundID]?.[
                headerText.toLowerCase()
              ].find((account) => account.id === accountId);
              console.log("foundSubAccount", foundAccount);
              return acc + (foundAccount ? parseInt(foundAccount.intTotal) : 0);
            },
            0,
          );
          const parentAccountTotal =
            allSubAccountsTotal +
            (parseInt(
              reportsData[fundID]?.[headerText.toLowerCase()].find(
                (a) => a.id === account.id,
              )?.intTotal || 0,
            ) || 0);

          return parentAccountTotal;
        };

        //We know there are subAccounts, but they are collapsed
        let fundTotals = fundsHierarchy.groups.map((fundGroup) => {
          let groupGroupTotal = 0;
          //Mapping over the fund groups
          const fundsGroupArr = fundGroup.funds.map((fund) => {
            //Mapping over the funds in the fund group
            //Check if there are subFunds for this fund
            if (fund.subFunds?.length > 0) {
              console.log(fund.subFunds);
              //Itterate through the subFunds and the parent fund, creating totals like above, then create a collapsedParentTotal to display if collapsed
              let allAccountsTotalInFund = parentAccountTotalHelper({
                fund,
              });
              let parentFundTotal = allAccountsTotalInFund;
              const subFundsWithTotals = fund.subFunds.map((subFund) => {
                const subFundTotal = parentAccountTotalHelper({
                  fund: { id: subFund },
                });
                //Increment the parent fund total by the subFund total
                parentFundTotal += subFundTotal;
                console.log(
                  "subFundTotal",
                  subFundTotal,
                  "fundTotal",
                  allAccountsTotalInFund,
                );
                groupGroupTotal += subFundTotal;
                return { total: subFundTotal };
              });
              //Increment the groupGroupTotal by the parent fund total
              groupGroupTotal += allAccountsTotalInFund;
              return {
                total: allAccountsTotalInFund,
                parentFundTotal: parentFundTotal,
                subFunds: subFundsWithTotals,
              };
            } else {
              const allAccountsTotalInFund = parentAccountTotalHelper({
                fund,
              });
              console.log("allAccountsTotalInFund", allAccountsTotalInFund);
              groupGroupTotal += allAccountsTotalInFund;

              return { total: allAccountsTotalInFund };
            }
          });
          //This is a group of accounts, so returning both the fund group and funds totals (funds because they may have subfunds)
          return {
            groupGroupTotal: groupGroupTotal,
            fundsGroupTotal: fundsGroupArr,
          };
        });

        // // Calculate the grand total across all funds for the group
        const grandTotal = fundTotals.reduce((acc, fundTotal) => {
          console.log("FUND GROUP TOTAL", fundTotal);
          //if there are subFunds, we need to sum the parentFundTotal
          if (fundTotal.fundsGroupTotal[0]?.subFunds?.length > 0) {
            return (
              acc +
              fundTotal.fundsGroupTotal.reduce((acc, fundTotal) => {
                return acc + fundTotal.parentFundTotal;
              }, 0)
            );
          } else {
            return acc + fundTotal.groupGroupTotal;
          }
        }, 0);

        // Append the grand total to the fundTotals array
        fundTotals.push(grandTotal);

        // fundTotals now has the total for each fund with the last element being the grand total across all funds.

        console.log("fundTotals for subAccounts", fundTotals);
        //These need to be added to the parent account

        return (
          <AccountLine
            account={account}
            reportsData={reportsData}
            headerText={headerText}
            accounts={accounts}
            showFunds={showFunds}
            setTxModalOpen={setTxModalOpen}
            setTxToDrill={setTxToDrill}
            collapsedGroup={collapsedGroup}
            isSubAccount={false}
            hasSubAccounts={true}
            toggleSubAccounts={() =>
              setShowSubAccounts((prev) => {
                const current = [...prev];
                current[index] = !current[index];
                return current;
              })
            }
            subAccountTotals={fundTotals}
            rollUpSubAccounts={true}
            fundGroupsToShow={fundGroupsToShow}
          />
        );
      }
    } else {
      //This account has no subAccounts
      return (
        <AccountLine
          account={account}
          reportsData={reportsData}
          headerText={headerText}
          accounts={accounts}
          showFunds={showFunds}
          setTxModalOpen={setTxModalOpen}
          setTxToDrill={setTxToDrill}
          collapsedGroup={collapsedGroup}
          isSubAccount={false}
          hasSubAccounts={false}
          fundGroupsToShow={fundGroupsToShow}
        />
      );
    }
  });
};

const GroupAccounts = ({
  group,
  reportsData,
  headerText,
  accounts,
  showFunds,
  setTxModalOpen,
  setTxToDrill,
  moreThanOneGroup,
  fundGroupsToShow,
}) => {
  const { fundsHierarchy } = useContext(UserContext);
  const [collapsedGroup, setCollapsedGroup] = useState(false);
  const groupName = group.groupName;

  //Helper:
  const accountsTotalHelper = ({ fund }) => {
    const fundID = fund.id;
    // console.log("fundID", fundID, headerText.toLowerCase());
    // For each fund, sum up the balances of all accounts in the group
    const allAccounts = group.accounts.map((account) => account.id);
    group.accounts.forEach((account) => {
      if (account.subAccounts?.length > 0) {
        allAccounts.push(...account.subAccounts);
      }
    });

    //Sum all accounts in this group of accounts (within this fund)
    const allAccountsTotal = allAccounts.reduce((acc, accountId) => {
      // Use accountId to find the corresponding account in reportsData
      // console.log(
      //   "reportsData",
      //   reportsData[fundID][headerText.toLowerCase()],
      //   "accountId",
      //   accountId,
      // );
      const foundAccount = reportsData[fundID]?.[headerText.toLowerCase()].find(
        (account) => account.id === accountId,
      );
      // console.log("foundAccount", foundAccount);
      if (foundAccount) {
        return acc + (foundAccount ? parseInt(foundAccount.intTotal) : 0);
      } else {
        return acc;
      }
    }, 0);

    return allAccountsTotal;
  };

  // Need to sum all the accounts total in this group
  // Initialize an array to hold the sum for each fund

  //Sum each fund for this account group
  let fundTotals = fundsHierarchy.groups.map((fundGroup) => {
    let groupGroupTotal = 0;
    const fundsGroupArr = fundGroup.funds.map((fund) => {
      //Check if there are subFunds for this fund
      if (fund.subFunds?.length > 0) {
        // console.log(fund.subFunds);
        //Itterate through the subFunds and the parent fund, creating totals like above, then create a collapsedParentTotal to display if collapsed
        let allAccountsTotalInFund = accountsTotalHelper({ fund });
        let parentFundTotal = allAccountsTotalInFund;
        const subFundsWithTotals = fund.subFunds.map((subFund) => {
          const subFundTotal = accountsTotalHelper({ fund: { id: subFund } });
          //Increment the parent fund total by the subFund total
          parentFundTotal += subFundTotal;
          groupGroupTotal += subFundTotal;
          // console.log(
          //   "subFundTotal",
          //   subFundTotal,
          //   "fundTotal",
          //   allAccountsTotalInFund,
          // );
          return { total: subFundTotal };
        });
        //Increment the groupGroupTotal by the parent fund total
        groupGroupTotal += allAccountsTotalInFund;
        return {
          total: allAccountsTotalInFund,
          parentFundTotal: parentFundTotal,
          subFunds: subFundsWithTotals,
        };
      } else {
        const allAccountsTotalInFund = accountsTotalHelper({ fund });
        groupGroupTotal += allAccountsTotalInFund;

        return { total: allAccountsTotalInFund };
      }
    });
    //This is a group of accounts, so returning both the fund group and funds totals (funds because they may have subfunds)
    return { groupGroupTotal: groupGroupTotal, fundsGroupTotal: fundsGroupArr };
  });

  //TODO: sum each fund group for this account group

  // Calculate the grand total across all funds for the group
  const grandTotal = fundTotals.reduce((acc, fundTotal) => {
    console.log(fundTotal);
    //handle subFunds if there are any by adding to the parentFundTotal
    if (fundTotal.fundsGroupTotal[0]?.subFunds?.length > 0) {
      return (
        acc +
        fundTotal.fundsGroupTotal.reduce((acc, fundTotal) => {
          // console.log("fund", fundTotal);
          return acc + fundTotal.parentFundTotal;
        }, 0)
      );
    } else {
      return acc + fundTotal.groupGroupTotal;
    }
  }, 0);

  // Append the grand total to the fundTotals array
  fundTotals.push(grandTotal);

  // fundTotals now has the total for each fund with the last element being the grand total across all funds.

  // console.log("fundTotals", fundTotals);
  // console.log("fundGroupsToShow", fundGroupsToShow);

  return (
    <React.Fragment>
      {/* Title for this group, with totals */}
      {moreThanOneGroup && (
        <tr>
          {collapsedGroup ? (
            <td
              style={
                collapsedGroup
                  ? {}
                  : {
                      backgroundColor: "#f0f0f0",
                      borderTopLeftRadius: "10px",
                      borderBottomLeftRadius: "10px",
                    }
              }>
              <GroupDiv onClick={() => setCollapsedGroup(!collapsedGroup)}>
                <LineText style={{ textDecoration: "underline" }}>
                  {groupName}
                </LineText>
                <ExpandLess id="expand-button" />
              </GroupDiv>
            </td>
          ) : (
            <td
              style={
                collapsedGroup
                  ? {}
                  : {
                      backgroundColor: "#f0f0f0",
                      borderTopLeftRadius: "10px",
                      borderBottomLeftRadius: "10px",
                    }
              }>
              <GroupDiv onClick={() => setCollapsedGroup(!collapsedGroup)}>
                <LineText style={{ textDecoration: "underline" }}>
                  {groupName}
                </LineText>
                <ExpandMore id="expand-button" />
              </GroupDiv>
            </td>
          )}
          {fundTotals.map((group, groupIndex) => (
            <React.Fragment>
              {isPlainObject(group) ? (
                group.fundsGroupTotal.map((fundTotal, fundIndex) =>
                  fundTotal?.subFunds?.length > 0 ? (
                    <React.Fragment key={`${groupIndex}-${fundIndex}`}>
                      {(fundGroupsToShow?.[groupIndex]?.show ||
                        fundIndex === 0) && (
                        <React.Fragment
                          key={`Inner-${groupIndex}-${fundIndex}`}>
                          <CollapsableTableItem
                            shouldShow={showFunds}
                            accountCollapse={collapsedGroup}
                            style={
                              collapsedGroup
                                ? {}
                                : { backgroundColor: "#f0f0f0" }
                            }>
                            <LineText
                              style={{
                                textAlign: "right",
                                fontWeight: "bold",
                              }}>
                              {fundGroupsToShow?.[groupIndex]?.show
                                ? fundGroupsToShow?.[groupIndex]?.funds[
                                    fundIndex
                                  ].subFunds.every((subFund) => subFund.show)
                                  ? //Account Group - Parent Fund Total without sub funds,
                                    formatNum(fundTotal.total)
                                  : //Group Total for Parent Fund with sub funds rolled up
                                    formatNum(fundTotal.parentFundTotal)
                                : //Accont Group - FundGroup Total Rolled Up with subFunds
                                  formatNum(fundTotal.parentFundTotal)}
                            </LineText>
                          </CollapsableTableItem>
                          {fundTotal.subFunds.map((subFund, subFundIndex) => (
                            <React.Fragment
                              key={`${groupIndex}-${subFundIndex}-total`}>
                              {fundGroupsToShow?.[groupIndex]?.funds[fundIndex]
                                .subFunds[subFundIndex].show && (
                                <CollapsableTableItem
                                  shouldShow={showFunds}
                                  accountCollapse={collapsedGroup}
                                  style={
                                    collapsedGroup
                                      ? {}
                                      : { backgroundColor: "#f0f0f0" }
                                  }>
                                  <LineText
                                    style={{
                                      textAlign: "right",
                                      fontWeight: "bold",
                                    }}>
                                    {formatNum(subFund.total)}
                                  </LineText>
                                </CollapsableTableItem>
                              )}
                            </React.Fragment>
                          ))}
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  ) : (
                    <React.Fragment key={`${groupIndex}-${fundIndex}`}>
                      {(fundGroupsToShow?.[groupIndex]?.show ||
                        fundIndex === 0) && (
                        <CollapsableTableItem
                          shouldShow={showFunds}
                          accountCollapse={collapsedGroup}
                          style={
                            collapsedGroup ? {} : { backgroundColor: "#f0f0f0" }
                          }>
                          <LineText
                            style={{
                              textAlign: "right",
                              fontWeight: "bold",
                            }}>
                            {fundGroupsToShow?.[groupIndex]?.show
                              ? formatNum(fundTotal.total)
                              : formatNum(group.groupGroupTotal)}
                          </LineText>
                        </CollapsableTableItem>
                      )}
                    </React.Fragment>
                  ),
                )
              ) : (
                <td
                  style={
                    collapsedGroup
                      ? {}
                      : {
                          backgroundColor: "#f0f0f0",
                          borderTopRightRadius: "10px",
                          borderBottomRightRadius: "10px",
                        }
                  }>
                  <LineText style={{ textAlign: "right", fontWeight: "bold" }}>
                    {formatNum(group)}
                  </LineText>
                </td>
              )}
            </React.Fragment>
          ))}
        </tr>
      )}

      <GroupAccountLines
        group={group}
        collapsedGroup={collapsedGroup}
        accounts={accounts}
        reportsData={reportsData}
        headerText={headerText}
        showFunds={showFunds}
        setTxModalOpen={setTxModalOpen}
        setTxToDrill={setTxToDrill}
        fundGroupsToShow={fundGroupsToShow}
      />
    </React.Fragment>
  );
};

const AccountRows = ({
  reportsData,
  setTxToDrill,
  setTxModalOpen,
  showFunds,
  accounts,
  headerText,
  accountsHierarchy,
  fundGroupsToShow,
}) => {
  // console.log("accountsHierarchy", headerText, accountsHierarchy);
  const accountHierarchy = accountsHierarchy.types.find(
    (type) => type.type.toLowerCase() === headerText.toLowerCase(),
  );

  // console.log("accountHierarchy", accountHierarchy);

  const headerRow = (
    <tr>
      <td colSpan={2}>
        <LargeLineText>
          {headerText === "Equity" ? "Equity Accounts" : headerText}
        </LargeLineText>
      </td>
    </tr>
  );

  const reportLines = accountHierarchy
    ? accountHierarchy.groups.map((group) => {
        return (
          <GroupAccounts
            group={group}
            reportsData={reportsData}
            headerText={headerText}
            accounts={accounts}
            showFunds={showFunds}
            setTxModalOpen={setTxModalOpen}
            setTxToDrill={setTxToDrill}
            moreThanOneGroup={
              accountHierarchy?.groups?.length > 1 ? true : false
            }
            fundGroupsToShow={fundGroupsToShow}
          />
        );
      })
    : [];

  return [headerRow, ...reportLines];
};

export default AccountRows;
