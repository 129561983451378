import { type } from "jquery";
import {
  deepClone,
  findAccountById,
  findFundById,
  instantLog,
} from "./general_util";

// Function to extract accounts and funds
function extractAccountsAndFunds(filteredTransactions) {
  let accountsFromTx = [];
  let fundsFromTx = [];

  filteredTransactions.forEach((entry) => {
    entry.lines.forEach((line) => {
      if (!accountsFromTx.includes(line.account)) {
        accountsFromTx.push(line.account);
      }
      if (!fundsFromTx.includes(line.fund)) {
        fundsFromTx.push(line.fund);
      }
    });
  });

  return { accountsFromTx, fundsFromTx };
}

// Function to calculate account total based on filteredTransactions, account, and fund
const calculateAccountTotal = ({
  filteredTransactions,
  account,
  fund,
  isCreditPositive,
}) => {
  // If there are no transactions, directly return 0
  if (!filteredTransactions || filteredTransactions.length === 0) {
    return 0;
  }

  // Use reduce to calculate the total
  const total = filteredTransactions.reduce((acc, entry) => {
    // Sum the amounts of all matching lines in the transaction
    const sum = entry.lines.reduce((lineAcc, line) => {
      if (line.account === account && line.fund === fund) {
        const amount = parseInt(line.amount, 10); // Explicitly specify base 10
        if (line.sign === "credit") {
          return lineAcc + (isCreditPositive ? amount : -amount);
        } else {
          return lineAcc + (isCreditPositive ? -amount : amount);
        }
      }
      return lineAcc;
    }, 0);

    return acc + sum;
  }, 0);

  return total;
};

const collectRelevantLines = ({ filteredTransactions, account, fund }) => {
  // Check if a fund argument is provided
  const filterByFund = fund !== undefined;

  // Filter transactions that are relevant to the account and optionally the fund
  const relevantTransactions = filteredTransactions.filter((entry) =>
    entry.lines.some(
      (line) =>
        line.account === account && (!filterByFund || line.fund === fund),
    ),
  );

  // Filter and map the relevant lines
  return relevantTransactions.flatMap((tx) =>
    tx.lines
      .filter(
        (line) =>
          line.account === account && (!filterByFund || line.fund === fund),
      )
      .map((line, index) => {
        return {
          amount: line.amount,
          date: tx.date,
          contact: line.contact,
          id: tx.id,
          line: index,
          sign: line.sign,
          fund: line.fund,
        };
      }),
  );
};

// Function to calculate total moves
const calculateTotalMoves = (accountData, type) => {
  return accountData.reduce((acc, account) => {
    if (account.accountType === type) {
      return acc + parseInt(account.intTotal);
    }
    return acc;
  }, 0);
};

export const calcBalanceSheet = (filteredTransactions, accounts) => {
  const { accountsFromTx, fundsFromTx } =
    extractAccountsAndFunds(filteredTransactions);
  let fundResultsObj = {};
  let fundTransactionObj = {};

  fundsFromTx.forEach((fund) => {
    let accountData = [];
    let accountResultsObj = {};
    fundTransactionObj[fund] = new Map();

    accountsFromTx.forEach((account) => {
      const accountDetails = accounts.find(
        (accountObj) => accountObj.id === account,
      );

      //Credits increase liabilities and decrease assets
      const isCreditPositive =
        accountDetails.accountType.toLowerCase() === "liabilities";

      const accountTotal = calculateAccountTotal({
        filteredTransactions,
        account,
        fund,
        isCreditPositive,
      });

      // console.log(
      //   "Account total:",
      //   accountTotal,
      //   accountDetails.accountNumber,
      //   accountDetails.accountType.toLowerCase(),
      // );

      const displayTotal = accountTotal / 100;

      accountData.push({
        accountNumber: accountDetails.accountNumber,
        total: displayTotal,
        intTotal: accountTotal,
        accountType: accountDetails.accountType.toLowerCase(),
        accountName: accountDetails.accountName,
        id: account,
      });
      // console.log("Account data:", accountData);

      const relevantLines = collectRelevantLines({
        filteredTransactions,
        account,
        fund,
      });
      fundTransactionObj[fund].set(account, relevantLines);
    });
    const totalAssetMoves = calculateTotalMoves(accountData, "assets");
    const totalLiabilityMoves = calculateTotalMoves(accountData, "liabilities");

    // console.log("Total asset moves:", totalAssetMoves);
    // console.log("Total liability moves:", totalLiabilityMoves);

    //   accountResultsObj = {
    //     assets: accountData.filter((account) => account.accountType === "assets"),
    //     liabilities: accountData.filter(
    //       (account) => account.accountType === "liabilities",
    //     ),
    //     total: ((totalAssetMoves - totalLiabilityMoves) / 100).toFixed(2),
    //     assetsTotal: (totalAssetMoves / 100).toFixed(2),
    //     liabilitiesTotal: (totalLiabilityMoves / 100).toFixed(2),
    //   };
    //   fundResultsObj[fund] = accountResultsObj;
    // });
    accountResultsObj = {
      assets: accountData.filter((account) => account.accountType === "assets"),
      liabilities: accountData.filter(
        (account) => account.accountType === "liabilities",
      ),
      total: totalAssetMoves - totalLiabilityMoves, // for display
      assetsTotal: totalAssetMoves, // integer total
      liabilitiesTotal: totalLiabilityMoves, // integer total
    };
    // console.log("Account results object:", accountResultsObj);
    fundResultsObj[fund] = accountResultsObj;
  });

  // Calculate grand totals
  const totalAssets = Object.values(fundResultsObj).reduce(
    (acc, fund) => acc + fund.assetsTotal,
    0,
  );
  const totalLiabilities = Object.values(fundResultsObj).reduce(
    (acc, fund) => acc + fund.liabilitiesTotal,
    0,
  );
  const totalSummed = totalAssets - totalLiabilities;

  fundResultsObj["total"] = {
    assetsTotal: totalAssets, // for display
    liabilitiesTotal: totalLiabilities, // for display
    total: totalSummed, // for display
  };

  console.log(fundResultsObj);

  //TODO: Calculate subtotals and grand totals using the accountsHierarchy and fundsHierarchy
  // The resulting object should be structured to allow for easy rendering in the BalanceSheet component

  return { fundResultsObj, fundTransactionObj };
};

const sumTransactionsByFundHierarchy = ({
  filteredTransactions,
  fundsHierarchy,
  funds,
  accountId,
  isCreditPositive,
  accountName,
}) => {
  let totalForAccount = 0;
  let relevantLinesForAccount = [];
  const fundGroupsArr = [];
  // instantLog({ obj: fundGroupsArr, msg: "fundGroupsArr, line 210" });
  fundsHierarchy.groups.forEach((fundGroupObj) => {
    let fundObjects = [];
    const fundGroupNameString = fundGroupObj.groupName;
    let totalForGroup = 0;
    let relevantLinesForGroup = [];
    fundGroupObj.funds.forEach((fund) => {
      const fundInfo = findFundById({
        funds,
        fundId: fund.id,
      });
      const fundName = fundInfo.fundName;
      const fundNumber = fundInfo.fundNumber;
      // Find totals for fund and sum into the group
      const relevantLines = collectRelevantLines({
        filteredTransactions,
        account: accountId,
        fund,
      });
      let relevantParentLines = relevantLines;
      const fundTotal = calculateAccountTotal({
        filteredTransactions,
        account: accountId,
        fund: fund.id,
        isCreditPositive,
      });

      let fundTotalWithSubFunds = fundTotal;
      const subFunds = fund.subFunds;
      let subFundsColumns = [];
      if (subFunds?.length > 0) {
        subFunds.forEach((subFundId) => {
          const subFundInfo = findFundById({
            funds,
            fundId: subFundId,
          });
          const subFundName = subFundInfo.fundName;
          const subFundNumber = fundInfo.fundNumber;
          // Find totals for subFunds and sum into the parent
          const relevantSubFundLines = collectRelevantLines({
            filteredTransactions,
            account: accountId,
            fund: subFundId,
          });
          relevantParentLines =
            relevantParentLines.concat(relevantSubFundLines);
          const subFundTotal = calculateAccountTotal({
            filteredTransactions,
            account: accountId,
            fund: subFundId,
            isCreditPositive,
          });
          fundTotalWithSubFunds += subFundTotal;
          // Add subFund columns
          subFundsColumns.push({
            fundName: subFundName,
            fundNumber: subFundNumber,
            total: subFundTotal,
            relevantLines: relevantSubFundLines,
            type: "subFund",
          });
        });
        // Add parent fund column (without the totaled subs) with subFund columns
        fundObjects.push({
          fundName,
          fundNumber,
          total: fundTotal,
          withSubFundsTotal: fundTotalWithSubFunds,
          relevantLines,
          relevantParentLines,
          subFunds: subFundsColumns,
          type: "parentFund",
        });
        totalForGroup += fundTotalWithSubFunds;
        relevantLinesForGroup =
          relevantLinesForGroup.concat(relevantParentLines);
        let checkThis = "Asset 1";
        if (accountName === checkThis) {
          console.log(
            `${checkThis} totalForGroup after subfunds`,
            totalForGroup,
          );
        }
      } else {
        // No subFunds, add the fund column
        fundObjects.push({
          fundName,
          fundNumber,
          total: fundTotal,
          relevantLines,
          type: "fund",
        });
        totalForGroup += fundTotal;
        let checkThis = "Asset 1";
        if (accountName === checkThis) {
          console.log(
            `${checkThis} totalForGroup with no subfunds`,
            totalForGroup,
          );
        }
        relevantLinesForGroup = relevantLinesForGroup.concat(relevantLines);
      }
    });
    // Add group column with the total of the group
    fundGroupsArr.push({
      fundGroupName: fundGroupNameString,
      total: totalForGroup,
      relevantLines: relevantLinesForGroup,
      type: "fundGroup",
      funds: fundObjects,
    });

    totalForAccount += totalForGroup;
    relevantLinesForAccount = relevantLinesForAccount.concat(
      relevantLinesForGroup,
    );
  });
  // Add account column with the total of the account
  fundGroupsArr.push({
    total: totalForAccount,
    relevantLines: relevantLinesForAccount,
    type: "allFundsTotal",
  });
  const fullFundsArrToReturn = deepClone(fundGroupsArr);
  return fullFundsArrToReturn || [];
};

//Helper function to add fund columns together for totals at each level, including subFunds, and return the summed columns while also combining the relevantLines
const combineFundColumns = ({
  fundColumnsObject1,
  fundColumnsObject2,
  subtract = false,
}) => {
  if (fundColumnsObject1.length !== fundColumnsObject2.length) {
    console.error(
      "Error: fundColumnsObject1 and fundColumnsObject2 must be the same length",
    );
    return;
  }

  let summedColumns = [];
  fundColumnsObject1.forEach((fundGroup, groupIndex) => {
    let summedFundGroup = deepClone(fundGroup);
    const fundGroup2 = fundColumnsObject2[groupIndex];

    // Determine the operation (addition or subtraction) based on the 'subtract' flag
    const operation = subtract ? (a, b) => a - b : (a, b) => a + b;

    if (summedFundGroup?.funds?.length > 0) {
      // Apply the operation to the fund group totals
      summedFundGroup.total = operation(
        summedFundGroup.total,
        fundGroup2.total,
      );
      summedFundGroup.funds.forEach((fund, fundIndex) => {
        const fund2 = fundGroup2.funds[fundIndex];
        fund.total = operation(fund.total, fund2.total);
        if (fund.type === "parentFund" && fund2.subFunds?.length > 0) {
          fund.subFunds.forEach((subFund, index) => {
            const subFund2 = fund2.subFunds[index];
            subFund.total = operation(subFund.total, subFund2.total);
          });
          fund.withSubFundsTotal = operation(
            fund.withSubFundsTotal,
            fund2.withSubFundsTotal,
          );
        }
      });
    } else {
      // Apply the operation to total rows
      summedFundGroup.total = operation(
        summedFundGroup.total,
        fundGroup2.total,
      );
    }

    summedColumns.push(summedFundGroup);
  });

  return summedColumns;
};

export const calcBalanceSheetV2 = ({
  filteredTransactions,
  accountsHierarchy,
  fundsHierarchy,
  accounts,
  funds,
}) => {
  // Move through the accountsHierarchy to the individual account level, then move through the fundsHierarchy to the individual fund level within each account.
  // This will allow the table to map through the result here in the row direction, then map through the columns within each row.
  const balanceSheetAccounts = [];
  accountsHierarchy.types
    .filter(
      (typeObj) =>
        typeObj.type.toLowerCase() === "assets" ||
        typeObj.type.toLowerCase() === "liabilities",
    )
    .forEach((accountTypeObj) => {
      const accountTypeNameString = accountTypeObj.type;
      let totalForAccountType = [];
      const typeAccounts = [];
      accountTypeObj.groups.forEach((accountGroupObj, groupIndex) => {
        const accountGroupNameString = accountGroupObj.groupName;
        let totalForAccountGroup = [];
        const groupAccounts = [];
        accountGroupObj.accounts.forEach((account, accountIndex) => {
          const accountInfo = findAccountById({
            accounts,
            accountId: account.id,
          });
          const accountName = accountInfo.accountName;
          const accountNumber = accountInfo.accountNumber;
          const isCreditPositive =
            accountInfo.accountType.toLowerCase() === "liabilities" ||
            accountInfo.accountType.toLowerCase() === "income";
          //Find totals for account by fund and total for the whole account
          let accountsFundColumns = sumTransactionsByFundHierarchy({
            filteredTransactions,
            fundsHierarchy,
            funds,
            accountId: account.id,
            isCreditPositive,
            accountName,
          });
          let accountTotalWithSubAccounts = deepClone(accountsFundColumns);
          const subAccounts = account.subAccounts;
          const subAccountsRows = [];
          if (subAccounts?.length > 0) {
            subAccounts.forEach((subAccountId) => {
              const subAccountInfo = findAccountById({
                accounts,
                accountId: subAccountId,
              });
              const subAccountName = subAccountInfo.accountName;
              const subAccountNumber = subAccountInfo.accountNumber;
              //Find totals for subAccounts by fund and total to sum up into the parent
              let subAccountFundColumns = sumTransactionsByFundHierarchy({
                filteredTransactions,
                fundsHierarchy,
                funds,
                accountId: subAccountId,
                isCreditPositive,
                accountName: subAccountName,
              });
              accountTotalWithSubAccounts = combineFundColumns({
                fundColumnsObject1: accountTotalWithSubAccounts,
                fundColumnsObject2: subAccountFundColumns,
              });
              subAccountsRows.push({
                accountName: subAccountName,
                accountNumber: subAccountNumber,
                columns: [...subAccountFundColumns],
                type: "subAccount",
              });
            });
            groupAccounts.push({
              accountName,
              accountNumber,
              columns: accountsFundColumns,
              subAccounts: subAccountsRows,
              type: "parentAccount",
              totalRowColumns: accountTotalWithSubAccounts,
            });
          } else {
            groupAccounts.push({
              accountName,
              accountNumber,
              columns: accountsFundColumns,
              type: "account",
            });
          }
          if (accountIndex === 0) {
            totalForAccountGroup = deepClone(accountTotalWithSubAccounts);
          } else {
            totalForAccountGroup = deepClone(
              combineFundColumns({
                fundColumnsObject1: totalForAccountGroup,
                fundColumnsObject2: accountTotalWithSubAccounts,
              }),
            );
          }
        });
        typeAccounts.push({
          accountGroupName: accountGroupNameString,
          columns: totalForAccountGroup,
          accounts: groupAccounts,
          type: "accountGroup",
        });
        if (groupIndex === 0) {
          totalForAccountType = deepClone(totalForAccountGroup);
        } else {
          totalForAccountType = deepClone(
            combineFundColumns({
              fundColumnsObject1: totalForAccountType,
              fundColumnsObject2: totalForAccountGroup,
            }),
          );
        }
      });
      balanceSheetAccounts.push({
        accountTypeName: accountTypeNameString,
        columns: totalForAccountType,
        groups: typeAccounts,
        type: "accountType",
      });
    });
  // for the fund columns, subtract the liabilities from the assets to get the total equity
  const totalAssets = balanceSheetAccounts.find(
    (accountType) => accountType.accountTypeName.toLowerCase() === "assets",
  );
  const totalLiabilities = balanceSheetAccounts.find(
    (accountType) =>
      accountType.accountTypeName.toLowerCase() === "liabilities",
  );

  console.log(
    "Total assets:",
    totalAssets,
    "Total liabilities:",
    totalLiabilities,
  );
  if (
    totalAssets?.columns?.length > 0 &&
    totalLiabilities?.columns?.length > 0
  ) {
    const totalEquity = deepClone(totalAssets);
    totalEquity.columns = combineFundColumns({
      fundColumnsObject1: totalAssets.columns,
      fundColumnsObject2: totalLiabilities.columns,
      subtract: true,
    });
    balanceSheetAccounts.push({
      accountTypeName: "Equity",
      columns: totalEquity.columns,
      type: "accountType",
    });
  }
  return balanceSheetAccounts;
};

export const calcIncomeStatement = (filteredTransactions, accounts) => {
  const { accountsFromTx, fundsFromTx } =
    extractAccountsAndFunds(filteredTransactions);
  let fundResultsObj = {};
  let fundTransactionObj = {};

  fundsFromTx.forEach((fund) => {
    let accountData = [];
    let accountResultsObj = {};
    fundTransactionObj[fund] = new Map();

    accountsFromTx.forEach((account) => {
      const accountDetails = accounts.find(
        (accountObj) => accountObj.id === account,
      );

      //Credits increase income and decrease expenses
      const isCreditPositive =
        accountDetails.accountType.toLowerCase() === "income";

      const accountTotal = calculateAccountTotal({
        filteredTransactions,
        account,
        fund,
        isCreditPositive,
      });

      const accountTotalDisplay = (accountTotal / 100).toFixed(2);

      accountData.push({
        accountNumber: accountDetails.accountNumber,
        total: accountTotalDisplay,
        intTotal: accountTotal,
        accountType: accountDetails.accountType.toLowerCase(),
        accountName: accountDetails.accountName,
        id: account,
      });

      const relevantLines = collectRelevantLines({
        filteredTransactions,
        account,
        fund,
      });
      fundTransactionObj[fund].set(account, relevantLines);
    });

    const totalIncomeMoves = calculateTotalMoves(accountData, "income");
    const totalExpenseMoves = calculateTotalMoves(accountData, "expenses");
    const displayTotal = ((totalIncomeMoves - totalExpenseMoves) / 100).toFixed(
      2,
    );
    const displayIncomeMovesTotal = totalIncomeMoves.toFixed(2);
    const displayExpenseMovesTotal = totalExpenseMoves.toFixed(2);

    accountResultsObj = {
      income: accountData.filter((account) => account.accountType === "income"),
      expenses: accountData.filter(
        (account) => account.accountType === "expenses",
      ),
      total: displayTotal,
      incomeTotal: displayIncomeMovesTotal,
      expensesTotal: displayExpenseMovesTotal,
      incomeTotalNumber: totalIncomeMoves,
      expensesTotalNumber: totalExpenseMoves,
    };
    fundResultsObj[fund] = accountResultsObj;
  });
  const totIncome = Object.values(fundResultsObj).reduce((acc, fund) => {
    return acc + fund.incomeTotalNumber;
  }, 0);
  const totExpenses = Object.values(fundResultsObj).reduce((acc, fund) => {
    return acc + fund.expensesTotalNumber;
  }, 0);
  const totSummed = totIncome - totExpenses;
  fundResultsObj["total"] = {
    incomeTotal: (totIncome / 100).toFixed(2),
    expensesTotal: (totExpenses / 100).toFixed(2),
    total: (totSummed / 100).toFixed(2),
  };
  return { fundResultsObj, fundTransactionObj };
};

// Function to generate a general ledger
export const calcGeneralLedger = ({
  transactions,
  accounts,
  funds,
  startDate,
  endDate,
}) => {
  // Object to hold general ledger data
  let generalLedger = {};

  // Iterate through each fund and account to populate the general ledger

  accounts.forEach((account) => {
    // Flag to determine whether credits are positive or negative for this account
    const isCreditPositive = (accountType) => {
      const lowerType = accountType.toLowerCase();
      return (
        lowerType === "income" ||
        lowerType === "liabilities" ||
        lowerType === "equity"
      );
    };

    let startingBalance = 0;
    let totalCredits = 0;
    let totalDebits = 0;

    if (
      account.accountType.toLowerCase() === "assets" ||
      account.accountType.toLowerCase() === "liabilities"
    ) {
      // Find the starting balance by summing all transactions before the start date
      startingBalance = transactions
        .filter((entry) => entry.date < startDate)
        .reduce((acc, entry) => {
          const lines = entry.lines.filter((l) => l.account === account.id);
          if (lines.length === 0) return acc;

          const amountForTx = lines.reduce((acc, line) => {
            const amount = parseInt(line.amount);
            return line.sign === "credit"
              ? acc + (isCreditPositive(account.accountType) ? amount : -amount)
              : acc +
                  (isCreditPositive(account.accountType) ? -amount : amount);
          }, 0);
          return acc + amountForTx;
        }, 0);
    }

    // Initialize data structure for this account under this fund
    generalLedger[account.id] = {
      transactions: [],
      startingBalance: startingBalance,
      totalCredits,
      totalDebits,
    };

    let runningTotal = startingBalance;

    // Gather transactions relevant to this account and fund
    const relevantLines = collectRelevantLines({
      filteredTransactions: transactions,
      account: account.id,
    });

    // Filter transactions by date
    const dateFilteredRelevantLines = relevantLines
      .filter((line) => line.date >= startDate && line.date <= endDate)
      .sort((a, b) => a.date - b.date);

    // Process each line to generate ledger entries
    dateFilteredRelevantLines.forEach((line) => {
      const amount = parseInt(line.amount);
      const displayAmount = amount.toFixed(2);

      // Update totalCredits and totalDebits
      if (line.sign === "credit") {
        totalCredits += amount;
      } else {
        totalDebits += amount;
      }

      // Calculate the effect on runningTotal
      const effect =
        line.sign === "credit"
          ? isCreditPositive(account.accountType)
            ? amount
            : -amount
          : isCreditPositive(account.accountType)
          ? -amount
          : amount;

      runningTotal += effect;
      const displayTotal = runningTotal.toFixed(2);

      generalLedger[account.id].transactions.push({
        ...line,
        amount: displayAmount,
        runningTotal: displayTotal,
      });

      const totalCreditsDisplay = totalCredits.toFixed(2);
      const totalDebitsDisplay = totalDebits.toFixed(2);
      const totalAmountDisplay = (totalCredits - totalDebits).toFixed(2);

      // Update the totals in the generalLedger
      generalLedger[account.id].totalCredits = totalCreditsDisplay;
      generalLedger[account.id].totalDebits = totalDebitsDisplay;
      generalLedger[account.id].totalAmount = totalAmountDisplay;
    });

    // Update the endingBalance for the account
    generalLedger[account.id].endingBalance = runningTotal.toFixed(2);
  });

  return generalLedger;
};
