import React from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { TextField } from "formik-mui";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { MenuItem } from "@mui/material";
import StyledButton from "../../assets/buttons";
import { TitleText } from "./onboarding_styles";
import {
  updateDoc,
  doc,
  collection,
  addDoc,
  writeBatch,
} from "firebase/firestore";
import { CenteringDiv } from "../../assets/containers";
import { RowWrapper } from "../../assets/containers";
import Grid from "@mui/material/Grid";
import {
  defaultAccounts,
  defaultAccountsHierarchy,
  defaultFunds,
  defaultFundsHierarchy,
} from "./defaultsForExpert";

export const AddOrg = ({ db, userData, user, closeModal }) => {
  const theme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Formik
        initialValues={{
          orgName: "",
          orgType: "",
          taxId: "",
          financialYearStart: "",
          streetaAddress: "",
          city: "",
          state: "",
          zip: "",
          phone: "",
          website: "",
        }}
        validationSchema={Yup.object({
          orgName: Yup.string()
            .max(30, "Must be 30 characters or less")
            .required("Your organization needs a name"),
          orgType: Yup.string().required("Required for proper setup"),
          taxId: Yup.string().matches(
            /^\d{9}$/,
            "Tax ID must be exactly 9 digits",
          ),
          financialYearStart: Yup.string().required(
            "Enter the month your FY starts",
          ),
          Phone: Yup.string().matches(
            /^\d{10}$/,
            "Phone number must be exactly 10 digits",
          ),
          website: Yup.string().url("Must be a valid URL"),
          streetAddress: Yup.string().max(35, "Must be 35 characters or less"),
          city: Yup.string().max(25, "Must be 25 characters or less"),
          state: Yup.string()
            .min(2, "Must 2 letters")
            .max(2, "Must be 2 letters"),
          zip: Yup.string().matches(
            /^\d{5}$/,
            "Zip code must be exactly 5 digits",
          ),
        })}
        onSubmit={(values, { setSubmitting }) => {
          const setDefaultSetup = async (db, orgID, retryCount = 3) => {
            let attempts = 0;
            let success = false;

            while (attempts < retryCount && !success) {
              try {
                const batch = writeBatch(db);

                const orgRef = doc(db, "orgs", orgID);
                const accountsHierarchyRef = doc(
                  db,
                  "orgs",
                  orgID,
                  "organizationAndSettings",
                  "accountsHierarchy",
                );
                const fundsHierarchyRef = doc(
                  db,
                  "orgs",
                  orgID,
                  "organizationAndSettings",
                  "fundsHierarchy",
                );

                batch.set(accountsHierarchyRef, defaultAccountsHierarchy);
                batch.set(fundsHierarchyRef, defaultFundsHierarchy);

                defaultAccounts.forEach((account) => {
                  const accountRef = doc(
                    db,
                    "orgs",
                    orgID,
                    "accounts",
                    account.id,
                  );
                  batch.set(accountRef, account);
                });

                defaultFunds.forEach((fund) => {
                  const fundRef = doc(db, "orgs", orgID, "funds", fund.id);
                  batch.set(fundRef, fund);
                });

                batch.update(orgRef, { onboarded: true }, { merge: true });

                await batch.commit();
                success = true; // If commit is successful, set success to true
              } catch (error) {
                attempts++;
                console.error(`Attempt ${attempts} failed:`, error);
                if (attempts >= retryCount) {
                  throw new Error(`Failed after ${attempts} attempts`);
                }
              }
            }
          };
          const addOrg = async (values) => {
            const today = new Date();
            const newOrgData = {
              ...values,
              users: {
                [user.uid]: {
                  roles: ["admin", "subscriber"],
                  username: userData.username,
                  email: userData.email,
                },
              },
              onboarded: false,
              creationDate: today,
            };
            const orgs = userData.orgs ? userData.orgs : [];
            console.log("Adding org: ", newOrgData);
            const orgsRef = collection(db, "orgs");
            const docRef = await addDoc(orgsRef, newOrgData);
            const orgID = docRef.id;
            console.log("Added org: ", orgID);
            orgs.push({
              ...values,
              orgID: orgID,
            });
            console.log("Orgs: ", orgs);
            await setDefaultSetup(db, orgID);
            await updateDoc(
              doc(db, "users", user.uid),
              {
                orgs: orgs,
                lastOrgUsed: orgID,
              },
              { merge: true },
            );
            if (closeModal) closeModal();
          };
          addOrg(values);

          setSubmitting(false);
        }}>
        <Form>
          <CenteringDiv>
            <TitleText>Create a New Organization</TitleText>
            <div style={{ height: "17px" }} />
            <Grid
              container
              style={{
                margin: 0,
                paddingLeft: 30,
                paddingRight: 30,
                width: "100%",
              }}
              spacing={2}>
              <Grid item xs={7}>
                <Field
                  component={TextField}
                  color="secondary"
                  name="orgName"
                  id="orgName"
                  type="text"
                  label="Org Name"
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={5}>
                <Field
                  component={TextField}
                  select
                  color="secondary"
                  id="orgType"
                  name="orgType"
                  required
                  fullWidth
                  label="Org Type">
                  <MenuItem value={"church"}>Church</MenuItem>
                  <MenuItem value={"generalNP"}>General Nonprofit</MenuItem>
                </Field>
              </Grid>
              <Grid item xs={7}>
                <Field
                  component={TextField}
                  color="secondary"
                  name="taxId"
                  id="taxId"
                  type="text"
                  label="Tax ID"
                  fullWidth
                />
              </Grid>
              <Grid item xs={5}>
                <Field
                  component={TextField}
                  select
                  color="secondary"
                  name="financialYearStart"
                  id="financialYearStart"
                  type="text"
                  label="Financial Year Start"
                  required
                  fullWidth>
                  <MenuItem value={"jan"}>January</MenuItem>
                  <MenuItem value={"feb"}>February</MenuItem>
                  <MenuItem value={"mar"}>March</MenuItem>
                  <MenuItem value={"apr"}>April</MenuItem>
                  <MenuItem value={"may"}>May</MenuItem>
                  <MenuItem value={"jun"}>June</MenuItem>
                  <MenuItem value={"jul"}>July</MenuItem>
                  <MenuItem value={"aug"}>August</MenuItem>
                  <MenuItem value={"sep"}>September</MenuItem>
                  <MenuItem value={"oct"}>October</MenuItem>
                  <MenuItem value={"nov"}>November</MenuItem>
                  <MenuItem value={"dec"}>December</MenuItem>
                </Field>
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={TextField}
                  color="secondary"
                  name="streetAddress"
                  id="streetAddress"
                  type="text"
                  label="Street Address"
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  component={TextField}
                  color="secondary"
                  name="city"
                  id="city"
                  type="text"
                  label="City"
                  fullWidth
                />
              </Grid>
              <Grid item xs={2}>
                <Field
                  component={TextField}
                  color="secondary"
                  name="state"
                  id="state"
                  type="text"
                  label="State"
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  component={TextField}
                  color="secondary"
                  name="zip"
                  id="zip"
                  type="text"
                  label="Zip"
                  fullWidth
                />
              </Grid>
              <Grid item xs={5}>
                <Field
                  component={TextField}
                  color="secondary"
                  name="phone"
                  id="phone"
                  type="text"
                  label="Phone Number"
                  fullWidth
                />
              </Grid>
              <Grid item xs={7}>
                <Field
                  component={TextField}
                  color="secondary"
                  name="website"
                  id="website"
                  type="text"
                  label="Business Website"
                  fullWidth
                />
              </Grid>
            </Grid>
            <div style={{ height: "25px" }} />
            <StyledButton
              // oneClick={true}
              bright
              width="165px"
              height="36px"
              fontSize="1.2rem"
              type="submit">
              Create Org
            </StyledButton>
            <p style={{ color: "white", fontFamily: "MontserratMed" }}>
              Or ask the admin of an existing organization to add you.
            </p>
          </CenteringDiv>
        </Form>
      </Formik>
    </ThemeProvider>
  );
};
