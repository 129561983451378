import React, { useEffect, useState, useContext } from "react";
import { doc, updateDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../services/use-auth.js";
import {
  Checkbox,
  FormControlLabel,
  Modal,
  TextField,
  Tooltip,
} from "@mui/material";
import {
  RowWrapper,
  LineText,
  StyledTableRow,
  StyledTableHeaderColumn,
  StyledHeaderText,
  StyledTableItem,
  ModalInner,
  StyledTitleText,
  MobileWarningWrapper,
  WarningText,
  StyledTbody,
  ModalBoxDark,
  StyledTableHeaderRow,
  TableWrapperDiv,
  StyledTable,
  TableWrapperInnerDiv,
  StyledSearchBox,
  LabelText,
  TopOptionsWrapper,
  DateRangeWrapper,
} from "./transactions_styles.jsx";

import {
  convertToDate,
  returnCurrency,
} from "../../utilities/general_util.jsx";
import UserContext from "../../assets/user_context.jsx";
import { CircleOutlined, TaskAltOutlined } from "@mui/icons-material";
import SortIcon from "@mui/icons-material/Sort";
import { ConfirmAction } from "../../assets/controls.jsx";
import { primary_dark_color } from "../../constants/color_constants.jsx";
import EnterTransaction from "./enter_transaction/enter_transaction.jsx";
import { ModalBoxLight } from "./transactions_styles.jsx";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { sortTransactions } from "./sort_functions.js";
import InspectTransaction from "../banking/banking/inspect_transaction.jsx";
import dayjs from "dayjs";
dayjs.extend(require("dayjs/plugin/isSameOrBefore"));
dayjs.extend(require("dayjs/plugin/isSameOrAfter"));

const Transactions = ({
  height,
  filterAccount,
  clearedTx,
  clickedClear,
  txNotToShow,
  setJournalEntryData,
}) => {
  const {
    user,
    org,
    showLoading,
    setIsChildDataLoaded,
    accounts,
    funds,
    contacts,
    transactions,
    dateRangeForTx,
    setDateRangeForTx,
    orgRoles,
    setDeleteQueue,
    deleteQueue,
    userData,
  } = useContext(UserContext);

  //Redirect to signin if props.authUser is null
  const redirect = useNavigate();
  useEffect(() => {
    if (!showLoading) {
      if (user === null) {
        console.log("Should redirect to SIGN_IN");
        redirect("/signin");
      }
    }
  }, [user, showLoading, redirect]);

  const [confirmRemoveOpen, setConfirmRemoveOpen] = useState(false);
  const [transactionIdToRemove, setTransactionIdToRemove] = useState(null);

  const [filteredTransactionsData, setFilteredTransactionsData] = useState([]);
  const [searchString, setSearchString] = useState("");

  const [journalEntryToEdit, setJournalEntryToEdit] = useState({});
  const [journalEntryEditModal, setJournalEntryEditModal] = useState(false);
  const [onlyJournalEntries, setOnlyJournalEntries] = useState(false);
  const [transactionToInspect, setTransactionToInspect] = useState(null);

  const [sortBy, setsortBy] = useState("date");

  //This following code is to truncate memo for the number of lines available and screen size

  const getCharacterLimit = (linesCount) => {
    // Adjust based on the number of lines
    const charPerLine =
      window.innerWidth >= 1400
        ? window.innerWidth / 72
        : window.innerWidth / 95;
    console.log("Char per line: ", charPerLine);
    const totalLimit = charPerLine * linesCount;

    return totalLimit;
  };

  // Utility function to truncate text
  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength - 3) + "...";
    }
    return text;
  };

  const authHook = useAuth();

  useEffect(() => {
    if (transactions.length > 0) {
      setIsChildDataLoaded(true);
    }
  }, [setIsChildDataLoaded, transactions]);

  const handleSearch = (event) => {
    setSearchString(event.target.value);
  };

  const isTransactionInDateRange = (transaction, dateRange) => {
    console.log("Transaction Date:", transaction?.date);

    if (!transaction?.date) {
      return false;
    }

    const parsedDate = convertToDate(transaction.date);
    console.log("Parsed Date:", parsedDate);
    console.log("Parsed Date Type:", typeof parsedDate);
    console.log("Is Parsed Date a Day.js Object:", parsedDate instanceof dayjs); // Check if it's a Day.js object

    // Ensure parsedDate is valid
    if (!parsedDate.isValid()) {
      console.log("Parsed Date is invalid");
      return false;
    }

    console.log("Day.js Version:", dayjs.version); // Log the Day.js version

    console.log("Date Range:", dateRange); // Log the date range object
    console.log(
      "Date Range Type - First Date:",
      typeof dateRange.firstDate,
      "Last Date:",
      typeof dateRange.lastDate,
    ); // Log types of dateRange's dates

    try {
      const beforeDate = parsedDate.isSameOrBefore(dateRange.lastDate, "day");
      const afterDate = parsedDate.isSameOrAfter(dateRange.firstDate, "day");

      console.log("Before Date Check:", beforeDate);
      console.log("After Date Check:", afterDate);

      return beforeDate && afterDate;
    } catch (error) {
      console.error("Error in date comparison:", error);
    }
  };

  const findNameByIdAndMatch = (array, id, searchString, fieldName) => {
    if (!array || !id) return false;

    const foundItem = array.find((item) => item.id === id);
    if (!foundItem || !foundItem[fieldName]) return false;

    return foundItem[fieldName]
      .toLowerCase()
      .includes(searchString.toLowerCase());
  };

  useEffect(() => {
    if (dateRangeForTx) {
      const filterTransactions = (transaction) => {
        if (!isTransactionInDateRange(transaction, dateRangeForTx))
          return false;

        const accountMatch = transaction.lines.some((line) =>
          findNameByIdAndMatch(
            accounts,
            line.account,
            searchString,
            "accountName",
          ),
        );

        const contactMatch = transaction.lines.some((line) =>
          findNameByIdAndMatch(
            contacts,
            line.contact,
            searchString,
            "shortName",
          ),
        );

        const showThisEntry = onlyJournalEntries
          ? transaction.journalEntry
          : true;

        return (
          (accountMatch || contactMatch || searchString === "") && showThisEntry
        );
      };

      let filteredTransactions = transactions.filter(filterTransactions);

      if (filterAccount) {
        //We are in a register, so we need to filter to only the account displayed
        console.log("Filtering Transactions for Account: ", filterAccount);
        filteredTransactions = filteredTransactions.filter((transaction) =>
          transaction.lines.some((line) => line.account === filterAccount),
        );
      }

      setFilteredTransactionsData(filteredTransactions);
    }
  }, [
    searchString,
    transactions,
    accounts,
    dateRangeForTx,
    filterAccount,
    contacts,
    onlyJournalEntries,
  ]);

  const transactionsHeader = [
    "Date",
    "Memo",
    "Account",
    "Fund",
    "Debit",
    "Credit",
    "Clear",
  ];

  const recordRemoval = async (data) => {
    setDeleteQueue((prevState) => [...prevState, data.id]);
    setTransactionToInspect(null);
    //Now check if the transactions is not in monothlyAggregates. If it isn't, just delete it from the state and the db. Otherwise, let the db deltion trigger the recalculation
    // const foundInMonthlyAggregates = monthlyAggregates.some((month) =>
    //   month.transactions.some((tx) => tx.id === data.id),
    // );
    // if (!foundInMonthlyAggregates) {
    //   //Delete from state
    //   setTransactions((prevState) =>
    //     prevState.filter((tx) => tx.id !== data.id),
    //   );
    // }
    //Delete from db
    const docRef = doc(authHook.db, "orgs", org, "journalEntries", data.id);
    await updateDoc(
      docRef,
      { toDelete: true, userMadeChange: user.uid },
      { merge: true },
    );
  };

  const handleCheckboxChange = (event) => {
    setOnlyJournalEntries(event.target.checked);
  };

  return (
    <div>
      <Modal
        open={transactionToInspect !== null}
        onClose={() => setTransactionToInspect(null)}>
        <ModalInner>
          <ModalBoxDark>
            <InspectTransaction
              transaction={transactionToInspect}
              contacts={contacts}
              accounts={accounts}
              funds={funds}
              filterAccount={false}
              setTransaction={setTransactionToInspect}
              setJournalEntryEditModal={setJournalEntryEditModal}
              setJournalEntryToEdit={setJournalEntryToEdit}
              setBankingTransactionEntryEditModal={setJournalEntryEditModal}
              setTransactionIdToRemove={setTransactionIdToRemove}
              setConfirmRemoveOpen={setConfirmRemoveOpen}
              orgRoles={orgRoles}
              userData={userData}
              setJournalEntryData={setJournalEntryData}
            />
          </ModalBoxDark>
        </ModalInner>
      </Modal>
      <Modal
        open={journalEntryEditModal}
        onClose={() => setJournalEntryEditModal(false)}>
        <ModalBoxLight style={{ width: "85vw" }}>
          <ModalInner>
            <EnterTransaction
              db={authHook.db}
              authUser={user}
              org={org}
              stillLoading={showLoading}
              accounts={accounts}
              funds={funds}
              entryToEdit={journalEntryToEdit}
              closeModal={() => setJournalEntryEditModal(false)}
            />
          </ModalInner>
        </ModalBoxLight>
      </Modal>

      {/* Modal for Adding many Transactions through import feature */}
      <ConfirmAction
        dataForAction={transactionIdToRemove}
        callback={(data) => recordRemoval(data)}
        openState={confirmRemoveOpen}
        handleCloseState={() => setConfirmRemoveOpen(false)}>
        Remove Transaction
      </ConfirmAction>

      <TopOptionsWrapper>
        <StyledSearchBox
          label="Search Transactions"
          name="search"
          variant="outlined"
          color="secondary"
          value={searchString}
          onChange={handleSearch}
        />
        {/* Checkbox with label for showing only journal entries */}
        <FormControlLabel
          control={
            <Checkbox
              checked={onlyJournalEntries}
              onChange={handleCheckboxChange}
              color="secondary"
              sx={{
                "& .MuiSvgIcon-root": { fontSize: 28 },
              }}
            />
          }
          label={
            <span style={{ fontFamily: "MontserratMed", whiteSpace: "nowrap" }}>
              <LabelText>Only Journal Entries</LabelText>
            </span>
          }
        />

        <DateRangeWrapper>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Transactions After"
              value={dateRangeForTx.firstDate}
              onChange={(newValue) => {
                setDateRangeForTx({
                  firstDate: newValue,
                  lastDate: dateRangeForTx.lastDate,
                });
              }}
              renderInput={(params) => (
                <TextField style={{ padding: "5px" }} {...params} />
              )}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Transactions Before"
              value={dateRangeForTx.lastDate}
              onChange={(newValue) => {
                setDateRangeForTx({
                  firstDate: dateRangeForTx.firstDate,
                  lastDate: newValue,
                });
              }}
              renderInput={(params) => (
                <TextField x={{ padding: "5px" }} {...params} />
              )}
            />
          </LocalizationProvider>
        </DateRangeWrapper>
      </TopOptionsWrapper>

      {filteredTransactionsData.length > 0 ? (
        <TableWrapperDiv height={height}>
          <TableWrapperInnerDiv>
            <StyledTable>
              <thead>
                <StyledTableHeaderRow header>
                  {filteredTransactionsData.length > 0 &&
                    transactionsHeader.map((header) => (
                      <StyledTableHeaderColumn
                        header={header}
                        key={`${header}-header`}
                        onClick={() => {
                          if (header === "Date") {
                            setsortBy(
                              sortBy === "date" ? "inverseDate" : "date",
                            );
                          }
                          if (header === "Debit") {
                            setsortBy(
                              sortBy === "debit" ? "inverseDebit" : "debit",
                            );
                          }
                          if (header === "Credit") {
                            setsortBy(
                              sortBy === "credit" ? "inverseCredit" : "credit",
                            );
                          }
                        }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}>
                          <StyledHeaderText
                            amount
                            key={`${header}-headerText`}
                            header={header}>
                            {header}
                          </StyledHeaderText>{" "}
                          {header === "Date" &&
                            (sortBy === "date" || sortBy === "inverseDate") && (
                              <SortIcon
                                style={
                                  sortBy === "date"
                                    ? {
                                        color: "white",
                                        cursor: "pointer",
                                      }
                                    : {
                                        color: "white",
                                        transform: "rotate(180deg)",
                                        cursor: "pointer",
                                      }
                                }
                                fontSize="large"
                              />
                            )}
                          {header === "Debit" &&
                            (sortBy === "debit" ||
                              sortBy === "inverseDebit") && (
                              <SortIcon
                                style={
                                  sortBy === "debit"
                                    ? {
                                        color: "white",
                                        cursor: "pointer",
                                      }
                                    : {
                                        color: "white",
                                        transform: "rotate(180deg)",
                                        cursor: "pointer",
                                      }
                                }
                                fontSize="large"
                              />
                            )}
                          {header === "Credit" &&
                            (sortBy === "credit" ||
                              sortBy === "inverseCredit") && (
                              <SortIcon
                                style={
                                  sortBy === "credit"
                                    ? {
                                        color: "white",
                                        cursor: "pointer",
                                      }
                                    : {
                                        color: "white",
                                        transform: "rotate(180deg)",
                                        cursor: "pointer",
                                      }
                                }
                                fontSize="large"
                              />
                            )}
                        </div>
                      </StyledTableHeaderColumn>
                    ))}
                </StyledTableHeaderRow>
              </thead>
              <StyledTbody height={height}>
                {filteredTransactionsData
                  .sort(
                    //Sort by date, in future, add sorting by other columns
                    (a, b) => sortTransactions(a, b, sortBy, filterAccount),
                  )
                  .filter((transaction) => {
                    if (!clearedTx) {
                      //The component is not being use in reconcilliation
                      return true;
                    } else {
                      //The component is being used in reconcilliation, don't show previously cleared tx
                      return !transaction.lines
                        .filter((line) => line.account === filterAccount)
                        .some((line) => line.cleared);
                    }
                  })
                  .map((transaction, transactionIndex) => {
                    const transactionLines = transaction.lines.filter(
                      (line) => {
                        return !filterAccount || line.account !== filterAccount;
                      },
                    );
                    return transactionLines.map((line, lineIndex) => {
                      const transactionForDeletion =
                        deleteQueue.length > 0 &&
                        deleteQueue.includes(transaction.id);
                      return (
                        <StyledTableRow
                          className="transactionLine"
                          key={`${transaction.id}-transID-${lineIndex}`}
                          index={transactionIndex}
                          isFirst={lineIndex === 0}
                          isLast={lineIndex === transactionLines.length - 1}
                          isSingle={transactionLines.length === 1}
                          optimistic={transaction.optimistic}
                          onClick={() => {
                            if (!transaction.optimistic) {
                              setTransactionToInspect(transaction);
                            }
                          }}>
                          {lineIndex === 0 && (
                            <StyledTableItem
                              attribute="Date"
                              isFirst={lineIndex === 0}
                              isLast={lineIndex === transactionLines.length - 1}
                              isSingle={transactionLines.length === 1}
                              rowSpan={transaction.lines.length}>
                              {lineIndex === 0 && (
                                <LineText
                                  style={
                                    transactionForDeletion
                                      ? {
                                          textDecoration: "line-through",
                                          color: "grey",
                                        }
                                      : transaction.optimistic
                                      ? { color: "grey" }
                                      : null
                                  }
                                  attribute="Date">
                                  {convertToDate(transaction.date).format(
                                    "MM/DD/YYYY",
                                  )}
                                </LineText>
                              )}
                            </StyledTableItem>
                          )}
                          {lineIndex === 0 && (
                            <StyledTableItem
                              attribute="Memo"
                              rowSpan={transaction.lines.length}>
                              <Tooltip title={transaction.memo}>
                                <LineText
                                  attribute="Memo"
                                  style={
                                    transactionForDeletion
                                      ? {
                                          textDecoration: "line-through",
                                          color: "grey",
                                        }
                                      : transaction.optimistic
                                      ? { color: "grey" }
                                      : null
                                  }>
                                  {truncateText(
                                    transaction.memo,
                                    getCharacterLimit(transaction.lines.length),
                                  )}
                                </LineText>
                              </Tooltip>
                            </StyledTableItem>
                          )}
                          <StyledTableItem attribute="Account">
                            <LineText
                              attribute="Account"
                              style={
                                transactionForDeletion
                                  ? {
                                      textDecoration: "line-through",
                                      color: "grey",
                                    }
                                  : transaction.optimistic
                                  ? { color: "grey" }
                                  : null
                              }>
                              {`${
                                accounts.find(
                                  (account) => account.id === line.account,
                                ).accountNumber
                              } - ${
                                accounts.find(
                                  (account) => account.id === line.account,
                                ).accountName
                              }`}
                            </LineText>
                          </StyledTableItem>
                          <StyledTableItem attribute="Fund">
                            <LineText
                              attribute="Fund"
                              style={
                                transactionForDeletion
                                  ? {
                                      textDecoration: "line-through",
                                      color: "grey",
                                    }
                                  : transaction.optimistic
                                  ? { color: "grey" }
                                  : null
                              }>
                              {`${
                                funds.find((fund) => fund.id === line.fund)
                                  .fundNumber
                              } - ${
                                funds.find((fund) => fund.id === line.fund)
                                  .fundName
                              }`}
                            </LineText>
                          </StyledTableItem>
                          {line.sign === "credit" ? (
                            <React.Fragment>
                              <StyledTableItem attribute={"Amount"} />
                              {/* Blank div if there is only a debit for this line */}

                              <StyledTableItem attribute={"Amount"}>
                                <LineText
                                  key={`${transaction.id}-${line.amount}-${lineIndex}-Amount`}
                                  style={
                                    transactionForDeletion
                                      ? {
                                          textDecoration: "line-through",
                                          color: "grey",
                                        }
                                      : transaction.optimistic
                                      ? { color: "grey" }
                                      : null
                                  }>
                                  {returnCurrency(line.amount)}
                                </LineText>
                              </StyledTableItem>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <StyledTableItem attribute={"Amount"}>
                                <LineText
                                  attribute={"Amount"}
                                  key={`${transaction.id}-${line.amount}-${lineIndex}-Amount`}
                                  style={
                                    transactionForDeletion
                                      ? {
                                          textDecoration: "line-through",
                                          color: "grey",
                                        }
                                      : transaction.optimistic
                                      ? { color: "grey" }
                                      : null
                                  }>
                                  {returnCurrency(line.amount)}
                                </LineText>
                              </StyledTableItem>
                              <StyledTableItem attribute={"Amount"} />
                              {/* Blank div if there is only a credit for this line */}
                            </React.Fragment>
                          )}
                          <StyledTableItem
                            attribute={"Clear"}
                            isFirst={lineIndex === 0}
                            isLast={lineIndex === transactionLines.length - 1}
                            isSingle={transactionLines.length === 1}>
                            {clearedTx ? (
                              //clearedTx is present only when in the reconciliation tool
                              clearedTx.includes(transaction.id) ? (
                                <TaskAltOutlined
                                  style={{
                                    color: primary_dark_color,
                                    cursor: "pointer",
                                  }}
                                  fontSize="large"
                                  onClick={() =>
                                    clickedClear({
                                      id: transaction.id,
                                      action: "unclear",
                                    })
                                  }
                                />
                              ) : (
                                <CircleOutlined
                                  onClick={() =>
                                    clickedClear({
                                      id: transaction.id,
                                      action: "clear",
                                    })
                                  }
                                  fontSize="large"
                                  style={{
                                    color: primary_dark_color,
                                    cursor: "pointer",
                                  }}
                                />
                              )
                            ) : line.cleared ? (
                              <TaskAltOutlined
                                style={{ color: primary_dark_color }}
                                fontSize="large"
                              />
                            ) : null}
                          </StyledTableItem>
                        </StyledTableRow>
                      );
                    });
                  })}
              </StyledTbody>
            </StyledTable>
          </TableWrapperInnerDiv>
        </TableWrapperDiv>
      ) : (
        <StyledTitleText>No Transactions Found in Date Range</StyledTitleText>
      )}
      <MobileWarningWrapper>
        <WarningText>
          Accounting work on mobile is really tough. We recomend you work on a
          larger screen to display these tables.
        </WarningText>
      </MobileWarningWrapper>
    </div>
  );
};

export default Transactions;
