import React, { useContext } from "react";
import { SubtitleText, StyledTextField } from "../component_styles.jsx";
import { collection, addDoc, setDoc, doc } from "firebase/firestore";
import { Autocomplete } from "formik-mui";
import { Formik, Field, Form, useFormikContext } from "formik";
// import { DatePicker } from "formik-mui-lab";
import * as Yup from "yup";
import StyledButton from "../../assets/buttons.js";
import {
  VerticalWrapper,
  HorizontalWrapper,
  StyledFormikTextField,
  StyledFormikSwitch,
  LineText,
} from "./contacts_styles.jsx";
import UserContext from "../../assets/user_context.jsx";
import { generateRandomId } from "../../utilities/general_util.jsx";

const states = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];

const ContactFields = () => {
  const { touched, errors, values, setFieldValue, setFieldTouched } =
    useFormikContext();

  return (
    <div
      style={{
        margin: "10px",
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
      }}>
      <HorizontalWrapper>
        <VerticalWrapper>
          <Field
            component={StyledFormikTextField}
            name="companyName"
            id="companyName"
            type="text"
            label="Company Name"
            required
            sx={{ margin: "15px", width: "500px" }}
            onBlur={(e) => {
              const companyName = e.target.value;
              if (
                (!touched.shortName || values.shortName === "") &&
                companyName
              ) {
                const shortNameValue = companyName.slice(0, 20);
                setFieldValue("shortName", shortNameValue);
                setFieldTouched("shortName", true, false);
              }
            }}
          />

          <HorizontalWrapper>
            <Field
              component={StyledFormikTextField}
              name="firstName"
              id="firstName"
              type="text"
              label="First Name"
              sx={{ margin: "15px", width: "200px" }}
            />
            <Field
              component={StyledFormikTextField}
              name="lastName"
              id="lastName"
              type="text"
              label="Last Name"
              sx={{ margin: "15px", marginLeft: "0", width: "300px" }}
            />
          </HorizontalWrapper>

          <Field
            component={StyledFormikTextField}
            color="secondary"
            name="phone"
            id="phone"
            type="text"
            label="Phone Number"
            sx={{ margin: "15px", width: "450px" }}
          />
          <Field
            component={StyledFormikTextField}
            color="secondary"
            name="email"
            id="email"
            type="text"
            label="Email Address"
            sx={{ margin: "15px", width: "450px" }}
          />
        </VerticalWrapper>
        <VerticalWrapper>
          <HorizontalWrapper>
            <Field
              name="type"
              component={Autocomplete}
              options={["Vendor", "Person"]}
              getOptionLabel={(option) => option}
              isOptionEqualToValue={(option, value) =>
                option === value || value === ""
              }
              style={{ width: 250, margin: 15 }}
              renderInput={(params) => (
                <StyledTextField
                  {...params}
                  name="type"
                  error={touched["type"] && !!errors["type"]}
                  helperText={touched["type"] && errors["type"]}
                  label="Contact Type"
                  required
                />
              )}
            />
            <Field
              component={StyledFormikTextField}
              color="secondary"
              name="shortName"
              id="shortName"
              type="text"
              label="Nickname"
              required
              sx={{ margin: "15px", marginLeft: "0", width: "350px" }}
            />
          </HorizontalWrapper>

          <Field
            component={StyledFormikTextField}
            color="secondary"
            name="streetAddress"
            id="streetAddress"
            type="text"
            label="Street Address"
            sx={{ margin: "15px", width: "615px" }}
          />
          <HorizontalWrapper>
            <Field
              component={StyledFormikTextField}
              color="secondary"
              name="city"
              id="city"
              type="text"
              label="City"
              sx={{ margin: "15px", width: "300px" }}
            />
            <Field
              name="state"
              component={Autocomplete}
              options={states}
              getOptionLabel={(option) => option}
              isOptionEqualToValue={(option, value) =>
                option === value || value === ""
              }
              style={{ width: 100, margin: 5 }}
              renderInput={(params) => (
                <StyledTextField
                  {...params}
                  name="state"
                  error={touched["state"] && !!errors["state"]}
                  helperText={touched["state"] && !!errors["state"]}
                  label="State"
                />
              )}
            />
            <Field
              component={StyledFormikTextField}
              color="secondary"
              name="zip"
              id="zip"
              type="text"
              label="Zip Code"
              sx={{ margin: "5px", width: "80px" }}
            />
            <Field
              component={StyledFormikTextField}
              color="secondary"
              name="country"
              id="country"
              type="text"
              label="Country"
              sx={{ marginLeft: "5px", marginRight: "15px", width: "100px" }}
            />
          </HorizontalWrapper>
          <HorizontalWrapper>
            <Field
              component={StyledFormikSwitch}
              type="checkbox"
              name="1099"
              sx={{ margin: "15px" }}
            />
            <LineText>This Contact recieves a 1099</LineText>
          </HorizontalWrapper>
        </VerticalWrapper>
      </HorizontalWrapper>
    </div>
  );
};

const AddContact = ({ db, org, contactToEdit, suggestedName, handleClose }) => {
  const { experimental, setContacts } = useContext(UserContext);
  console.log("Contact to Edit: ", contactToEdit);
  return (
    <Formik
      initialValues={
        contactToEdit && Object.keys(contactToEdit)?.length > 0
          ? {
              shortName: contactToEdit?.shortName
                ? contactToEdit.shortName
                : "",
              type: contactToEdit?.type ? contactToEdit.type : "",
              companyName: contactToEdit?.companyName
                ? contactToEdit.companyName
                : "",
              firstName: contactToEdit?.firstName
                ? contactToEdit.firstName
                : "",
              lastName: contactToEdit?.lastName ? contactToEdit.lastName : "",
              phone: contactToEdit?.phone ? contactToEdit.phone : "",
              email: contactToEdit?.email ? contactToEdit.email : "",
              streetAddress: contactToEdit?.streetAddress
                ? contactToEdit.streetAddress
                : "",
              city: contactToEdit?.city ? contactToEdit.city : "",
              state: contactToEdit?.state ? contactToEdit.state : "",
              zip: contactToEdit?.zip ? contactToEdit.zip : "",
              country: contactToEdit?.country ? contactToEdit.country : "",
              1099: contactToEdit?.["1099"] ? contactToEdit["1099"] : false,
              previousMatchWords: contactToEdit?.previousMatchWords,
            }
          : {
              shortName: "",
              type: "Vendor",
              companyName: suggestedName ? suggestedName : "",
              firstName: "",
              lastName: "",
              phone: "",
              email: "",
              streetAddress: "",
              city: "",
              state: "",
              zip: "",
              country: "",
              1099: false,
            }
      }
      validationSchema={Yup.object({
        shortName: Yup.string()
          .max(20, "Must be 20 characters or less")
          .required("You must include a nickname"),
        type: Yup.string().required("You must select a contact type"),
        email: Yup.string()
          .email("Invalid email address")
          .max(35, "Must be 35 characters or less"),
        companyName: Yup.string()
          .min(3, "I'm sure that's too short...")
          .max(45, "Must be 45 characters or less")
          .required("You must include a company name"),
        firstName: Yup.string().max(20, "Must be 20 characters or less"),
        lastName: Yup.string().max(30, "Must be 30 characters or less"),
        phone: Yup.string().matches(
          /^\d{10}$/,
          "Phone number must be exactly 10 digits",
        ),
        streetAddress: Yup.string().max(30, "Must be 30 characters or less"),
        city: Yup.string().max(25, "Must be 25 characters or less"),
        state: Yup.string().max(25, "Must be 25 characters or less"),
        zip: Yup.string().matches(
          /^\d{5}$/,
          "Zip code must be exactly 5 digits",
        ),
        country: Yup.string().max(25, "Must be 25 characters or less"),
        1099: Yup.boolean(),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        if (contactToEdit && Object.keys(contactToEdit)?.length > 0) {
          console.log("Editing contact: ", values);
          if (!experimental) {
            const contactRef = doc(
              db,
              "orgs",
              org,
              "contacts",
              contactToEdit.id,
            );
            try {
              await setDoc(
                contactRef,
                {
                  ...values,
                  previousMatchWords: contactToEdit?.previousMatchWords
                    ? contactToEdit.previousMatchWords
                    : "",
                },
                { merge: true },
              );
              console.log("Contact edited successfully");
            } catch (error) {
              console.error("Error editing contact: ", error);
            }
          } else {
            //Experimental mode, add to local state
            setContacts((prevState) =>
              prevState.map((contact) =>
                contact.id === contactToEdit.id ? values : contact,
              ),
            );
          }
        } else {
          console.log("Adding NEW contact: ", values);
          if (!experimental) {
            const contactsRef = collection(db, "orgs", org, "contacts");
            try {
              await addDoc(contactsRef, {
                ...values,
                previousMatchWords: suggestedName ? suggestedName : "",
              });
              console.log("Contact added successfully");
            } catch (error) {
              console.error("Error adding contact: ", error);
            }
          } else {
            //Experimental mode, add to local state
            let contactId = generateRandomId();
            const newContact = { id: contactId, ...values };
            setContacts((prevState) => [...prevState, newContact]);
          }
        }
        setSubmitting(false);
        handleClose();
      }}>
      <Form>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}>
          <SubtitleText>
            {Object.keys(contactToEdit)?.length > 0
              ? "Edit Contact"
              : "Add Contact"}
          </SubtitleText>
          <ContactFields />
          {/* For some reason this div is needed to center the button */}
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}>
            <StyledButton type="submit" primary width="250px">
              {Object.keys(contactToEdit)?.length > 0
                ? "Edit Contact"
                : "Add Contact"}
            </StyledButton>
          </div>
        </div>
      </Form>
    </Formik>
  );
};

export default AddContact;
