export const countFundsDisplayed = (data) => {
  let count = 0;

  const traverse = (node) => {
    // If show is false, count as 1 and return without exploring further.
    if (node.show === false) {
      count += 1;
      return;
    }
    // If show is true, count it and explore further.

    if (node.funds && node.funds.length > 0) {
      node.funds.forEach((fund) => {
        // If a fund's show is false, it still counts as 1, so we check before traversing.
        if (fund.show === false) {
          count += 1;
        } else {
          traverse(fund);
        }
      });
    } else {
      count += 1;
    }
    if (node.subFunds && node.subFunds.length > 0) {
      node.subFunds.forEach((subFund) => {
        // If a subFund's show is false, it still counts as 1, so we check before traversing.
        if (subFund.show === true) {
          count += 1;
        }
      });
    }
  };

  data.forEach(traverse);
  return count;
};
