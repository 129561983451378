import React, { useContext } from "react";
import { SubtitleText, StyledTextField } from "../component_styles.jsx";
import {
  collection,
  addDoc,
  setDoc,
  doc,
  updateDoc,
  getDoc,
} from "firebase/firestore";
import { Autocomplete } from "formik-mui";
import { Formik, Field, Form, useFormikContext } from "formik";
// import { DatePicker } from "formik-mui-lab";
import * as Yup from "yup";
import StyledButton from "../../assets/buttons.js";
import {
  VerticalWrapper,
  HorizontalWrapper,
  StyledFormikTextField,
} from "./contacts_styles.jsx";
import UserContext from "../../assets/user_context.jsx";
import { generateRandomId } from "../../utilities/general_util.jsx";

const states = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];

const ContactFields = () => {
  const { touched, errors, values, setFieldValue, setFieldTouched } =
    useFormikContext();

  return (
    <div
      style={{
        margin: "10px",
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
      }}>
      <HorizontalWrapper>
        <VerticalWrapper>
          <HorizontalWrapper>
            <Field
              component={StyledFormikTextField}
              name="firstName"
              id="firstName"
              type="text"
              label="First Name"
              sx={{ margin: "15px", width: "200px" }}
              onBlur={(e) => {
                console.log("e.target.value: ", e.target.value, e.target);
                const firstName = e.target.value;
                const lastName = values.lastName;
                if (
                  (!touched.shortName || values.shortName === "") &&
                  firstName !== "" &&
                  lastName !== ""
                ) {
                  const shortNameValue = `${firstName} ${lastName}`;
                  setFieldValue("shortName", shortNameValue);
                  setFieldTouched("shortName", true, false);
                }
              }}
            />
            <Field
              component={StyledFormikTextField}
              name="lastName"
              id="lastName"
              type="text"
              label="Last Name"
              sx={{ margin: "15px", marginLeft: "0", width: "300px" }}
              onBlur={(e) => {
                console.log("e.target.value: ", e.target.value, e.target);
                const lastName = e.target.value;
                const firstName = values.firstName;
                if (
                  (!touched.shortName || values.shortName === "") &&
                  firstName !== "" &&
                  lastName !== ""
                ) {
                  const shortNameValue = `${firstName} ${lastName}`;
                  setFieldValue("shortName", shortNameValue);
                  setFieldTouched("shortName", true, false);
                }
              }}
            />
          </HorizontalWrapper>
          <Field
            component={StyledFormikTextField}
            name="companyName"
            id="companyName"
            type="text"
            label="Company Name"
            sx={{ margin: "15px", width: "500px" }}
          />

          <Field
            component={StyledFormikTextField}
            color="secondary"
            name="phone"
            id="phone"
            type="text"
            label="Phone Number"
            sx={{ margin: "15px", width: "450px" }}
          />
          <Field
            component={StyledFormikTextField}
            color="secondary"
            name="email"
            id="email"
            type="text"
            label="Email Address"
            sx={{ margin: "15px", width: "450px" }}
          />
        </VerticalWrapper>
        <VerticalWrapper>
          <HorizontalWrapper>
            <Field
              component={StyledFormikTextField}
              color="secondary"
              name="shortName"
              id="shortName"
              type="text"
              label="Nickname"
              required
              sx={{ margin: "15px", marginLeft: "0", width: "350px" }}
            />
          </HorizontalWrapper>

          <Field
            component={StyledFormikTextField}
            color="secondary"
            name="streetAddress"
            id="streetAddress"
            type="text"
            label="Street Address"
            sx={{ margin: "15px", width: "615px" }}
          />
          <HorizontalWrapper>
            <Field
              component={StyledFormikTextField}
              color="secondary"
              name="city"
              id="city"
              type="text"
              label="City"
              sx={{ margin: "15px", width: "300px" }}
            />
            <Field
              name="state"
              component={Autocomplete}
              options={states}
              getOptionLabel={(option) => option}
              isOptionEqualToValue={(option, value) =>
                option === value || value === ""
              }
              style={{ width: 100, margin: 5 }}
              renderInput={(params) => (
                <StyledTextField
                  {...params}
                  name="state"
                  error={touched["state"] && !!errors["state"]}
                  helperText={touched["state"] && !!errors["state"]}
                  label="State"
                />
              )}
            />
            <Field
              component={StyledFormikTextField}
              color="secondary"
              name="zip"
              id="zip"
              type="text"
              label="Zip Code"
              sx={{ margin: "5px", width: "80px" }}
            />
            <Field
              component={StyledFormikTextField}
              color="secondary"
              name="country"
              id="country"
              type="text"
              label="Country"
              sx={{ marginLeft: "5px", marginRight: "15px", width: "100px" }}
            />
          </HorizontalWrapper>
        </VerticalWrapper>
      </HorizontalWrapper>
    </div>
  );
};

const AddDonor = ({ db, org, donorToEdit, handleClose }) => {
  const { experimental, setContacts, donors } = useContext(UserContext);
  console.log("Contact to Edit: ", donorToEdit);
  return (
    <Formik
      initialValues={
        donorToEdit && Object.keys(donorToEdit)?.length > 0
          ? {
              shortName: donorToEdit?.shortName ? donorToEdit.shortName : "",
              firstName: donorToEdit?.firstName ? donorToEdit.firstName : "",
              lastName: donorToEdit?.lastName ? donorToEdit.lastName : "",
              phone: donorToEdit?.phone ? donorToEdit.phone : "",
              email: donorToEdit?.email ? donorToEdit.email : "",
              streetAddress: donorToEdit?.streetAddress
                ? donorToEdit.streetAddress
                : "",
              city: donorToEdit?.city ? donorToEdit.city : "",
              state: donorToEdit?.state ? donorToEdit.state : "",
              zip: donorToEdit?.zip ? donorToEdit.zip : "",
              country: donorToEdit?.country ? donorToEdit.country : "",
            }
          : {
              shortName: "",
              companyName: "",
              firstName: "",
              lastName: "",
              phone: "",
              email: "",
              streetAddress: "",
              city: "",
              state: "",
              zip: "",
              country: "",
            }
      }
      validationSchema={Yup.object({
        shortName: Yup.string()
          .max(20, "Must be 20 characters or less")
          .required("You must include a nickname"),
        email: Yup.string()
          .email("Invalid email address")
          .max(35, "Must be 35 characters or less"),
        companyName: Yup.string()
          .min(3, "I'm sure that's too short...")
          .max(45, "Must be 45 characters or less"),
        firstName: Yup.string().max(20, "Must be 20 characters or less"),
        lastName: Yup.string().max(30, "Must be 30 characters or less"),
        phone: Yup.string().matches(
          /^\d{10}$/,
          "Phone number must be exactly 10 digits",
        ),
        streetAddress: Yup.string().max(25, "Must be 25 characters or less"),
        city: Yup.string().max(25, "Must be 25 characters or less"),
        state: Yup.string().max(25, "Must be 25 characters or less"),
        zip: Yup.string().matches(
          /^\d{5}$/,
          "Zip code must be exactly 5 digits",
        ),
        country: Yup.string().max(25, "Must be 25 characters or less"),
      }).test({
        name: "emailOrAddress",
        test: function (values) {
          if (!values.email && !values.streetAddress) {
            return this.createError({
              path: "email",
              message: "You must provide either an email or an address",
            });
          }
          return true;
        },
      })}
      onSubmit={async (values, { setSubmitting }) => {
        if (Object.keys(donorToEdit)?.length > 0) {
          console.log("Editing contact: ", values);
          if (!experimental) {
            const donorsRef = doc(db, "orgs", org, "donorManagement", "donors");
            try {
              const docSnapshot = await getDoc(donorsRef);
              if (docSnapshot.exists()) {
                const currentDonors = docSnapshot.data().donors || [];
                //Replace the old donor with the new one using the id
                console.log("Donor to edit: ", donorToEdit);
                const donorIndex = currentDonors.findIndex((donor) =>
                  donor.id.some(
                    (donorIdItem) => donorIdItem === donorToEdit.id[0],
                  ),
                );
                currentDonors[donorIndex] = { ...values, id: donorToEdit.id };
                await updateDoc(donorsRef, {
                  donors: currentDonors,
                });
                console.log("Donor edited successfully");
              } else {
                console.error(
                  "Document does not exist, but should since we are editing",
                );
              }
              console.log("Contact edited successfully");
            } catch (error) {
              console.error("Error editing contact: ", error);
            }
          } else {
            //Experimental mode, add to local state
            setContacts((prevState) =>
              prevState.map((contact) =>
                contact.id === donorToEdit.id ? values : contact,
              ),
            );
          }
        } else {
          console.log("Adding NEW donor: ", values);
          if (!experimental) {
            const donorsRef = doc(db, "orgs", org, "donorManagement", "donors");
            console.log("DonorsRef: ", donorsRef);
            //While the id is not unique, generate a new one
            let donorId = generateRandomId();
            let isIdUnique = false;

            while (!isIdUnique && donors?.length > 0) {
              isIdUnique = true;
              for (let donor of donors) {
                if (donor.id.includes(donorId)) {
                  isIdUnique = false;
                  donorId = generateRandomId();
                  break; // Exit the for-loop early as we found a duplicate
                }
              }
            }
            try {
              const docSnapshot = await getDoc(donorsRef);
              if (docSnapshot.exists()) {
                const currentDonors = docSnapshot.data().donors || [];
                await updateDoc(donorsRef, {
                  donors: [...currentDonors, { ...values, id: [donorId] }],
                });
                console.log("Donor added successfully");
              } else {
                console.log("Document does not exist!");
                await setDoc(donorsRef, {
                  donors: [{ ...values, id: [donorId] }],
                });
              }
            } catch (error) {
              console.error("Error adding donor: ", error);
            }
          } else {
            //Experimental mode, add to local state
            let contactId = generateRandomId();
            const newContact = { id: contactId, ...values };
            setContacts((prevState) => [...prevState, newContact]);
          }
        }
        setSubmitting(false);
        handleClose();
      }}>
      <Form>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}>
          <SubtitleText>
            {donorToEdit && Object.keys(donorToEdit)?.length > 0
              ? "Edit Donor"
              : "Add Donor"}
          </SubtitleText>
          <ContactFields />
          {/* For some reason this div is needed to center the button */}
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}>
            <StyledButton type="submit" primary width="250px">
              {donorToEdit && Object.keys(donorToEdit)?.length > 0
                ? "Edit Donor"
                : "Add Donor"}
            </StyledButton>
          </div>
        </div>
      </Form>
    </Formik>
  );
};

export default AddDonor;
