import React, { useContext, useState } from "react";
import UserContext from "../../../assets/user_context.jsx";
import {
  ClickableDiv,
  TitleText,
  ModalBox,
  ModalInner,
  LineText,
  AddUserRowWrapper,
  SubtitleText,
} from "../account_styles.jsx";
import { AddCircleOutline } from "@mui/icons-material";
import { Modal } from "@mui/material";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { TextField, Switch } from "formik-mui";
import { collection, addDoc } from "firebase/firestore";
import StyledButton from "../../../assets/buttons";
import { useAuth } from "../../../services/use-auth";

const AddUser = () => {
  const { user, org, userData } = useContext(UserContext);

  const [openAddUser, setOpenAddUser] = useState(false);

  const authHook = useAuth();
  const db = authHook.db;

  return (
    <React.Fragment>
      <Modal open={openAddUser} onClose={() => setOpenAddUser(false)}>
        <ModalBox>
          <ModalInner>
            <Formik
              initialValues={{
                email: "",
                admin: false,
                subscriber: false,
                auditor: false,
                bookkeeper: false,
              }}
              validationSchema={Yup.object({
                email: Yup.string()
                  .email("INvalid email address")
                  .max(30, "Must be 30 characters or less")
                  .required("You must include an email address"),
                admin: Yup.boolean(),
                subscriber: Yup.boolean(),
                auditor: Yup.boolean(),
                bookkeeper: Yup.boolean(),
              })}
              onSubmit={(values, { setSubmitting }) => {
                const addUserInvite = async (values) => {
                  const newUserData = {
                    ...values,
                    inviteBy: user.uid,
                    toOrg: org,
                    userAdded: false,
                    userExists: false,
                    invitedByName: userData.username,
                  };
                  console.log("Adding user: ", newUserData);
                  const invitesRef = collection(db, "orgs", org, "invites");
                  await addDoc(invitesRef, newUserData);
                  console.log("Added user: ", newUserData);
                };
                addUserInvite(values);
                setSubmitting(false);
                setOpenAddUser(false);
              }}>
              <Form>
                <TitleText>Invite a New User</TitleText>
                <Field
                  component={TextField}
                  color="secondary"
                  name="email"
                  id="email"
                  type="text"
                  label="New User Email"
                  sx={{ margin: "15px", width: "450px" }}
                />
                <AddUserRowWrapper>
                  <LineText>Admin</LineText>
                  <Field
                    component={Switch}
                    color="secondary"
                    name="admin"
                    id="admin"
                    type="checkbox"
                    sx={{ margin: "15px" }}
                  />
                </AddUserRowWrapper>
                <AddUserRowWrapper>
                  <LineText>Subscriber</LineText>
                  <Field
                    component={Switch}
                    color="secondary"
                    name="subscriber"
                    id="subscriber"
                    type="checkbox"
                    sx={{ margin: "15px" }}
                  />
                </AddUserRowWrapper>
                <AddUserRowWrapper>
                  <LineText>Auditor</LineText>
                  <Field
                    component={Switch}
                    color="secondary"
                    name="auditor"
                    id="auditor"
                    type="checkbox"
                    sx={{ margin: "15px" }}
                  />
                </AddUserRowWrapper>
                <AddUserRowWrapper>
                  <LineText>Bookkeeper</LineText>
                  <Field
                    component={Switch}
                    color="secondary"
                    name="bookkeeper"
                    id="bookkeeper"
                    type="checkbox"
                    sx={{ margin: "15px" }}
                  />
                </AddUserRowWrapper>
                <StyledButton type="submit" primary adaptiveSize>
                  Invite
                </StyledButton>
              </Form>
            </Formik>
          </ModalInner>
        </ModalBox>
      </Modal>
      <ClickableDiv onClick={() => setOpenAddUser(true)}>
        <AddCircleOutline />
        <SubtitleText style={{ fontSize: "1.1rem", textAlign: "left" }}>
          Add a new user
        </SubtitleText>
      </ClickableDiv>
    </React.Fragment>
  );
};

export default AddUser;
