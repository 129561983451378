import React, { useState } from "react";
import {
  CollapsableTableItem,
  LargeLineText,
  LineText,
  LineTextEmphasized,
  ClickableLineText,
  TableItemAccount,
  TotalItem,
} from "./reports_styles";

const formatNum = (num) => {
  const float = parseFloat(num);
  const formatted = new Intl.NumberFormat("en-us", {
    style: "currency",
    currency: "USD",
  }).format(float);
  return formatted;
};

export const renderAccountRows = ({
  accounts,
  headerText,
  reportData,
  showFunds,
  drillTx,
}) => {
  const headerRow = (
    <tr>
      <td colSpan={2}>
        <LargeLineText>
          {headerText === "Equity" ? "Equity Accounts" : headerText}
        </LargeLineText>
      </td>
    </tr>
  );

  const dataRows = reportData[
    Object.keys(reportData).filter((fundId) => fundId !== "total")[0]
  ][headerText.toLowerCase()]
    .sort((a, b) => a.accountNumber - b.accountNumber)
    .map((account, outerIndex) => {
      // Summing the totals across all funds
      const sum = Object.keys(reportData)
        .filter((fundId) => fundId !== "total")
        .reduce(
          (acc, fundID) => {
            const foundAccount = reportData[fundID][
              headerText.toLowerCase()
            ].find((a) => a.accountNumber === account.accountNumber);
            return acc + (foundAccount ? parseFloat(foundAccount.total) : 0); // Make sure to parse as float
          },
          0, // Starts at 0
        );

      return (
        <tr key={outerIndex}>
          <TableItemAccount>
            <LineText>{`${account.accountNumber} - ${account.accountName}`}</LineText>
          </TableItemAccount>
          {Object.keys(reportData)
            .filter((fundId) => fundId !== "total")
            .map((fundID, innerIndex) => {
              const foundAccount = reportData[fundID][
                headerText.toLowerCase()
              ].find((a) => a.accountNumber === account.accountNumber);
              return (
                <CollapsableTableItem shouldShow={showFunds} key={innerIndex}>
                  <ClickableLineText
                    style={{ textAlign: "right" }}
                    onClick={() => {
                      drillTx({ fund: fundID, account: foundAccount.id });
                    }}>
                    {foundAccount ? formatNum(foundAccount.total) : "N/A"}
                  </ClickableLineText>
                </CollapsableTableItem>
              );
            })}
          <TotalItem>
            <LineText style={{ textAlign: "right" }}>{formatNum(sum)}</LineText>
          </TotalItem>
        </tr>
      );
    });

  return [headerRow, ...dataRows];
};

export const TotalRowComponent = ({
  reportData,
  headerText,
  totalKey,
  showFunds,
  fundsHierarchy,
  fundGroupsToShow,
}) => {
  // Calculate the grand total for all funds
  const grandTotal = Object.keys(reportData)
    .filter((fundId) => fundId !== "total")
    .reduce((acc, fundID) => acc + (reportData[fundID][totalKey] || 0), 0);

  const fromCentsToCurrency = (num) => {
    const float = parseFloat(num) / 100;
    const formatted = new Intl.NumberFormat("en-us", {
      style: "currency",
      currency: "USD",
    }).format(float);
    return formatted;
  };

  return (
    <tr>
      <td>
        <LineTextEmphasized>
          {totalKey === "total" ? "Net Income" : "Total"}
        </LineTextEmphasized>
      </td>
      {fundsHierarchy?.groups?.length > 0 &&
        fundsHierarchy.groups.map((group, groupIndex) =>
          group.funds.map((fund, fundIndex) => (
            <React.Fragment key={`${groupIndex}-${fundIndex}`}>
              {/* Group/Fund Total */}
              {(fundGroupsToShow[groupIndex].show || fundIndex === 0) && (
                <CollapsableTableItem shouldShow={showFunds} key={fundIndex}>
                  <LineTextEmphasized
                    style={{
                      borderTop: "1px solid black",
                      textAlign: "right",
                    }}>
                    {!fundGroupsToShow[groupIndex].show
                      ? // Roll all Funds and subFunds up into the Group Total
                        fromCentsToCurrency(
                          group.funds.reduce((acc, fund) => {
                            let subFundTotal = 0;
                            if (fund.subFunds?.length > 0) {
                              subFundTotal = fund.subFunds.reduce(
                                (acc, subFund) => {
                                  return (
                                    (reportData[subFund]?.[
                                      totalKey.concat("Number")
                                    ] || 0) + acc
                                  );
                                },
                                0,
                              );
                            }
                            return (
                              (reportData[fund.id]?.[
                                totalKey.concat("Number")
                              ] || 0) +
                              acc +
                              subFundTotal
                            );
                          }, 0),
                        )
                      : !fund?.subFunds?.length > 0 ||
                        fundGroupsToShow[groupIndex].funds[
                          fundIndex
                        ].subFunds.some((subFund) => subFund.show)
                      ? fromCentsToCurrency(
                          reportData[fund.id]?.[totalKey.concat("Number")] || 0,
                        )
                      : fromCentsToCurrency(
                          (reportData[fund.id]?.[totalKey.concat("Number")] ||
                            0) +
                            fund.subFunds.reduce((acc, subFund) => {
                              return (
                                (reportData[subFund]?.[
                                  totalKey.concat("Number")
                                ] || 0) + acc
                              );
                            }, 0),
                        )}
                  </LineTextEmphasized>
                </CollapsableTableItem>
              )}
              {/* SubFunds Total (conditionally rendered) */}
              {fund?.subFunds?.length > 0 &&
                fund.subFunds.map((subFund, subFundIndex) => (
                  <React.Fragment>
                    {fundGroupsToShow[groupIndex].funds[fundIndex].subFunds[
                      subFundIndex
                    ].show && (
                      <CollapsableTableItem
                        shouldShow={showFunds && fund.show}
                        key={`${groupIndex}-${fundIndex}-${subFundIndex}`}>
                        <LineTextEmphasized
                          style={{
                            borderTop: "1px solid black",
                            textAlign: "right",
                          }}>
                          {fromCentsToCurrency(
                            reportData[subFund]?.[totalKey] || 0,
                          )}
                        </LineTextEmphasized>
                      </CollapsableTableItem>
                    )}
                  </React.Fragment>
                ))}
            </React.Fragment>
          )),
        )}
      <td>
        <LineTextEmphasized
          style={{
            borderTop: "1px solid black",
            textAlign: "right",
          }}>
          {formatNum(reportData.total?.[totalKey] || 0)}
        </LineTextEmphasized>
      </td>
    </tr>
  );
};
