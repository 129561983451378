import { Modal } from "@mui/material";
import React from "react";
import {
  AccountLineText,
  ModalBox,
  ModalInner,
  RowWrapper,
  StyledTableHeaderDiv,
  StyledTableHeaderRowDiv,
} from "./reports_styles";
import { returnCurrency } from "../../utilities/general_util";
import dayjs from "dayjs";

const TransactionsDrilling = ({
  txModalOpen,
  setTxToDrill,
  setTxModalOpen,
  fundTransactions,
  contacts,
  txToDrill,
}) => {
  return (
    <Modal
      open={txModalOpen}
      onClose={() => {
        setTxToDrill({ fund: null, account: null });
        setTxModalOpen(false);
      }}>
      <ModalBox>
        <ModalInner>
          <React.Fragment>
            <table>
              <thead>
                <StyledTableHeaderRowDiv>
                  <StyledTableHeaderDiv>
                    <AccountLineText>Date</AccountLineText>
                  </StyledTableHeaderDiv>
                  <StyledTableHeaderDiv>
                    <AccountLineText>Contact</AccountLineText>
                  </StyledTableHeaderDiv>
                  <StyledTableHeaderDiv>
                    <AccountLineText>Debit</AccountLineText>
                  </StyledTableHeaderDiv>
                  <StyledTableHeaderDiv>
                    <AccountLineText>Credit</AccountLineText>
                  </StyledTableHeaderDiv>
                </StyledTableHeaderRowDiv>
              </thead>
              <tbody>
                {fundTransactions[txToDrill?.fund]
                  ? fundTransactions[txToDrill.fund]
                      .get(txToDrill.account)
                      .sort(
                        //Sort by date
                        (a, b) => {
                          return dayjs(a.date).isBefore(dayjs(b.date)) ? 1 : -1;
                        },
                      )
                      .map((tx) => (
                        <RowWrapper key={`row-${tx.id}-account-${tx.line}`}>
                          <td key={`row-${tx.id}-date-${tx.line}-td`}>
                            <AccountLineText
                              key={`row-${tx.id}-date-${tx.line}`}>
                              {`${tx.date.format("MM/DD/YYYY")} `}
                            </AccountLineText>
                          </td>
                          <td key={`row-${tx.id}-contact-${tx.line}-td`}>
                            <AccountLineText
                              key={`row-${tx.id}-contact-${tx.line}`}>
                              {`${
                                contacts.find(
                                  (contact) => contact.id === tx.contact,
                                )?.shortName
                                  ? contacts.find(
                                      (contact) => contact.id === tx.contact,
                                    )?.shortName
                                  : "Journal Entry"
                              } `}
                            </AccountLineText>
                          </td>
                          <td key={`row-${tx.id}-debit-${tx.line}-td`}>
                            {tx.sign === "debit" ? (
                              <AccountLineText
                                key={`row-${tx.id}-debit-${tx.line}`}>
                                {`${returnCurrency(tx.amount)} `}
                              </AccountLineText>
                            ) : null}
                          </td>
                          <td key={`row-${tx.id}-credit-${tx.line}-td`}>
                            {tx.sign === "credit" ? (
                              <AccountLineText
                                key={`row-${tx.id}-credit-${tx.line}`}>
                                {`${returnCurrency(tx.amount)} `}
                              </AccountLineText>
                            ) : null}
                          </td>
                        </RowWrapper>
                      ))
                  : null}
              </tbody>
            </table>
          </React.Fragment>
        </ModalInner>
      </ModalBox>
    </Modal>
  );
};

export default TransactionsDrilling;
