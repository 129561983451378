import React, { useState, useEffect, useRef, useContext } from "react";
import {
  findAccountType,
  findAccountsGroupInHierarchy,
  findIfSubAccountInHierarchy,
} from "./utils";
import {
  TextField,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Box,
  FormControl,
  Autocomplete,
  Tooltip,
  Switch,
} from "@mui/material";
import StyledButton from "../../../assets/buttons";
import { TitleText } from "../../component_styles";
import { LineText } from "../table_styles";
import UserContext from "../../../assets/user_context";
import StartingBalance from "./starting_balance";
import {
  useAccountNumberValidation,
  validateAccountType,
  validateAccountNumber,
  validateAccountName,
  validateGroup,
  validateSubAccount,
  validateStartingBalance,
} from "./validation";
import { useSubmitAccount } from "./add_to_db_logic";
import { ReactComponent as LoadingLogo } from "../../../assets/logos/animated-logo.svg";
import styled from "@emotion/styled";

const LoadingLogoStyled = styled(LoadingLogo)`
  z-index: 5;
  width: 50%;
`;

const accountTypes = ["Assets", "Liabilities", "Income", "Expenses"];

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const AddAccount = React.forwardRef(
  (
    {
      accountNumberToAdd,
      possibleAccountNameToAdd,
      setShowModal,
      org,
      db,
      massAdd,
      addGroup,
      groupsAddedToParent,
      thisGroup,
      options,
      accountDataToEdit,
      disabledAddButton,
      reenableSubmit,
      clickedAdd,
    },
    ref,
  ) => {
    // TODO: Add default fund to accounts, should be able to be changed later, and maybe someday calculate from their most used fund for this transaction
    const { funds, accounts, accountsHierarchy } = useContext(UserContext);
    const [accountEntryData, setAccountEntryData] = useState({
      type: accountNumberToAdd
        ? capitalizeFirstLetter(findAccountType(accountNumberToAdd))
        : options?.type
        ? options.type
        : accountDataToEdit?.accountType
        ? accountDataToEdit.accountType
        : "",
      accountName: possibleAccountNameToAdd
        ? possibleAccountNameToAdd
        : accountDataToEdit?.accountName
        ? accountDataToEdit.accountName
        : "",
      accountNumber: accountNumberToAdd
        ? accountNumberToAdd
        : accountDataToEdit?.accountNumber
        ? accountDataToEdit.accountNumber
        : "",
      isRegister: accountDataToEdit?.isRegister
        ? accountDataToEdit.isRegister
        : false,
      isPayableReceivable: accountDataToEdit?.isPayableReceivable
        ? accountDataToEdit.isPayableReceivable
        : false,
      group: thisGroup
        ? thisGroup
        : options?.group
        ? options.group
        : accountDataToEdit?.group
        ? accountDataToEdit.group
        : "",
      subAccount: accountDataToEdit?.subAccount
        ? accountDataToEdit.subAccount
        : false,
      subAccountOf: accountDataToEdit?.subAccountOf
        ? accountDataToEdit.subAccountOf
        : null,
    });
    const matchOption = accountNumberToAdd && accounts.length > 0;
    const [accountEntryErrors, setAccountEntryErrors] = useState({
      type: false,
      accountName: false,
      accountNumber: false,
      group: false,
      subAccount: false,
    });
    const [accountEntryFeedback, setAccountEntryFeedback] = useState({
      type: "",
      accountName: "",
      accountNumber: "",
      group: "",
      subAccount: "",
    });
    const accountEntryErrorsRef = useRef(accountEntryErrors);
    useEffect(() => {
      accountEntryErrorsRef.current = accountEntryErrors;
    }, [accountEntryErrors]);

    const [existingAccountNumbers, setExistingAccountNumbers] = useState(
      accounts ? accounts.map((account) => account.accountNumber) : [],
    );

    const [remainingStartingToAssign, setRemainingStartingToAssign] =
      useState(0);

    const editingAccountId = accountDataToEdit?.accountDatabaseId
      ? accountDataToEdit.accountDatabaseId
      : null;

    useAccountNumberValidation({
      number: accountEntryData.accountNumber,
      setAccountEntryErrors,
      accountEntryErrors,
      setAccountEntryFeedback,
      accountEntryFeedback,
      existingAccountNumbers,
      accounts,
    });

    useEffect(() => {
      setExistingAccountNumbers(
        accounts ? accounts.map((account) => account.accountNumber) : [],
      );
    }, [accounts]);

    const handleChange = (event) => {
      console.log(accountEntryData);
      const { name, value, type, checked } = event.target;
      const inputValue = type === "checkbox" ? checked : value;

      if (name === "accountNumber" && value !== "") {
        if (/^[1-9][0-9]*\.?[0-9]*$/.test(inputValue)) {
          setAccountEntryData({
            ...accountEntryData,
            [name]: inputValue,
          });
        }
      } else {
        setAccountEntryData({
          ...accountEntryData,
          [name]: inputValue,
        });
      }
    };

    //TODO: This is a dumb workaround to the fact that the account number is not set on initial load
    useEffect(() => {
      if (accountDataToEdit) {
        setTimeout(() => {
          validateAccountNumber({
            accountEntryData,
            setAccountEntryErrors,
            accountEntryErrors,
            accountEntryFeedback,
            setAccountEntryFeedback,
            accountDataToEdit,
          });
        }, 10);
      }
    }, []);

    //useEffect to validate subAccount if entryData changes and it is already an error
    useEffect(() => {
      if (accountEntryErrors.subAccount) {
        validateSubAccount({
          accountEntryData,
          setAccountEntryErrors,
          accountEntryErrors,
          setAccountEntryFeedback,
          accountEntryFeedback,
        });
      }
      if (accountEntryErrors.startingBalance) {
        validateStartingBalance({
          accountEntryData,
          setRemainingStartingToAssign,
          setAccountEntryErrors,
          accountEntryErrors,
          setAccountEntryFeedback,
          accountEntryFeedback,
        });
      }
    }, [accountEntryData]);

    //Validate all fields before allowing submission
    const validateAll = () => {
      return new Promise(async (resolve) => {
        const validations = [
          validateAccountType({
            accountEntryData,
            setAccountEntryErrors,
            accountEntryErrors,
            accountEntryFeedback,
            setAccountEntryFeedback,
          }),
          validateAccountName({
            accountEntryData,
            setAccountEntryErrors,
            accountEntryErrors,
            accountEntryFeedback,
            setAccountEntryFeedback,
          }),
          validateAccountNumber({
            accountEntryData,
            setAccountEntryErrors,
            accountEntryErrors,
            accountEntryFeedback,
            setAccountEntryFeedback,
            accountDataToEdit,
          }),
          validateGroup({
            accountEntryData,
            setAccountEntryErrors,
            setAccountEntryFeedback,
          }),
        ];

        await Promise.all(validations); // Wait for all validations to complete

        // Check if there are any errors (assuming that errors are boolean flags)
        const hasErrors = Object.values(accountEntryErrorsRef.current).some(
          (error) => error,
        );
        console.log("Has Errors ValidateAll: ", hasErrors);

        resolve(!hasErrors); // Resolve the promise with the validation status
      });
    };

    const { handleSubmit } = useSubmitAccount({
      accountEntryData,
      editingAccountId,
      setShowModal,
      setAccountEntryData,
      accountDataToEdit,
      db,
      massAdd,
    });

    const handleClick = () => {
      if (clickedAdd) clickedAdd();
      console.log(
        "Submitting account creation/change, current data: ",
        accountEntryData,
      );
      // Validate group
      validateGroup({
        accountEntryData,
        setAccountEntryErrors,
        setAccountEntryFeedback,
      });

      // Validate sub-account
      validateSubAccount({
        accountEntryData,
        setAccountEntryErrors,
        accountEntryErrors,
        setAccountEntryFeedback,
        accountEntryFeedback,
      });

      // Additional checks for starting balance
      if (accountEntryData.startingBalance > 0) {
        //Check for date
        if (!accountEntryData.startingBalanceDate) {
          console.log("No date");
          setAccountEntryErrors({
            ...accountEntryErrors,
            startingBalanceDate: true,
          });
          setAccountEntryFeedback({
            ...accountEntryFeedback,
            startingBalanceDate: "Please enter a date",
          });
          reenableSubmit();
        } else {
          console.log("Date entered");
          setAccountEntryErrors({
            ...accountEntryErrors,
            startingBalanceDate: false,
          });
          setAccountEntryFeedback({
            ...accountEntryFeedback,
            startingBalanceDate: "",
          });
        }
        const fundStartingBalances = Object.values(
          accountEntryData.fundStartingBalances,
        );
        const sumOfFundStartingBalances = fundStartingBalances.reduce(
          (acc, curr) => {
            if (curr !== "") {
              return acc + parseFloat(curr || 0);
            } else {
              return acc;
            }
          },
          0,
        );

        if (
          parseFloat(accountEntryData.startingBalance) !==
          sumOfFundStartingBalances
        ) {
          console.error("Not balanced");
          validateStartingBalance({
            accountEntryData,
            setRemainingStartingToAssign,
            setAccountEntryErrors,
            accountEntryErrors,
            setAccountEntryFeedback,
            accountEntryFeedback,
          });
          console.log(
            "Starting Balance NOT Balanced: ",
            accountEntryData.startingBalance,
          );
          return;
        }
      } else {
        console.log("Starting Balance is 0, proceeding...");
      }

      // Final validation before submission
      if (
        (!accountEntryData.subAccount || accountEntryData.subAccountOf) &&
        accountEntryData.group !== "" &&
        (!accountEntryData.startingBalance ||
          accountEntryData.startingBalance === 0 ||
          accountEntryData.startingBalanceDate)
      ) {
        console.log("Final Validation Passed, goto handleSubmit");
        handleSubmit();
      } else {
        reenableSubmit();
      }
    };

    //Function to trigger adding an account from the parent component
    React.useImperativeHandle(ref, () => ({
      handleSubmit,
      validateForm: validateAll,
    }));

    // console.log(db, org);

    return (
      <React.Fragment>
        {massAdd && <div style={{ height: "25px" }} />}
        {editingAccountId ? (
          <TitleText>Edit Account Details</TitleText>
        ) : (
          <React.Fragment>
            {!massAdd && (
              <TitleText style={{ fontSize: "1.8rem" }}>
                Add an Account
              </TitleText>
            )}
          </React.Fragment>
        )}
        {!disabledAddButton ? (
          <Box
            component="form"
            sx={{
              display: "flex",
              flexDirection: massAdd ? "row" : "column",
              flexWrap: massAdd ? "nowrap" : "wrap",
              alignItems: "center",
              gap: 2,
              pb: 3,
              pl: 2,
              pr: 2,
            }}
            noValidate
            autoComplete="off">
            <FormControl>
              <Tooltip
                placement="top-end"
                title={
                  editingAccountId
                    ? "Cannot Change an Existing Account's Type"
                    : "This is Immutable Upon Account Creation"
                }>
                <TextField
                  style={{
                    marginBottom: "20px",
                    width: massAdd ? "160px" : "350px",
                  }}
                  value={accountEntryData.type}
                  label="Account Type"
                  name="type"
                  color="secondary"
                  select
                  disabled={editingAccountId || options?.type}
                  onChange={handleChange}
                  required
                  onBlur={() => {
                    validateAccountType({
                      accountEntryData,
                      setAccountEntryErrors,
                      accountEntryErrors,
                      accountEntryFeedback,
                      setAccountEntryFeedback,
                    });
                    if (accountEntryErrors.accountNumber) {
                      validateAccountNumber({
                        accountEntryData,
                        setAccountEntryErrors,
                        accountEntryErrors,
                        accountEntryFeedback,
                        setAccountEntryFeedback,
                        accountDataToEdit,
                      });
                    }
                  }}
                  error={accountEntryErrors.type}
                  helperText={accountEntryFeedback.type}>
                  {accountTypes.map((type) => (
                    <MenuItem key={`menu-${type}`} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </TextField>
              </Tooltip>
            </FormControl>
            <FormControl>
              <TextField
                sx={{
                  width:
                    massAdd &&
                    (accountEntryData.type === "Assets" ||
                      accountEntryData.type === "Liabilities")
                      ? "300px"
                      : "350px",
                  marginRight:
                    massAdd &&
                    (accountEntryData.type === "Assets" ||
                      accountEntryData.type === "Liabilities")
                      ? "20px"
                      : "0px",
                  fontSize: "22px",
                  marginBottom: "20px",
                }}
                required
                label="Account Name"
                name="accountName"
                variant="outlined"
                color="secondary"
                value={accountEntryData.accountName}
                onChange={handleChange}
                error={accountEntryErrors.accountName}
                helperText={accountEntryFeedback.accountName}
                onBlur={() => {
                  validateAccountName({
                    accountEntryData,
                    setAccountEntryErrors,
                    accountEntryErrors,
                    accountEntryFeedback,
                    setAccountEntryFeedback,
                  });
                }}
              />
            </FormControl>
            <FormControl>
              <TextField
                sx={{
                  width: massAdd ? "140px" : "350px",
                  fontSize: "22px",
                  marginRight: massAdd ? "20px" : "0px",
                  marginLeft: massAdd ? "20px" : "0px",
                }}
                label="Account Number"
                name="accountNumber"
                variant="outlined"
                color="secondary"
                value={accountEntryData.accountNumber}
                onChange={handleChange}
                error={accountEntryErrors.accountNumber}
                helperText={accountEntryFeedback.accountNumber}
                required
                onBlur={() => {
                  validateAccountNumber({
                    accountEntryData,
                    setAccountEntryErrors,
                    accountEntryErrors,
                    accountEntryFeedback,
                    setAccountEntryFeedback,
                    accountDataToEdit,
                  });
                  if (accountEntryErrors.type) {
                    validateAccountType({
                      accountEntryData,
                      setAccountEntryErrors,
                      accountEntryErrors,
                      accountEntryFeedback,
                      setAccountEntryFeedback,
                    });
                  }
                }}
                disabled={accountNumberToAdd ? true : false}
              />
            </FormControl>

            {(accountEntryData.type === "Assets" ||
              accountEntryData.type === "Liabilities") &&
              !massAdd && (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <FormControlLabel
                    required
                    variant="outlined"
                    control={
                      <Checkbox
                        color="secondary"
                        checked={accountEntryData.isRegister}
                        name="isRegister"
                        onChange={handleChange}
                      />
                    }
                    label={
                      accountEntryData.type === "Assets"
                        ? "This is a bank account"
                        : "This is a credit card"
                    }
                  />

                  {funds?.length > 0 && !accountDataToEdit && (
                    <StartingBalance
                      accountEntryData={accountEntryData}
                      setAccountEntryData={setAccountEntryData}
                      entryErrors={accountEntryErrors}
                      entryFeedback={accountEntryFeedback}
                    />
                  )}
                </div>
              )}

            {accounts?.length > 0 && (
              <React.Fragment>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}>
                  <Switch
                    checked={accountEntryData.subAccount}
                    color="secondary"
                    onChange={(event) => {
                      setAccountEntryData({
                        ...accountEntryData,
                        subAccount: event.target.checked,
                        subAccountOf: null,
                      });
                    }}
                    name="subAccount"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                  <LineText>This is a Sub Account</LineText>
                </div>

                {accountEntryData.subAccount && !massAdd && (
                  <Autocomplete
                    key={`subAccount-${accountEntryData.accountNumber}-selector`}
                    id="subAccount"
                    name="subAccount"
                    value={accountEntryData.subAccountOf}
                    options={accounts.filter(
                      (account) =>
                        account.accountType.toUpperCase() ===
                          accountEntryData.type.toUpperCase() &&
                        !findIfSubAccountInHierarchy(
                          accountsHierarchy,
                          account.id,
                        ) &&
                        account.id !== editingAccountId,
                    )}
                    getOptionLabel={(option) => option.accountName}
                    getOptionSelected={(option, value) =>
                      option.id === value.id
                    }
                    onChange={(event, newValue) => {
                      console.log("New Value: ", newValue);
                      let foundAccountGroup = null;
                      if (newValue !== null && newValue?.id) {
                        foundAccountGroup = findAccountsGroupInHierarchy(
                          accountsHierarchy,
                          newValue.id,
                        );
                      }

                      // Update the subAccountOf state
                      setAccountEntryData({
                        ...accountEntryData,
                        subAccountOf: newValue,
                        group: foundAccountGroup?.groupName || "",
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{
                          width: massAdd ? "190px" : "350px",
                          fontSize: "22px",
                        }}
                        label="Sub Account of..."
                        name="subAccount"
                        color="secondary"
                        variant="outlined"
                        error={accountEntryErrors.subAccount}
                        helperText={accountEntryFeedback.subAccount}
                        disabled={!accountEntryData.subAccount}
                        required
                      />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} key={option.id}>
                        {option.accountName}
                      </li>
                    )}
                  />
                )}
              </React.Fragment>
            )}

            <Autocomplete
              freeSolo
              id="group"
              name="group"
              value={accountEntryData.group}
              onInputChange={(event, newValue) => {
                setAccountEntryData({
                  ...accountEntryData,
                  group: newValue,
                });
              }}
              options={
                !massAdd
                  ? (
                      accountsHierarchy?.types?.find(
                        (type) => type.type === accountEntryData.type,
                      )?.groups || []
                    ).map((option) => option.groupName)
                  : Object.keys(
                      groupsAddedToParent[accountEntryData.type] || {},
                    ).map((option) => option)
              }
              disabled={accountEntryData.subAccount || options?.group}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{
                    width: massAdd ? "190px" : "350px",
                    fontSize: "22px",
                  }}
                  label="Group"
                  name="group"
                  color="secondary"
                  variant="outlined"
                  required
                  onBlur={() => {
                    if (massAdd) {
                      addGroup(
                        {
                          type: accountEntryData.type,
                          group: accountEntryData.group,
                        },
                        accountEntryData.accountNumber,
                      );
                    }
                    validateGroup({
                      accountEntryData,
                      setAccountEntryErrors,
                      setAccountEntryFeedback,
                    });
                  }}
                  error={accountEntryErrors.group}
                  helperText={accountEntryFeedback.group}
                  disabled={accountEntryData.subAccount || options?.group}
                />
              )}
            />
            {/* TODO: This is a dumb repitiion, need to refactor so that this is contained in a component that can be placed better by what component it's rendering in */}
            {(accountEntryData.type === "Assets" ||
              accountEntryData.type === "Liabilities") &&
              massAdd && (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <FormControlLabel
                    required
                    variant="outlined"
                    control={
                      <Checkbox
                        color="secondary"
                        checked={accountEntryData.isRegister}
                        name="isRegister"
                        onChange={handleChange}
                      />
                    }
                    label={
                      accountEntryData.type === "Assets"
                        ? "This is a bank account"
                        : "This is a credit card"
                    }
                  />
                </div>
              )}
          </Box>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <LoadingLogoStyled />
            <LineText>Adding Account...</LineText>
          </div>
        )}
        {!massAdd && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}>
            <StyledButton
              fontSize={"1.3rem"}
              width={"270px"}
              disabled={Object.values(accountEntryErrors).some((err) => err)}
              primary
              onClick={handleClick}>
              {accountDataToEdit?.accountDatabaseId
                ? "Edit Account"
                : "Add Account"}
            </StyledButton>
          </div>
        )}
      </React.Fragment>
    );
  },
);

export default AddAccount;
