import styled from "@emotion/styled";
import { Box } from "@mui/material";
import {
  primary_dark_color,
  primary_color,
  primary_dark_color_lighter,
} from "../../constants/color_constants";
import { css } from "@emotion/react";

//This will help clean up media queries
const mediaQuery = (maxWidth) => `
  @media (max-width: ${maxWidth}px)
`;

export const ReportsTitle = styled.p`
  font-size: 1.8rem;
  font-family: "MontserratBold";
  margin: 1rem;
  color: ${primary_dark_color}};
  text-align: center;
`;

export const PageTitle = styled.p`
  font-size: 2.2rem;
  font-family: "MontserratBold";
  margin: 1rem;
  margin-top: 2.5rem;
  color: ${primary_dark_color};
  text-align: center;
`;

export const ReportsComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 1rem;
  margin-top: 1rem;
`;

export const LineText = styled.p`
  font-size: 1rem;
  font-family: "MontserratMed";
  margin: 1rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  color: ${primary_dark_color};
  text-align: left;
`;

export const LineTextEmphasized = styled.p`
  font-size: 1rem;
  font-family: "MontserratBold";
  margin-left: 1rem;
  margin-right: 1rem;
  color: ${primary_dark_color};
  text-align: left;
`;

export const AccountLineText = styled.p`
  font-size: 1.2rem;
  font-family: "MontserratBold";
  margin: 1rem;
  color: ${primary_color};
  text-align: left;
`;

export const ClickableLineText = styled.p`
  font-size: 1rem;
  font-family: "MontserratMed";
  margin: 1rem;
  color: ${primary_dark_color};
  text-align: left;
  cursor: pointer;
  &:hover {
    transition: 0.3s ease-in-out;
    color: ${primary_color};
    font-family: "MontserratBold";
    //outline the text
    text-shadow:
      0 0 2px ${primary_dark_color},
      0 0 2px ${primary_dark_color};
  }
`;

export const LargeLineText = styled.p`
  display: inline-block;
  font-size: 1.6rem;
  font-family: "MontserratBold";
  text-align: ${(props) => (props.header === "Clear" ? "left" : "center")};
  color: ${primary_dark_color};
  padding: 0.8rem;
  margin: 0px;
  ${mediaQuery(2200)} {
    font-size: 1.2rem;
  }
  ${mediaQuery(1800)} {
    font-size: 1.1rem;
  }
  ${mediaQuery(1600)} {
    font-size: 1rem;
  }
  ${mediaQuery(1400)} {
    font-size: 1rem;
  }
  ${mediaQuery(1200)} {
    font-size: 0.9rem;
    font-stretch: condensed;
  }
  ${mediaQuery(1000)} {
    font-size: 0.8rem;
  }
  ${mediaQuery(900)} {
    font-size: 0.7rem;
  }
`;

export const RowWrapper = styled.tr`
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const CollapsableTableItem = styled.td`
  transition: max-width 0.5s ease-in-out;
  width: ${(props) => (props.shouldShow ? "200px" : "0")};
  max-width: ${(props) => (props.shouldShow ? null : "0")};
  overflow: hidden;
`;

export const StyledHeaderText = styled.p`
  display: inline-block;
  font-size: 1.2rem;
  font-family: ${(props) =>
    props.attribute === "bold" ? "MontserratBold" : "MontserratLight"};
  text-align: ${(props) => (props.header === "Clear" ? "left" : "center")};
  color: white;
  padding: 0.5rem;
  margin: 0px;
  ${mediaQuery(2200)} {
    font-size: 1.1rem;
  }
  ${mediaQuery(1800)} {
    font-size: 1rem;
  }
  ${mediaQuery(1600)} {
    font-size: 0.9rem;
  }
  ${mediaQuery(1400)} {
    font-size: 0.9rem;
  }
  ${mediaQuery(1200)} {
    font-size: 0.9rem;
    font-stretch: condensed;
  }
  ${mediaQuery(1000)} {
    font-size: 0.8rem;
  }
  ${mediaQuery(900)} {
    font-size: 0.7rem;
  }
  &.clone-for-pdf & {
    font-size: 1.2rem; // Adjust font size for PDF export
  }
  // Define a CSS block for additional styles
  ${(props) =>
    props.pdfExport &&
    css`
      font-size: 1.2rem; // Adjust font size for PDF export
      // other specific styles for PDF export...
    `}
`;

export const StyledTableItem = styled.td`
  font-size: 20px;
  font-family: "MontserratMed";
  text-align: left;
  color: ${primary_dark_color};
  width: 200px;
  @media (max-width: 1800px) {
    width: 150px;
  }
  @media (max-width: 1450px) {
    width: 125px;
  }
  padding-left: 5px;
  padding-right: 5px;
  /* border: 1px solid white; */
`;

export const TotalItem = styled.td`
  width: 240px;
`;

export const TableItemAccount = styled.td`
  width: 440px;
`;

export const TableText = styled.p`
  font-size: 1rem;
  font-family: "MontserratMed";
  color: ${primary_dark_color};
  text-align: left;
`;

export const TableTextSmall = styled.p`
  font-size: 0.8rem;
  font-family: "MontserratMed";
  color: ${primary_dark_color};
  text-align: left;
`;

export const StyledTableRow = styled.tr`
  padding-right: 10px;
  display: flex;
`;

export const ScrollableContainer = styled.div`
  max-height: 75vh;
  overflow-y: auto;
`;

export const ModalBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 90vw;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  box-shadow: black 0px 0px 10px 0px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  max-height: 90vh;
  overflow: hidden;
`;

export const ModalInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;

  padding-bottom: 40px;
  max-height: 90vh;
  overflow-y: auto;
`;

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ScrollableDiv = styled.div`
  max-height: 75vh;
  overflow-y: auto;
`;

export const ExpandButton = styled.div`
  position: absolute;
  left: 0; // Stick to the left side
  top: 50%; // Center it vertically
  transform: translateY(-50%); // Adjust for perfect centering
  width: 1.2rem; // Square dimensions
  height: 1.6rem;
  background-color: white;
  cursor: pointer;
  border-radius: 0 10px 10px 0;
  //before with a small arrow
  &:before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    ${(props) =>
      props.showFunds
        ? "transform : translate(-50%, -50%) rotate(45deg);"
        : "transform: translate(-80%, -50%) rotate(225deg);"}
    width: 8px;
    height: 8px;
    border-left: 2px solid black;
    border-bottom: 2px solid black;
    transition: transform 0.3s ease-in-out;
  }
  display: ${(props) => (props.isPdfMode ? "none !important" : "block")};
`;

export const AmountLineText = styled.p`
  font-size: 1rem;
  font-family: "MontserratBold";
  margin-right: 1rem;
  margin-left: 1rem;
  color: ${primary_dark_color};
  text-align: right;
`;

export const FiltersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
`;

export const HorizontalWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
`;

export const ButtonDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  border: 2px solid black;
  border-radius: 10px;
  cursor: pointer;
  padding: 0.25rem;
`;

export const PDFTitleText = styled.p`
  font-size: 34px;
  font-family: "MontserratBold";
  margin: 1rem;
  margin-top: 1.5rem;
  color: ${primary_dark_color};
  text-align: center;
  width: 100%;
`;

export const GroupHeaderLabelDiv = styled.div`
  display: inline-block;
  position: absolute;
  top: -1.5rem;
  left: 0.3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

export const StyledGroupHeaderText = styled.p`
  display: inline-block;

  font-size: 1.1rem;
  font-family: "MontserratMed";
  text-align: "left";
  color: white;
  margin: 0px;
  ${mediaQuery(2200)} {
    font-size: 1.1rem;
  }
  ${mediaQuery(1800)} {
    font-size: 1rem;
  }
  ${mediaQuery(1600)} {
    font-size: 0.9rem;
  }
  ${mediaQuery(1400)} {
    font-size: 0.8rem;
  }
  ${mediaQuery(1200)} {
    font-size: 0.8rem;
    font-stretch: condensed;
  }
  ${mediaQuery(1000)} {
    font-size: 0.8rem;
  }
  ${mediaQuery(900)} {
    font-size: 0.8rem;
  }
  &.clone-for-pdf & {
    font-size: 1.2rem; // Adjust font size for PDF export
  }
  // Define a CSS block for additional styles
  ${(props) =>
    props.pdfExport &&
    css`
      font-size: 1.2rem; // Adjust font size for PDF export
      // other specific styles for PDF export...
    `}
`;

export const StyledTableHeaderDiv = styled.th`
  position: sticky;
  border: none; // Moved border styling here
  &:first-child {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }
  &:last-child {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }
  ${(props) =>
    props.attribute === "Account" ? "width: 450px;" : "width: 200px;"}
  background-color: ${primary_dark_color};
`;

export const CollapsableTableHeaderDiv = styled(StyledTableHeaderDiv)`
  position: relative; // Essential for positioning the pseudo-element
  max-width: ${(props) => (props.shouldShow ? "200px" : "0")};
  /* overflow: hidden; */
  background-color: ${(props) =>
    props.shouldShow ? primary_dark_color : "white"};
  transition:
    max-width 0.5s ease-in-out,
    background-color 1s ease-in-out;

  ${(props) =>
    props.firstInGroup &&
    props.shouldShow &&
    `
    &::before {
      content: '';
      position: absolute;
      top: -1.8rem;
      left: 0;
      right: 0;
      height: 1.8rem;
      background-color: ${primary_dark_color};
      border-radius: 1rem 1rem 0 0;
    }
  `}
`;

export const CollapsableSubFundTableHeaderDiv = styled(StyledTableHeaderDiv)`
  position: relative;
  max-width: ${(props) => (props.showFunds ? "200px" : "0")};
  background-color: ${(props) =>
    props.showFunds ? primary_dark_color_lighter : "white"};
  transition:
    max-width 0.5s ease-in-out,
    background-color 1s ease-in-out;

  // Apply smaller padding to reduce height dynamically
  padding: ${(props) => (props.showFunds ? "0.2rem 0.4rem" : "0")};

  // Optionally adjust font size to ensure text does not overflow and looks proportionate
  font-size: ${(props) => (props.showFunds ? "0.9em" : "inherit")};
  transform: translateY(10px);
  border-radius: 0 0 1rem 1rem;
`;

export const StyledTableHeaderRowDiv = styled.tr`
  /* background-color: ${primary_dark_color}; */
  border-radius: 1rem;
  th {
    border: none; // Remove any existing borders
    padding: 0; // Adjust as needed
  }
`;
